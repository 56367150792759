<div class="music-choice-wrapper" (window:popstate)="onLeave($event)">
  <div class="left-side">
    <div class="music-header-wrapper">
      <span (click)="back()"><i class="fa fa-chevron-left"></i></span>
    </div>

    <div class="music-footer-wrapper">
      <div class="thmubnail" [style.background-image]="currentMusic.thumbnail_url"></div>
      <div class="metadata">
        <div class="title">{{currentMusic.title}}</div>
        <div class="artist">{{currentMusic.artist}}</div>
      </div>
      <canvas #visualizationCanvas></canvas>
    </div>
  </div>

  <div class="cover-image" [ngClass]="{'disabled': !currentMusic.able}" [style.--background]="currentMusic.cover_url">
    <div class="cover-image-body">
      <div class="additional-wrapper">
        <div class="soulbeat-icon-rect" (click)="gms.dialog_ranking(currentMusic, currentMusic.id)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.ranking}"><i class="fa fa-trophy"></i></div>
            <div class="soulbeat-icon-label">Ranking</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_comment(currentMusic, currentMusic.id)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.comments}"><i class="fa fa-comments"></i></div>
            <div class="soulbeat-icon-label">Comment</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.toggle_favorite(currentMusic, currentMusic.id)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.favorite}"><i class="fa fa-tag"></i></div>
            <div class="soulbeat-icon-label">Favorite</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.vote(currentMusic, currentMusic.id, 1)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.my_vote > 0}"><i class="fa fa-thumbs-up"></i></div>
            <div class="soulbeat-icon-label">{{currentMusic.like ? currentMusic.like : 'Like'}}</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.vote(currentMusic, currentMusic.id, -1)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.my_vote < 0}"><i class="fa fa-thumbs-down"></i></div>
            <div class="soulbeat-icon-label">{{currentMusic.dislike ? currentMusic.dislike : 'Dislike'}}</div>
          </div>
        </div>
      </div>

      <div class="additional-wrapper rank-wrapper">
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.score_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.score_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">score</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level"><i class="fa fa-star"></i></div>
            <div class="soulbeat-icon-label">
              <div class="digits-wrapper" *ngIf="result.point_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.point_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.combo.maxCombo_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.combo.maxCombo_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">Max Combo</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">{{result.rank}}</div>
            <div class="soulbeat-icon-label">Rank</div>
          </div>
        </div>
      </div>

      <div class="additional-wrapper combo-wrapper">
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.combo.miss_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.combo.miss_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">Miss</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.combo.bad_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.combo.bad_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">Bad</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.combo.good_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.combo.good_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">Good</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.combo.excellent_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.combo.excellent_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">Excellent</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.combo.perfect_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.combo.perfect_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">Perfect</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <div class="digits-wrapper" *ngIf="result.combo.total_effect">
                <ul class="digits" [ngClass]="effect" *ngFor="let effect of result.combo.total_effect"><li>0<li>1<li>2<li>3<li>4<li>5<li>6<li>7<li>8<li>9</ul>
              </div>
            </div>
            <div class="soulbeat-icon-label">Total</div>
          </div>
        </div>
      </div>
      
      <div class="difficult-wrapper">
        <div class="difficult-rect" (click)="gms.play_music(currentMusic)">
          <div class="difficult-info">
            <div class="difficult-level"><i class="fa fa-repeat"></i></div>
            <div class="difficult-label">Retry</div>
          </div>
        </div>
        <div class="difficult-rect">
          <div class="difficult-info">
            <div class="difficult-level">10</div>
            <div class="difficult-label">Normal</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<audio #audio (canplaythrough)="onCanPlay()" [volume]="setting?.setting?.volume / 2" [src]="currentMusic.audio_url" autoplay loop></audio>
<audio [volume]="setting?.setting?.volume" [src]="audioURL" autoplay></audio>