import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { ConfirmDialogComponent } from "../../dialog/confirm-dialog/confirm-dialog.component"

import { CraftService } from "../../service/craft.service";
import { PlayService } from "../../service/play.service";
import { StatusService } from 'src/app/service/status.service';
import { SettingService } from 'src/app/service/setting.service';

let effectColor: Array<string> = [];

@Component({
  selector: 'app-craft-note',
  templateUrl: './craft-note.component.html',
  styleUrls: ['../play/play.component.scss', './craft-note.component.scss']
})
export class CraftNoteComponent implements OnInit {
  private keyMap: Array<string> = [];
  private subkeyMap: Array<string> = [];
  private keyShuffle: Array<number> = [];
  private longNoteSwitch: Array<number> = [];
  public effectMap: Array<any> = [];
  public noteColor: Array<string> = [];
  
  public frequencyData: any = [];

  public boardWidth: number = 0;
  public boardScaledWidth: number = 0;
  public boardScale: string = '';
  public duration: number = 0;
  public sec2px: number = 300;
  public flowlineWidth: number = 0;
  public currentTime: number = 0;
  public currentTimeBar: number = 0;
  public currentTimeBarMargin : number = 400;

  private isDisplayMMD: boolean = false;
  private isDisplayMV: boolean = false;
  private isDisplayBgImg: boolean = false;
  private isShuffle: boolean = false;
  private isFade: boolean = false;
  private isDisplayEffect: boolean = false;
  private isDisplayHitEffect: boolean = false;
  private isDisplayKeyPressEffect: boolean = false;
  
  private createBySoundHandle: any = undefined;
  
  private onPaintHandle: any = undefined;
  private recordedNotes: Array<any> = [];
  private noteIdx: number = 0;
  private timePivot: number = 0;
  private noteWidth: number = 80;
  private noteSpeed: number = 1500;
  private notes: Array<Array<any>> = [];
  public flowlineNotes: Array<Array<any>> = [];
  public flowlineLongNoteSwitch: any = {};
  public flowlineTimebarMain: Array<number> = [];
  public flowlineTimebarSub: Array<number> = [];
  private downNotePerSecond: number = 0;
  private downNotePivot: number = 0;
  public clientHeight: number = 0;
  public score: number = 0;
  public scorePrint: string = '0';
  private answerLine = 0.85;
  public answerLinePer = this.answerLine * 100;
  public scene: any;
  public keyPress: boolean = false;
  private cmid: string = '';
  public combo = {
    life: 1,
    maxCombo: 0,
    count: 0,
    perfect : 0,
    excellent : 0,
    good : 0,
    bad : 0,
    miss: 0,
    total: 0,
    lastJudge: '',
    lastClass: '',
    lastPivot: 0,
  };
  public cursor:any = {};
  public setting:any = {};
  
  @ViewChild('noteCanvas') noteCanvas: ElementRef = {} as ElementRef;
  @ViewChild('audio') audio: ElementRef = {} as ElementRef;
  @ViewChild('video') video: ElementRef = {} as ElementRef;
  @ViewChild('flowNoteScroll') flowNoteScroll: ElementRef = {} as ElementRef;

  public hitEffectImage: any = undefined;
  public hitEffectImage2: any = undefined;

  public files: any = {
    album: undefined,
    cover: undefined,
    movie: undefined,
    audio: undefined,
  };
  public progress: number = 0;
  public requestUpload : boolean = false;
  

  public metadata: any = {
    focus: 0,
    notePlayMode: 'play',
    title : '',
    description : '',
    price : 0,
    divideTime : 5,
    audioRate: 1.0,
    audioPath : '',
    albumPath : '',
    coverPath : '',
    moviePath : '',
  }
  public title: string = '';
  public description: string = '';
  public price: number = 0;
  public divideTime: number = 5;

  
  private context: CanvasRenderingContext2D = {} as CanvasRenderingContext2D;
  



  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public tagCtrl = new FormControl();
  public filteredTags: Observable<Array<any>> | null = null;
  public tags: string[] = [];
  public allTags: string[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement> = {} as ElementRef;

  public add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.tagCtrl.setValue(null);
  }

  public remove(label: string): void {
    const index = this.tags.indexOf(label);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(label: any): string[] {
    const filterValue = label.toLowerCase();

    return this.allTags.filter((tag: any) => tag.label.toLowerCase().includes(filterValue));
  }

  constructor(
    private zone: NgZone,
    private router: Router, 
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public status: StatusService,
    public craftService: CraftService,
    public playService: PlayService,
    public settingService: SettingService,
  ) { 
    this.cmid = route.snapshot.params['cmid'];


    this.craftService.get_tags().subscribe((res)=> {
      if(res.status) {
        this.allTags = res.data;
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
          startWith(null),
          map((tag: any | null) => {
            return tag ? this._filter(tag) : this.allTags.slice();
          }),
        );
        
        this.init();
      }
    })
  }

  ngOnInit(): void {
    this.clientHeight = +(window.innerHeight || 0);
  }

  ngAfterViewInit(): void {
    this.context = this.noteCanvas.nativeElement.getContext('2d');
    this.context.canvas.width = this.notes.length * this.noteWidth;
    this.context.canvas.height = this.clientHeight;
    
    this.hitEffectImage = document.getElementById("effect")
    this.hitEffectImage2 = document.getElementById("effect2")
  }
  ngOnDestroy(): void {
    if (this.onPaintHandle) {
      cancelAnimationFrame(this.onPaintHandle);
      this.onPaintHandle = undefined;
    }
  }
  
  public onKeyTouch(idx: number){
    this.onKeydown({code : this.keyMap[idx]})
    this.onKeyup({code : this.keyMap[idx]})  
  }
  public onKeyup(event: any) {
    let idx = this.keyMap.indexOf(event.code);
    
    if (this.metadata.focus == 0 && !event?.isfake) {
      switch(event.code) {
        case 'Space':
          this.audio.nativeElement.paused ? this.audio.nativeElement.play() : this.audio.nativeElement.pause();
          this.files.audio_class = this.audio.nativeElement.paused ? 'fa-play' : 'fa-pause';
          break;
        case 'ArrowLeft':
          this.audio.nativeElement.currentTime -= 1;
          break;
        case 'ArrowRight':
          this.audio.nativeElement.currentTime += 1;
          break;
        default:
          if (idx != -1) {
            this.add_note(idx, this.audio.nativeElement.currentTime * 1000);
          }
          else{
            idx = this.subkeyMap.indexOf(event.code);
           
            if (idx != -1) {
              this.add_note(idx, this.audio.nativeElement.currentTime * 1000, true);
            } 
          }
          break;
      }
    }


    if (idx < 0) {
      idx = this.subkeyMap.indexOf(event.code);

      if (idx < 0) {
        return;
      }
    }

    this.effectMap[idx].press = false;
    this.effectMap[idx].keyPressPivot = new Date().getTime();
  }
  public onKeydown(event: any) {
    if (this.metadata.focus == 1 || event?.isfake) {
      let idx = this.keyMap.indexOf(event.code);
      let validIdx = 0;

      if (idx < 0) {
        idx = this.subkeyMap.indexOf(event.code);

        if (idx < 0) {
          return;
        }
      }
      
      this.keyPress = false;
      
      setTimeout(() => {
        this.keyPress = true;
      }, 10);

        
      if(this.effectMap[idx].press)
        return;
      
      this.effectMap[idx].press = true;
      
      for (; validIdx < this.notes[idx]?.length; validIdx++) {
        if ((this.notes[idx][validIdx].longnote || 0) >= 0)
          break;
      }

      if (validIdx >= this.notes[idx].length)
        return;
      
      let score = (200 - Math.pow(Math.abs(this.answerLine - (this.notes[idx][validIdx].y / this.clientHeight)) * 100, 1.5)) * 1500 / this.noteSpeed;
      
      if (score > 0) {
        let time = new Date().getTime();
        score = score * Math.log(this.combo.count + 1);

        this.combo.count += 1;
        this.combo.lastPivot = time;
        this.effectMap[idx].lastHitPivot = time;
        this.effectMap[idx].lastHitY = this.notes[idx][validIdx]?.longnote ? this.answerLine * this.clientHeight : this.notes[idx][validIdx].y;
        this.score += score;
        this.score += this.isShuffle ? score : 0;
        this.score += this.isFade ? score : 0;

        this.scorePrint = this.score.toFixed(0);

        this.notes[idx].splice(validIdx, 1);
        
        if (!this.notes[idx][validIdx]?.longnote && this.metadata.notePlayMode == 'pause') {
          // setTimeout(()=>{
            this.audio.nativeElement.pause();
          // }, 0)
        }

        if (score > 160) {
          this.combo.life = Math.min(this.combo.life + 0.6, 100);
          this.combo.lastJudge = 'Perfect!!';
          this.combo.lastClass = 'perfect';
          this.combo.perfect++;
        } else if (score > 120) {
          this.combo.life = Math.min(this.combo.life + 0.35, 100);
          this.combo.lastJudge = 'Excellent!';
          this.combo.lastClass = 'excellent';
          this.combo.excellent++;
        } else if (score > 60) {
          this.combo.life = Math.min(this.combo.life + 0.1, 100);
          this.combo.lastJudge = 'Good';
          this.combo.lastClass = 'good';
          this.combo.good++;
        } else {
          this.combo.life = Math.max(this.combo.life - 0.2, 0.5);
          this.combo.lastJudge = 'Bad';
          this.combo.lastClass = 'bad';
          this.combo.bad++;
        }

        if(this.combo.maxCombo < this.combo.count)
          this.combo.maxCombo = this.combo.count;
      }
    }
  }
  public onKeypress(event: any) {
    let idx = this.keyMap.indexOf(event.code);
    
    if (idx < 0 || !this.notes[idx].length)
      return;
    
    let score = (20 - Math.pow(Math.abs(this.answerLine - (this.notes[idx][0].y / this.clientHeight)) * 10, 1.5));
      
    if(score > 0) {
      this.combo.count += 1;
      this.combo.lastPivot = new Date().getTime();
      this.score += score * Math.log(this.combo.count + 1);
      this.scorePrint = this.score.toFixed(0);

      this.notes[idx].splice(0, 1);
      this.combo.lastJudge = 'Perfect!!';
      this.combo.lastClass = 'perfect';
    }
  }
  public onResize(event: any) {
    this.clientHeight = +(window.innerHeight || 0);
    this.downNotePerSecond = (this.clientHeight * this.answerLine * 1000) / this.noteSpeed * (this.audio?.nativeElement?.playbackRate || 1);

    let ratio = (this.clientHeight - 280) / this.clientHeight;
    
    this.boardScale = `scale(${ratio})`;
    this.boardScaledWidth = ratio * this.boardWidth;

    if(this.context?.canvas) {
      this.context.canvas.width = this.notes.length * this.noteWidth;
      this.context.canvas.height = this.clientHeight;
    }    
  }


  public init() {
    if (!this.settingService.setting.init) {
      this.settingService.get_setting();

      setTimeout(() => {
        this.init();
      }, 500);
    }
    else {
      this.init_setting();

      if (this.cmid)
        this.init_background();
        
      this.onResize(null);
      
      for (let i = 0; i < this.keyMap.length; i++) {
        this.notes.push([]);
        this.flowlineNotes.push([]);
        this.longNoteSwitch.push(0);
        this.keyShuffle.push(i);
      }

      if (this.isShuffle) {
        this.keyShuffle.sort((a, b) => {return Math.random() - 0.5;});
      }
      
      this.context = this.noteCanvas.nativeElement.getContext('2d');
      this.context.canvas.width = this.notes.length * this.noteWidth;
      this.context.canvas.height = this.clientHeight;
    }
  }

  public init_setting() {
    this.setting = this.settingService.setting;
    this.boardWidth = this.setting.boardWidth;
    this.noteSpeed = (this.setting.noteSpeed || 1.5) * 1000;
    this.answerLine = this.setting.answerLine || 0.85;
    this.answerLinePer = this.setting.answerLine * 100;
    this.isDisplayMMD = +this.setting.mmdDisplay != 0;
    this.isDisplayMV = +this.setting.videoDisplay != 0;
    this.isDisplayBgImg = +this.setting.bgImageDisplay != 0;
    this.isDisplayHitEffect = +this.setting.hitEffectDisplay != 0;
    this.isDisplayKeyPressEffect = +this.setting.keyPressEffectDisplay != 0;
    this.isDisplayEffect = this.isDisplayKeyPressEffect || this.isDisplayHitEffect;
    this.isShuffle = Math.floor(+this.setting.difficult) % 2 == 1;
    this.isFade = Math.floor(+this.setting.difficult / 2) == 1;
    
    if (this.setting.lines) {
      this.keyMap = [];
      this.effectMap = [];
      this.noteColor = [];
      effectColor = [];
      
      for (let i = 0; i < this.setting.lines.length; i++) {
        let line = this.setting.lines[i];
        this.keyMap.push(line.key);
        this.subkeyMap.push(line.subkey);
        this.effectMap.push({
          key : line.key.replace('Key', ''), 
          keyPressPivot: 0,
          lastHitPivot: 0,
          press: false,
        });
        this.noteColor.push(line.color);
        effectColor.push(line.effect);  
      }
    }
    else {
      console.log('no setting file');
    }
  }

  public init_background = () => {
    this.combo.life = 100.0;
        
    this.craftService.get_music(this.cmid).subscribe((res: any)=>{
      if(res.status) {
        if (!this.isDisplayMMD) {
          res.mmd = undefined;
        }
        if (!this.isDisplayMV) {
          res.video = undefined;
        }
        if (!this.isDisplayBgImg) {
          res.cover = undefined;
        }

        this.metadata.title = res.data.title;
        this.metadata.artist = res.data.artist;
        this.metadata.dlc_day_price = res.data.dlc_day_price;
        this.metadata.dlc_lifelong_price = res.data.dlc_lifelong_price;
        this.metadata.dlc_expired_count = res.data.dlc_expired_count;
        this.metadata.audio_highlight = res.data.audio_highlight;

        const { divideTime, audioRate, autoCreateNoteThreshold, autoCreateNoteTimeThreshold, autoCreateNoteMaxNote } = JSON.parse(res?.data?.craft_setting || '{}');

        this.metadata.divideTime = +(divideTime || 5);
        this.metadata.audioRate = +(audioRate || 1);
        this.metadata.autoCreateNoteThreshold = +(autoCreateNoteThreshold || 15);
        this.metadata.autoCreateNoteTimeThreshold = +(autoCreateNoteTimeThreshold || 75);
        this.metadata.autoCreateNoteMaxNote = +(autoCreateNoteMaxNote || 1);


        this.tags = JSON.parse(res.data.tags);
        this.duration = res.data.duration;
        this.recordedNotes = JSON.parse(res.data.note);
        this.noteIdx = 0;
        this.timePivot = new Date().getTime();
        this.downNotePivot = new Date().getTime();
        
        this.files.album_url = res.data.thumbnail;
        this.files.cover_url = res.data.cover;
        this.files.audio_url = res.data.audio;
        this.files.video_url = res.data.video;


        if (this.files.audio_url) {
          this.files.audio_class = 'fa-play';

        }
        if (this.files.video_url) {
          this.files.video_class = 'fa-play';
        }
        
        this.init_flowLineNotes(this.recordedNotes, this.duration);
        this.changeAudioRate(this.metadata.audioRate);
      }
    })
  }

  public init_flowLineNotes(notes: Array<any>, duration: number){
    this.flowlineTimebarMain = [];
    this.flowlineTimebarSub = [];
    this.flowlineWidth = duration * this.sec2px + 5000;
    
    let longnote = Array(this.flowlineNotes.length);
    let lineInterval = (this.divideTime / 5) * this.sec2px;

    for (let i = 0, left = this.currentTimeBarMargin; left < this.flowlineWidth; i++, left += lineInterval) {
      if (i % 5) {
        this.flowlineTimebarSub.push(left);
      }
      else {
        this.flowlineTimebarMain.push(left);
      }
    }

    for (let i = 0; i < notes.length; i++) {
      let note = notes[i];

      if (note.k >= 0 && note.k < this.flowlineNotes.length) {
        this.flowlineNotes[note.k].push({
          x : note.ts / 1000 * this.sec2px + this.currentTimeBarMargin,
          w : 5,
          ts : note.ts,
        });
      }
      else if (note.k >= this.flowlineNotes.length && note.k < this.flowlineNotes.length * 3) {
        if (note.k / this.flowlineNotes.length < 2){
          longnote[note.k % this.flowlineNotes.length] = note.ts;
        }
        else {
          if(longnote[note.k % this.flowlineNotes.length] > 0){
            let x = longnote[note.k % this.flowlineNotes.length] / 1000 * this.sec2px + this.currentTimeBarMargin;
            
            this.flowlineNotes[note.k % this.flowlineNotes.length].push({
              x: x,
              w: note.ts / 1000 * this.sec2px + this.currentTimeBarMargin - x,
              ts: longnote[note.k % this.flowlineNotes.length],
              start: longnote[note.k % this.flowlineNotes.length],
              end: note.ts,
            });
            longnote[note.k % this.flowlineNotes.length] = 0;
          }
        }
      }
    }
  }

  public createNote() {
    const mapping: any = {
      0:0,  1:1,  2:2,  3:3, 4:4,  5:5,  6:6,
      7:0,  8:1,  9:2,  10:3, 11:4, 12:5, 13:6,
      14:0, 15:1, 16:2, 17:3, 18:4, 19:5, 20:6,
    }

    let time = this.audio?.nativeElement?.currentTime * 1000;

    if (time == this.timePivot)
      return;
    
    this.currentTimeBar = time / 1000 * this.sec2px;
    this.currentTime = time;

    this.flowNoteScroll.nativeElement.scrollLeft = Math.max(this.currentTimeBar + 5, 0);

    // let time = new Date().getTime() - this.timePivot;
    let ts = time + this.noteSpeed;


    if (time < this.timePivot) {
      this.combo.total = 0;
      this.noteIdx = 0;
      
      for (let i = 0; i < this.longNoteSwitch.length; i++) {
        this.longNoteSwitch[i] = 0;
      }
      while (this.noteIdx < this.recordedNotes.length) {
        let note = this.recordedNotes[this.noteIdx];
        
        if (ts < note.ts)
          break;
  
        this.noteIdx++;
        this.combo.total += 1;
      }
    }

    this.timePivot = time;

    for (let i = 0; i < this.longNoteSwitch.length; i++){
      if (this.longNoteSwitch[i]) {
        this.notes[i].push({y: 0, noteId: this.longNoteSwitch[i], longnote: this.longNoteSwitch[i] > 0 ? 1 : -1});
      }
    }

    while (this.noteIdx < this.recordedNotes.length) {
      let note = this.recordedNotes[this.noteIdx];
      
      if (ts < note.ts)
        return;

      this.noteIdx++;
      this.combo.total += 1;

      if (this.keyShuffle[mapping[note.k]] >= 0 && this.keyShuffle[mapping[note.k]] < this.keyMap.length) {
        let height = this.downNotePerSecond * ((ts - note.ts) / 1000);
        
        if (note.k >= 0 && note.k < 7) {
          this.notes[this.keyShuffle[mapping[note.k]]].push({y: height});
        }
        if (note.k >= 7 && note.k < 14) {
          this.notes[this.keyShuffle[mapping[note.k]]].push({y: height});
          this.longNoteSwitch[this.keyShuffle[mapping[note.k]]] = this.noteIdx;
        }
        if (note.k >= 14 && note.k < 21) {
          this.longNoteSwitch[this.keyShuffle[mapping[note.k]]] = 0;
        }
      }
    }
  }

  public downNote() {
    const time = new Date().getTime();
    const timeGap = (time - this.downNotePivot) / 1000;
    const py = this.downNotePerSecond * timeGap;
    
    if (this.audio.nativeElement.paused) {
      return;
    }

    this.downNotePivot = time;

    

    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);

    for (let i = 0; i < this.notes.length; i++) {
      this.context.fillStyle = this.noteColor[i];

      for (let j = 0; j < this.notes[i].length; j++) {
        this.notes[i][j].y += py;

        if (this.isFade) {
          this.context.fillStyle = this.noteColor[i] + ('00' + Math.max(Math.min(Math.floor(400 - (this.notes[i][j].y / this.clientHeight * 700)), 255), 0).toString(16)).substr(-2);
        }

        if (this.notes[i][j].longnote) {
          if(this.notes[i][j].longnote == -1) {
            this.context.fillStyle = '#666';
            this.context.fillRect(i * this.noteWidth, this.notes[i][j].y - py, this.noteWidth, py * 2);
            this.context.fillStyle = this.noteColor[i];
          }
          else {
            this.context.fillRect(i * this.noteWidth, this.notes[i][j].y - py, this.noteWidth, py * 2);
          }
        }
        else {
          this.context.fillRect(i * this.noteWidth, this.notes[i][j].y - 20, this.noteWidth, 20);
        }
        
        if (this.metadata.focus == 0 && this.notes[i][j].y >= this.clientHeight * this.answerLine) {
          this.onKeydown({code : this.keyMap[i], isfake: true});
          this.onKeyup({code : this.keyMap[i], isfake: true});
        }

        if (this.notes[i][j]?.longnote && this.effectMap[i].press) {
          if (this.notes[i][j]?.y >= this.clientHeight * this.answerLine) {
            this.notes[i].splice(j--, 1);
          
            // this.onKeyup({code : this.keyMap[i]});
            // this.onKeydown({code : this.keyMap[i]});
          }
        }
        else if (this.notes[i][j]?.y >= this.clientHeight){
          let isLongnote = this.notes[i][j].longnote;
          let noteId = this.notes[i][j].noteId;

          this.notes[i].splice(j--, 1);
          
          if (isLongnote != -1) {
            if (isLongnote == 1) {
              let k = 0
              
              for (; k < this.notes[i].length && this.notes[i][k].noteId == noteId; k++) {
                this.notes[i][k].longnote *= -this.notes[i][k].longnote;
              }

              if (k >= this.notes[i].length) {
                this.longNoteSwitch[i] *= -1;
              }
            }
            this.combo.life = Math.max(Math.min(this.combo.life - 1.5, 100), 0);
            this.combo.count = 0;

            this.combo.miss += 1;
            this.combo.lastJudge = 'Miss';
            this.combo.lastClass = 'miss';
            this.combo.lastPivot = new Date().getTime();
            this.score = Math.max(0, this.score - 200);
            this.scorePrint = this.score.toFixed(0);
          }
        }
      }      
    }
  
    if (this.isDisplayEffect) {
      for (let i = 0; i < this.effectMap.length; i++) {
        if (this.isDisplayKeyPressEffect && (time - this.effectMap[i].keyPressPivot < 150 || this.effectMap[i].press)) {
          let gradientStyle = this.context.createLinearGradient((i + 0.5) * this.noteWidth, this.context.canvas.height * (this.answerLine - 0.35), (i + 0.5) * this.noteWidth, this.context.canvas.height);
          
          gradientStyle.addColorStop(0, effectColor[i] + '00');
          gradientStyle.addColorStop(this.answerLine - 0.05, effectColor[i] + '80');
          gradientStyle.addColorStop(1, effectColor[i] + 'C0');

          this.context.fillStyle = gradientStyle;
          
          this.context.fillRect(i * this.noteWidth, this.context.canvas.height * (this.answerLine - 0.35), this.noteWidth, this.context.canvas.height * (1.35 - this.answerLine));
        }
        if (this.isDisplayHitEffect && time - this.effectMap[i].lastHitPivot < 150){
          let idx = Math.floor((time - this.effectMap[i].lastHitPivot) / 15);

          this.context.drawImage(this.hitEffectImage,
            (idx % 5) * 192, Math.floor(idx / 5) * 192, 192, 192,
            (i - 1) * this.noteWidth, this.effectMap[i].lastHitY - this.noteWidth * 2, this.noteWidth * 3, this.noteWidth * 3
          );
        }
        if (this.isDisplayHitEffect && time - this.effectMap[i].lastHitPivot < 800){
          let idx = Math.floor((time - this.effectMap[i].lastHitPivot) / 40) + 10;

          this.context.drawImage(this.hitEffectImage2,
            (idx % 5) * 192, Math.floor(idx / 5) * 192, 192, 192,
            (i - 1) * this.noteWidth, this.effectMap[i].lastHitY - this.noteWidth * 2, this.noteWidth * 3, this.noteWidth * 3
          );
        }
      }
    }

    
  }
  
  public onCanPlay() {
    this.timePivot = new Date().getTime();
    this.downNotePivot = new Date().getTime();

    if (+this.cmid == 0) {
      console.log(this.audio.nativeElement.duration);
      this.init_flowLineNotes([], this.audio.nativeElement.duration);
    }

    if (this.onPaintHandle) {
      cancelAnimationFrame(this.onPaintHandle);
      this.onPaintHandle = undefined;
    }

    const animate = () => {
      this.onPaintHandle = requestAnimationFrame(animate);
      this.createNote();
      this.downNote();
    }

    animate();
  }

  public onFileDropped($event: any, key: string) {
    this.prepareFilesList($event, key);
  }
  public fileBrowseHandler(files: any, key: string) {
    this.prepareFilesList(files?.target?.files, key);
  }
  public deleteFile(key: string) {
    this.files[key] = null;
    this.files[`${key}_url`] = '';
  }
  public prepareFilesList(files: Array<any>, key: string) {
    for (const item of files) {
      this.progress = 0;
      this.files[key] = item;
      
      this.files[`${key}_url`] =  this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(item));
    }
  }
  public formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0)
      return '0 Byte';
    
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Byte', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  public upload() {
    if (this.requestUpload)
      return;

  }

  public onMouseMove(event: any) {
    this.cursor.x = event.layerX;


    if(this.flowlineLongNoteSwitch.able) {
      if (this.cursor.x > this.flowlineLongNoteSwitch.x) {
        this.flowlineLongNoteSwitch.left = this.flowlineLongNoteSwitch.x;
        this.flowlineLongNoteSwitch.width = this.cursor.x - this.flowlineLongNoteSwitch.x;
      }
      else {
        console.log(event);
        this.flowlineLongNoteSwitch.left = this.cursor.x;
        this.flowlineLongNoteSwitch.width = this.flowlineLongNoteSwitch.x - this.cursor.x;
      }
    }

    event.stopPropagation();
  }

  private isLongNoteSwitch: Array<boolean> = new Array(10);

  public add_note(idx: number, millisecond: number, isLongnote: boolean = false) {
    let offset = 0;
    if(isLongnote) {
      offset += this.isLongNoteSwitch[idx] ? 14 : 7;
      this.isLongNoteSwitch[idx] = !this.isLongNoteSwitch[idx];
    }
    this.flowlineNotes[idx].push({x : millisecond / 1000 * this.sec2px + this.currentTimeBarMargin, w : 5, ts : millisecond});
    this.recordedNotes.push({k: idx + offset, ts: millisecond});
    this.recordedNotes.sort((a, b) => a.ts - b.ts);
  }
  public addNote(idx : number, event: any) {
    let x: number = event.offsetX - this.currentTimeBarMargin;
    let time: number = x / this.sec2px * 1000;

    if (this.metadata.focus) {
      this.metadata.focus = 0;
      return;
    }

    switch(event.button) {
      case 0:
        this.add_note(idx, time);
        break;
      case 2:
        if (this.flowlineLongNoteSwitch.able) {
          let startX = Math.min(this.flowlineLongNoteSwitch.x, event.offsetX), endX = Math.max(this.flowlineLongNoteSwitch.x, event.offsetX);
          let startTime = Math.min(this.flowlineLongNoteSwitch.ts, time), endTime = Math.max(this.flowlineLongNoteSwitch.ts, time);

          this.flowlineNotes[this.flowlineLongNoteSwitch.k].push({x : startX, w: endX - startX, ts: startTime, ts2: endTime});
          this.recordedNotes.push({k: this.flowlineLongNoteSwitch.k + this.flowlineNotes.length, ts: startTime});
          this.recordedNotes.push({k: this.flowlineLongNoteSwitch.k + this.flowlineNotes.length * 2, ts: endTime});
          this.recordedNotes.sort((a, b) => a.ts - b.ts);

          this.flowlineLongNoteSwitch = {able: false, k: 0, ts: 0};
        }
        else{
          this.flowlineLongNoteSwitch = {able: true, k: idx, ts: time, left: event.offsetX, x: event.offsetX, top : idx * 40, width : 0, color: '#FFFFFF88'};
        };
        break;
    }

    event.stopPropagation();
    event.preventDefault();
  }

  public deleteNote(lineIdx : number, flowNoteIdx: number, ts: number, event: any) {
    let deleteIdx = this.recordedNotes.findIndex((note) => note.ts == ts && note.k % 7 == lineIdx);
    let end = this.flowlineNotes[lineIdx][flowNoteIdx].end;

    if (end) {
      let deleteIdx = this.recordedNotes.findIndex((note) => note.ts == end && note.k % 7 == lineIdx && note.k >= 14);
      this.recordedNotes.splice(deleteIdx, 1);
    }

    this.recordedNotes.splice(deleteIdx, 1);
    this.flowlineNotes[lineIdx].splice(flowNoteIdx, 1);

    event.stopPropagation();
    event.preventDefault();
  }
  
  public seekCurrentTime(nextTime:number, event: any){
    let x: number = event.offsetX - this.currentTimeBarMargin;
    let time = (nextTime || (x / this.sec2px * 1000)) / 1000;

    this.seek(time);

    event.stopPropagation();
    event.preventDefault();
  }

  public async importSBN(files: any) {
    if(files?.target?.files?.length) {
      let text = await files?.target?.files[0].text();
      let json = JSON.parse(text);

      this.metadata.title = json?.title;
      this.metadata.artist = json?.artist;
      this.metadata.description = json?.description;
      this.metadata.prcie = json?.price;
      this.metadata.divideTime = json?.divideTime;
      this.metadata.audioPath = json?.audioPath;
      this.metadata.albumPath = json?.albumPath;
      this.metadata.coverPath = json?.coverPath;
      this.metadata.moviePath = json?.moviePath;
      this.recordedNotes = json?.note;
      this.files.audio_url = json?.audioPath;
      
      this.init_flowLineNotes(json?.note, json?.duration);
    }
  }

  public exportSBN(metadata: any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Export',
        content : `Do you want export this data?`,
        button : [
          { text : 'Cancel', color : '#333' },
          { text : 'Export', color : 'purple' }
        ],
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == 'Export') {  
        this.downloadJSON(`${metadata.title}.sbn`, {
          title : metadata.title,
          artist : metadata.artist,
          description : metadata.description,
          price : metadata.price,
          divideTime : metadata.divideTime,
          duration : this.audio.nativeElement.duration,
          audioPath : this.files.audio_url,
          albumPath : '',
          coverPath : '',
          moviePath : '',
          note : this.recordedNotes,
        })
      }
    });
  }

  public downloadJSON(filename: string, jsondata: any) {
    let url = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(jsondata, null, 4));
    let link = document.createElement('a');

    link.href = url;
    link.download = filename;
    link.click();
  }

  public onScroll(event: any){
    this.flowNoteScroll.nativeElement.scrollLeft += event.deltaY
    this.audio.nativeElement.currentTime = this.flowNoteScroll.nativeElement.scrollLeft / this.sec2px;
  }

  public download_youtube(event: any){
    this.craftService.download_youtubeUrl(this.metadata.youtubeLink).subscribe((res: any)=>{
      console.log(res);
    });
  }

  public play(element:any, key: string) {
    element?.nativeElement?.paused ? element?.nativeElement?.play() : element?.nativeElement?.pause();
    this.files[`${key}_class`] = element?.nativeElement?.paused ? 'fa-play' : 'fa-pause';
  }
  public save() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Save',
        content : `Do you want save changes?`,
        button : [
          { text : 'Cancel', color : '#333' },
          { text : 'Save', color : 'purple' }
        ],
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == 'Save') {
        this.craftService.upsert_music(
          this.cmid, this.metadata.title, this.metadata.description, this.metadata.artist, this.recordedNotes, null, 
          this.files.album || this.files.album_url, this.files.cover || this.files.cover_url, this.files.audio || this.files.audio_url, this.files.video || this.files.video_url,
          this.audio.nativeElement.duration, this.metadata.audio_highlight,
          this.metadata.dlc_expired_count, this.metadata.dlc_day_price, this.metadata.dlc_lifelong_price, this.tags || ['test'], 
          this.metadata.divideTime, this.metadata.audioRate, this.metadata.autoCreateNoteThreshold, this.metadata.autoCreateNoteTimeThreshold, this.metadata.autoCreateNoteMaxNote,
          (res: any) => {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Save',
                content : res.status ? 'Save done!' : res.msg,
                button : [{ text : 'OK', color : '#333' }],
              },
            });
          }
        );
      }
    });
  }
  public publish() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Publish',
        content : `
          Will you really publish the music?<br><br>
          When published, <span class="sb-point">20,000<i class="fa fa-star"></i></span> are consumed and other users can buy & play your song.<br>
          If you set the price, other users will receive a <b>10% reward</b> each time they buy this song.<br><br>
          <b class="red">If a work that violates copyright is distributed, criminal charges and civil liability may be held under the relevant laws.</b><br>
          Please check again to see if there are any resources that violate copyright.
        `,
        button : [
          { text : 'Cancel', color : '#333' },
          { text : 'Publish', color : 'purple' }
        ],
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == 'Publish') {
        this.craftService.publish_music(this.cmid).subscribe((res:any)=>{
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Publish',
              content : res.status ? 'Publication is done!' : res.msg,
              button : [{ text : 'OK', color : '#333' }],
            },
          });
        }, (err: any) => {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Publish',
              content : err?.error?.msg || 'Sorry, there is some problem.<br>this problem will be report.',
              button : [{ text : 'OK', color : '#333' }],
            },
          });
        })
      }
    });
  }

  public toggle_pauseNote () {
    const nextStep: any = {
      'default' : 'play',
      'pause' : 'play',
      'play' : 'pause',
    }

    if (nextStep[this.metadata.notePlayMode]) {
      this.metadata.notePlayMode = nextStep[this.metadata.notePlayMode];
    }
    else {
      this.metadata.notePlayMode = nextStep['default'];
    }
  }
  public toggle_createBySound () {
    if (this.createBySoundHandle) {
      clearInterval(this.createBySoundHandle);
      this.createBySoundHandle = undefined;
    }
    else {
      const audioContext = new AudioContext();
      
      const audioSourceNode = audioContext.createMediaElementSource(this.audio.nativeElement);
      const audioAnalyser = audioContext.createAnalyser();
      
      audioAnalyser.fftSize = 32;
      audioSourceNode.connect(audioAnalyser);
      audioSourceNode.connect(audioContext.destination);
      
      this.frequencyData = new Uint8Array(audioAnalyser.frequencyBinCount);
    
      let pivot = new Uint32Array(this.keyMap.length);
        
      // we're ready to receive some data!
      // loop
      const renderFrame = () => {
        let currentFrequencyData = new Uint8Array(audioAnalyser.frequencyBinCount);
        let diff = [];
        let time = this.audio.nativeElement.currentTime * 1000;

        audioAnalyser.getByteFrequencyData(currentFrequencyData);
  
        for (let i = 0; i < currentFrequencyData.length; i++) {
          diff.push({idx: i % 6 > 2 ? i % 6 + 1: i % 6, value: Math.abs(currentFrequencyData[i] - this.frequencyData[i])});
        }

        diff.sort((a,b)=>a.value - b.value);
        let count = 0;

        for (let i = 0; i < diff.length && count < this.autoCreateNoteMaxNote; i++){
          if (diff[i].value > this.autoCreateNoteThreshold + Math.max(this.autoCreateNoteTimeThreshold - (time - pivot[diff[i].idx]), 0) / 10) {
            pivot[diff[i].idx] = time;
            count++;

            this.add_note(diff[i].idx % this.keyMap.length, time);
          }
        }

        this.frequencyData = currentFrequencyData;
      }
      this.createBySoundHandle = setInterval(renderFrame, 20);
    }
  }

  public autoCreateNoteThreshold = 15;
  public autoCreateNoteTimeThreshold = 75;
  public autoCreateNoteMaxNote = 1;

  public seek(sec: number) {
    this.audio.nativeElement.currentTime = +sec;
  }

  public changeAudioRate(rate: number) {
    try {
      this.audio.nativeElement.playbackRate = Math.min(Math.max(0.01, rate), 1.0);
    }
    catch (err) {
      setTimeout(() => { 
        this.changeAudioRate(rate);
      }, 1000)
    }
  }

  public stopPropagation(event: any) { 
    event.stopPropagation();
  }
}