<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <div class="thumbnail-container" *ngIf="data.thumbnail">
      <div class="thumbnail" [style.background-image]="data.thumbnail"></div>
      <br>
  </div>
  <p [innerHTML]="data.content"></p>
  <div class="include-option" *ngIf="data.item.mmd">* include MMD Motion!</div>
  <div class="include-option" *ngIf="data.item.video">* include Music Video!</div>
    
  <div class="dashed-line" *ngIf="data.item.price || data.item.dlc_expired_count">
    <span class="line" *ngIf="data.item.dlc_day_price >= 0">
      <input type="radio" name="purchase_type" value="1" (click)="data.item.select_price_option = -1" checked/>
      <span class="tool-tip-container">
        1 day
        <span class="tool-tip">{{data.item.next_expired_date}}</span>
      </span>
      <span class="line-right sb-point">
        {{data.item.dlc_day_price}}<i class="fa fa-star"></i>
      </span>
    </span>
  
    <span class="line" *ngFor="let option of data.price_options; index as i;">
      <input type="radio" name="purchase_type" [value]="option.day" (click)="data.item.select_price_option = i"/>
      <span class="tool-tip-container">
        {{option.day}} day
        <span class="tool-tip">{{option.next_expired_date}}</span>
      </span>
      <span class="tag">-{{option.discount}}%</span>
      <span class="line-right sb-point">
        {{option.price}}<i class="fa fa-star"></i>
      </span>
    </span>
    
    <span class="line" *ngIf="data.item.dlc_lifelong_price >= 0">
      <input type="radio" name="purchase_type" value="lifelong" (click)="data.item.select_price_option = -2"/>
      <span class="tool-tip-container">
        Lifelong
        <span class="tool-tip">You can enjoy Forever!</span>
      </span>      
      <span class="tag">Forever!</span>
      <span class="line-right sb-point">
        {{data.item.dlc_lifelong_price}}<i class="fa fa-star"></i>
      </span>
    </span>

    <span class="line" *ngIf="data.item.dlc_expired_count">
      <input type="radio" name="purchase_type" value="1" (click)="data.item.select_price_option = -3"/>
      <span class="tool-tip-container">
        {{data.item.dlc_expired_count}} Game
        <span class="tool-tip">You can play {{data.item.dlc_expired_count}} Game</span>
      </span>
      <span class="line-right sb-ticket">
        <i class="fa fa-credit-card-alt"></i>1
      </span>
    </span>
  </div>

  <img [src]="data.image" style="width: 100%; height : auto; max-height: 100%;"/>
  
  <div *ngIf="data.input">
    <mat-form-field *ngFor="let input of data.input" style="width:100%;">
      <mat-label>{{input.label}}</mat-label>
      <input matInput type="{{input.type}}" placeholder="{{input.placeholder}}" [(ngModel)]="input.text">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text">{{button.text}}</button>
    <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
  </div>
</div>

<audio *ngIf="data.audio" style="display: none;" [src]="data.audio" [volume]="0.2" autoplay loop></audio>