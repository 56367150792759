import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component"

import { CommunityService } from 'src/app/service/community.service';
import { StatusService } from 'src/app/service/status.service';
import { FriendService } from 'src/app/service/friend.service';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    public dialog: MatDialog,
    public community: CommunityService,
    public friend: FriendService,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public status: StatusService,
  ) { 
    this.status.isOpenDialog = true;
    this.data.input = {text : ''};

    this.load_comment();
  }
  
  ngOnDestroy():void {
    this.status.isOpenDialog = false;
  }
  
  close(): void {
    this.dialogRef.close();
  }

  public onKeyup(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
  
  public load_comment() {
    this.community.load_comment(this.data.mid).subscribe(res=>{
      this.data.comments = res.data;
    })
  }
  public write_comment(event: any) {
    this.community.write_comment(this.data.mid, this.data.input.text).subscribe(res=>{
      this.data.input.text = '';
      this.load_comment();
    })

    event.stopPropagation();
    event.preventDefault();
  }
  public open_writeReplyForm(comment: any, name: string) {
    console.log(comment, name);

    comment.replyInput = {
      text: `@${name} `,
    };
  }
  public modify_comment(cid: string, comment: string) {
    this.community.modify_comment(cid, comment).subscribe(res => {
      this.load_comment();
    })
  }
  public delete_comment(cid: string) {
    let data = {
      width : '280px',
      data : {
        title : 'Delete',
        content : `Are you sure you want to delete this comment?`,
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Delete', color : 'red'}
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Delete') {
        this.community.delete_comment(cid).subscribe(res => {
          if(res.status) {
            this.load_comment();
          }
        })
      }
    });
  }

  public load_reply(comment: any) {
    this.community.load_reply(comment.cid).subscribe(res => {
      comment.show_replys = true;
      comment.replys = res.data;
    })
  }
  public write_reply(event: any, comment: any) {
    this.community.write_reply(comment.parent_cid || comment.cid, comment.replyInput.text).subscribe(res=>{
      comment.replyInput.text = '';
      this.load_reply(comment);
    })

    event.stopPropagation();
    event.preventDefault();
  }
  public delete_reply(comment: any, cid: string) {
    let data = {
      width : '280px',
      data : {
        title : 'Delete',
        content : `Are you sure you want to delete this comment?`,
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Delete', color : 'red'}
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Delete') {
        this.community.delete_comment(cid).subscribe(res => {
          if(res.status) {
            this.load_reply(comment);
          }
        })
      }
    });
  }

  public vote_comment(data: any, cid: string, score: number) {
    this.community.vote_comment(cid, score).subscribe(res=>{
      if (res?.status) {
        data.my_score = +res?.score || 0;
        data.like = +res.like || '';
        data.dislike = +res.dislike || '';
      }
    })
  }
}
