

<div class="dashboard" cdkDropListGroup>
  <div class="right-side">
    <div class="right-header">
      <div class="top-bar">
        <div class="top-bar-justify">
          <div class="big-inbox">
            User Management
          </div>
        </div>

        <div class="form has-search">
          <input class="text" type="search" placeholder="Search" [(ngModel)]="search_keyword" (keyup)="match_keyword(search_keyword)">
          <span class="searchIcon" (click)="match_keyword()">
            <img src="../../../assets/search.png"/>
          </span>
        </div>

        <div class="profile" *ngIf="accountService.session">
          <img src="../../../assets/images/default_profile.jpg"/>
          <span>{{accountService.session.name}}</span>
        </div>
      </div>
    </div>
    
    <div class="right-body">
      <div class="scroll-cards">
        <div class="board">
          <div class="card-list" *ngFor="let group of userGroups" cdkDragHandle>
            <div class="collection-header">
              <div class="collection-header-name" *ngIf="!group.edit">
                {{group.name}}
              </div>
              <input  [id]="'header-collection-' + group.id" class="collection-header-name" *ngIf="group.edit" 
                      [(ngModel)]="group.edit_name" 
                      (keydown.enter)="edit_permissionGroupName(group, true)"
                      (keydown.esc)="edit_permissionGroupName(group, false)"
                      (focusout)="edit_permissionGroupName(group, false)">
              <span class="collection-header-options">
                <i class="fa fa-pencil" (click)="edit_permissionGroup(group, 'header-collection-')"></i>
                <i class="fa fa-plus" (click)="add_user(group)"></i>
                <!-- <i class="fa fa-trash" (click)="delete_permissionGroup(group)"></i> -->
              </span>
            </div>
            
            <div class="card-list-content" cdkDropList [cdkDropListData]="group.data" (cdkDropListDropped)="onDragDrop($event)">
                <div *ngFor="let user of group.data">
                  <mat-card [id]="'user-card-' + user.id" cdkDrag (click)="get_user(user)" *ngIf="!user.hidden" [ngClass]="{'unsave_card' : user.unsave}">
                    <!-- Use the mat-card-content to add the proper spacing. -->
                    <mat-card-content>
                      <div class="api-header">
                        <div class="api-info">
                          <span class="api-method" [ngClass]="'user-status-' + user.permission">{{permissions[user.permission].label}}</span>
                          <!-- {{user.email}} -->
                        </div>
                      </div> 

                      <div class="user-name">{{user.name}}</div>
                      <div class="user-desc">{{user.email}}</div>
                    </mat-card-content>
                  </mat-card>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="api-detail" *ngIf="selectUser">
        <div class="api-header">
          <div class="api-info">
            <span class="api-method" [ngClass]="'user-status-' + selectUser.permission">{{permissions[selectUser.permission].label}}</span>
          </div>
          <span class="api-header-options">
            <i class="fa fa-trash" (click)="delete_user(selectUser)"></i>
          </span>
        </div>

        <mat-form-field class="width-100">
          <mat-label>Name</mat-label>
          <input class="user-name" matInput placeholder="Username" [(ngModel)]="selectUser.name" (keyup)="check_hash(selectUser)">
        </mat-form-field>
    
        <div class="row">

          <div class="col-xs-12">
            <mat-form-field class="width-100">
              <mat-label>E-mail</mat-label>
              <input class="api-request-url" matInput [(ngModel)]="selectUser.email" (keyup)="check_hash(selectUser)">
            </mat-form-field>    
          </div>

          <div class="col-xs-4">
            <mat-form-field class="width-100">
              <mat-label>Permission</mat-label>
              <mat-select [formControl]="selectUser.permission_form" required>
                <mat-option *ngFor="let permission of permissionsKeys" [value]="permission.value" (click)="check_hash(selectUser)">
                  {{permission.label}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectUser.permission_form.hasError('required')">You must choice Permission</mat-error>
            </mat-form-field>    
          </div>

          <div class="col-xs-4">
            <div class="api-send-wrapper">
              <button class="api-send-button" mat-stroked-button color="primary" (click)="reset_token(selectUser)">Reset Token</button>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="api-send-wrapper">
              <button class="api-send-button" mat-stroked-button color="primary" (click)="change_password(selectUser)">Change Password</button>
            </div>
          </div>  
        </div>
          
        <mat-form-field class="width-100">
          <mat-label>Description</mat-label>
          <textarea rows="6" class="user-desc" matInput [(ngModel)]="selectUser.description" (keyup)="check_hash(selectUser)"></textarea>
        </mat-form-field>

      <div class="row">
        <div class="col-xs-12">
          <div class="api-send-wrapper">
            <button class="api-send-button" mat-stroked-button color="primary" (click)="update_user(selectUser)">Update</button>
          </div>
        </div>    
      </div>
    </div>
  </div>
</div>
    

