import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.scss']
})
export class SettingDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SettingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  
    private status: StatusService,
  ) { 
    this.status.isOpenDialog = true;
  }

  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }

  close(): void {
    this.status.isOpenDialog = false;
    this.dialogRef.close();
  }
  public onKeyup(line: any, key: string, event: any) {
    line[key] = event.code;
    console.log(event);
    return event.preventDefault();
  }
  public onInput(event: any){
    return event.preventDefault();
  }
}
