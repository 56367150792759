import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

import { DomSanitizer } from '@angular/platform-browser';

import { FriendService } from './friend.service';
import { GameManagerService } from './game-manager.service';
import { StatusService } from './status.service';

import * as io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  public socket: io.Socket | undefined = undefined;
  public roomId: string = '';
  public chatAlarmSrc: string = '';
  public chatAlarm: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public httpService: HttpService,
    public friend: FriendService,
    public gms: GameManagerService,
    public status: StatusService,
  ) {
  }

  public connect(uuid: string) {
    console.log('connect:uid');
    console.log(uuid);

    if (this.socket) {
      return console.log('NetworkService:connect(); already socket on');
    }

    this.socket = io.io(this.httpService?.apiServer, {
      transports: ["websocket"]
    });


    if (uuid) {
      console.log('userId:', uuid);
      this.socket.emit('userId', uuid);
    }
    
    this.socket.on('state', (players: any) => {
      console.log(players);
    });
    this.socket.on('notice', (notice: any) => {
      this.gms.dialog_notice(notice?.content, notice?.button);
    });
    this.socket.on('request_friend', (request: any) => {
      console.log(request);
      this.gms.dialog_requestFriend(request);
    });
    this.socket.on('request_chat', (request: any) => {
      this.gms.dialog_requestChat(request);
    });
    this.socket.on('request_game', (request: any) => {
      this.gms.dialog_invitedGame(request);
    });

    this.socket.on('statusLastDate', (data: any) => {
      for(let i = 0; i < data?.length; i++) {
        this.friend.update_afk(data[i]);
      }
    });
    this.socket.on('youtube_pause', (pause: boolean) => {
      this.status.youtubePause = pause;
      this.status.youtubeVideoURL = undefined;
      this.status.youtubeCurrentTime += Math.round((new Date().getTime() - this.status.youtubeCurrentTimePivot) / 1000);
      this.status.youtubeCurrentTimePivot = new Date().getTime();

      setTimeout(()=> {
        let vid = this.status.youtubeURL?.match(/(?<=v=)[\w|\-|_]+/gim);
        let url = `https://www.youtube-nocookie.com/embed/${vid?.length ? vid[0] : ''}?start=${this.status.youtubeCurrentTime}${this.status.youtubePause ? '' : '&autoplay=1&cc_load_policy=99&iv_load_policy=3'}`

        this.status.youtubeVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }, 500)
    });
    this.socket.on('youtube_seek', (sec: number) => {
      this.status.youtubeCurrentTime = sec
      this.status.youtubeVideoURL = undefined;
      this.status.youtubeCurrentTimePivot = new Date().getTime();

      setTimeout(()=> {
        let vid = this.status.youtubeURL?.match(/(?<=v=)[\w|\-|_]+/gim);
        let url = `https://www.youtube-nocookie.com/embed/${vid?.length ? vid[0] : ''}?start=${sec}${this.status.youtubePause ? '' : '&autoplay=1&cc_load_policy=99&iv_load_policy=3'}`

        console.log(url);

        this.status.youtubeVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }, 500)
    });
    this.socket.on('youtube_url', (url: string) => {
      this.status.youtubeURL = url
      this.status.youtubeVideoURL = undefined;
      this.status.youtubeCurrentTimePivot = new Date().getTime();
      
      setTimeout(()=> {
        let vid = this.status.youtubeURL?.match(/(?<=v=)[\w|\-|_]+/gim);
        let url = `https://www.youtube-nocookie.com/embed/${vid?.length ? vid[0] : ''}?start=1${this.status.youtubePause ? '' : '&autoplay=1&cc_load_policy=99&iv_load_policy=3'}`

        this.status.youtubeVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }, 500)
    });
    this.socket.on('chat_message', (chat: any) => {
      setTimeout(() => {
        if (!chat.self) {
          this.chatAlarm = true;
        }
      }, 100)

      console.log('# recieve chat');
      console.log(chat);

      chat.message += ' ';
      let token = chat?.message?.match(/\d+(:\d{2}){1,2}(?=[^\d\w:])/gim);
      let tokenURL = chat?.message?.match(/(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.-]*)(\?[a-zA-Z0-9=&%+_.-]*)?([#][\w\-]*)?/gim);
      let tokenYoutbueURL = chat?.message?.match(/(https?:\/\/)?(?:www\.)?youtu(?:be\.com|\.be)\/(?:watch\?v=|embed\/|v\/)?([\w-]{11})(?:\S+)/gim);
      let msg = chat?.message;

      for (let i = 0; i < token?.length; i++) {
        msg = msg.split(token[i]).join(`<span class="chat-time">${token[i]}</span>`);
      }
      for (let i = 0; i < tokenURL?.length; i++) {
        msg = msg.split(tokenURL[i]).join(`<span class="chat-link">${tokenURL[i]}</span>`);
      }
      for (let i = 0; i < tokenYoutbueURL?.length; i++) {
        msg = msg.split(tokenYoutbueURL[i]).join(`<span class="chat-youtube-link">${tokenYoutbueURL[i]}</span>`);
      }

      chat.messageHTML = msg.split('\n').join('<br>');

      this.status.youtubeChat.push(chat);
      
      if (this.status.youtubeChatCallback) {
        this.status.youtubeChatCallback();
      }
    });
    this.socket.on('chat_file', (chat: any) => {
      let ext = chat?.url?.match(/(?<=\.)[\w]+$/gim);
      
      ext = ext?.length ? ext[0] : ext;
      ext = `${ext}`.toLowerCase();

      // 
      switch(ext){
        case 'jpg': 
        case 'jpeg': 
        case 'png':
        case 'gif':
          chat.messageHTML = `<img class="chat-iamge" src="https://soulbeat.siya.co.kr/${chat?.url}">`;
          break;
        case 'mp4':
        case 'webm': 
          chat.messageHTML = this.sanitizer.bypassSecurityTrustHtml(`
            <video class="chat-iamge" controls>
              <source src="https://soulbeat.siya.co.kr/${chat?.url}" type="video/${ext}">
            </video>`);
          break;
        case 'wav':
        case 'mp3':
        case 'm4a':
          chat.messageHTML = `<audio class="chat-audio" controls src="https://soulbeat.siya.co.kr/${chat?.url}"></audio>`
          break;
      }

      this.status.youtubeChat.push(chat);
      
      if (this.status.youtubeChatCallback) {
        this.status.youtubeChatCallback();
      }
    });

    this.onPingEvent(uuid);
    setInterval(() => {
      this.onPingEvent(uuid);
    }, 5000)
  }

  public onPingEvent(uid: string) {
    if (this.socket) {
      this.socket.emit('ping', {uid});
      this.socket.emit('get_statusLastDate', this.friend.friend.map((item) => {return { id: item?.id}} ));
    }
  }

  public onKeyEvent(event: any) {
    if (this.socket) {
      console.log(event?.code);
      this.socket.emit('input', event?.code);
    }
  }

  public joinRoom(roomId: string) {
    if (this.socket) {
      this.socket.emit('join', {room: roomId});
      this.roomId = roomId;
    }
  }
  public youtube_onSeek(sec: number) {
    if (this.socket) {
      this.socket.emit('youtube_seek', {room: this.roomId, sec});
    }
  }
  public youtube_onPause(pause: boolean) {
    if (this.socket) {
      this.socket.emit('youtube_pause', {room: this.roomId, pause});
    }
  }
  public youtube_changeURL(url: string) {
    if (this.socket) {
      this.socket.emit('youtube_url', {room: this.roomId, url});
    }
  }
  public youtube_chat(message: string, friendId: string) {
    if (this.socket) {
      this.socket.emit('chat_message', {room: this.roomId, message, friendId});
    }
  }
  public youtube_file(url: any) {
    if (this.socket) {
      this.socket.emit('chat_file', {room: this.roomId, url});
    }
  }
}
