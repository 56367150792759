import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clicker',
  templateUrl: './clicker.component.html',
  styleUrls: ['./clicker.component.scss']
})
export class ClickerComponent implements OnInit {
  public clickerIncrease: number = 1;
  public resource: any = [
    {
      key: 'Tree',
      prefix: 'Chop',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Stone',
      prefix: 'Mine',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Iron',
      prefix: 'Mine',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Bronze',
      prefix: 'Mine',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Silver',
      prefix: 'Mine',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Gold',
      prefix: 'Mine',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Flour',
      prefix: 'Farm',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Meat',
      prefix: 'Hunt',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
    {
      key: 'Fish',
      prefix: 'Hunt',
      total: 0,
      increase: 0,
      timer: 300,
      count: 0,
      limit: 10,
    },
  ];
  public population: any = [
    {
      key: 'Lumberjack',
      prefix: 'Create',
      total: 0,
    },
    {
      key: 'Miner',
      prefix: 'Create',
      total: 0,
    },
    {
      key: 'Famer',
      prefix: 'Create',
      total: 0,
    },
    {
      key: 'Hunter',
      prefix: 'Create',
      total: 0,
    },
    {
      key: 'Fisher',
      prefix: 'Create',
      total: 0,
    },
  ];
  public building: any = [
    {
      key: 'Tent',
      prefix: 'Build',
      total: 0,
    },
    {
      key: 'House',
      prefix: 'Build',
      total: 0,
    },
    {
      key: 'INN',
      prefix: 'Build',
      total: 0,
    },
  ];
  public upgrade: any = [
    {
      key: 'double finger',
      prefix: 'upgrade',
      description: 'get double resource!',
    },
    {
      key: 'five finger',
      prefix: 'upgrade',
      description: 'get 5 times resource!',
    },
    {
      key: 'ten finger',
      prefix: 'upgrade',
      description: 'get 10x resource!',
    },
    {
      key: 'ten finger & ten toe',
      prefix: 'upgrade',
      description: 'get 20X resource!',
    },
  ];

  constructor() { }

  ngOnInit(): void {
    setInterval(() => {
      for (const key in this.resource) {
        if (++this.resource[key].count >= this.resource[key].timer) {
          this.resource[key].count = 0;
          this.resource[key].total += this.resource[key].increase;

          if (this.resource[key].total > this.resource[key].limit) {
            this.resource[key].total = this.resource[key].limit;
          }
        }
      }
    })
  }

  public click_resources(item: any) {
    item.total += this.clickerIncrease;

    if (item.total > item.limit) {
      item.total = item.limit;
    }
  }
  public create_item(item: any) {
    item.total += this.clickerIncrease;
  }


  public keyUp(event: any) {
    let target = event.srcElement;
    let maxLength = parseInt(target.attributes["maxlength"].value, 10);
    let myLength = target.value.length;

    if (myLength >= maxLength) {
        let next = target;
        
        while (next = next.nextElementSibling) {
            if (next == null) break;
            if (next.tagName.toLowerCase() == "input") {
                next.focus();
                break;
            }
        }
    }

    if (myLength === 0) {
        let next = target;
        
        while (next = next.previousElementSibling) {
            if (next == null) break;
            if (next.tagName.toLowerCase() == "input") {
                next.focus();
                break;
            }
        }
    }
  }
  public keyDown(event: any) {
    const target = event.srcElement;
    target.value = "";
  }
}
