import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from "../../dialog/confirm-dialog/confirm-dialog.component"
import { PurchaseDialogComponent } from "../../dialog/purchase-dialog/purchase-dialog.component"

import { PlayService } from "../../service/play.service";
import { ShopService } from "../../service/shop.service";

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  public clientHeight: number = 0;
  public clientWidth: number = 0;
  public cardContainerWidth: number = 1280;
  public list: Array<any> = [];
  public audio: any = undefined;
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public playService : PlayService,
    public shopService : ShopService,
  ) { 
  }

  ngOnInit(): void {
    this.onResize(null);
    this.load_list();
  }
  
  
  public load_list() {
    this.shopService.load_refresh();
    this.shopService.get_list().subscribe((res:any)=>{
      console.log(res);

      if (res.status){
        this.list = res.data;
      }
    });
  }
  public onResize(event: any) {
    this.clientHeight = +(window.innerHeight || 0);
    this.clientWidth = +(window.innerWidth || 0);
    this.cardContainerWidth = Math.floor((+(window.innerWidth || 0) - 277) / 320) * 320;

    console.log(this.cardContainerWidth);
  }

  public onFocus(event: any, item: any) {
    this.audio = item.audio_url;
  }

  public buy(item: any) {
    let mid: string = item.id;

    let data = {
      width : '450px',
      data : {
        title : 'Purchase',
        thumbnail : item.thumbnail_url,
        content : `
          Do you want to buy ${item.title}?<br>
          You can play only <span class="sb-point">${item.price}<i class="fa fa-star">.</i></span><br>
          Also, There are many other purchase options.
        `,
        price_options: item.dlc_price_option,
        item: item,
        audio: this.audio.split('#')[0],
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Buy', color : 'purple'}
        ],
      },
    }

    if (item.soldout){
      data.data.content = `
        <div class="warning-msg">You have not permission. :(</div>
        Sorry! ${item.title} is <b>SOLD OUT!</b><br>
        Please check it again next time.
      `
      data.data.button = [
        {text : 'Cancel', color : '#333'},
        {text : 'Buy', color : 'purple'}
      ];
    } else if (item.price == 0) {
      data.data.title = 'Free to Play!'
      data.data.content = `
        Do you want to buy ${item.title}?<br>
        You can purchase it for free!! just <span class="sb-point">0<i class="fa fa-star">!</i></span><br>
      `
    }
    
    let dialogRef = this.dialog.open(PurchaseDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Buy') {
        let title = 'Error', msg = 'Unknown error';

        this.shopService.buy_music(mid, item.select_price_option).subscribe((res: any)=>{
          if (res.status) {
            title = 'Notice';
            msg = 'Thank you for purchasing it.'
            this.load_list();
          }
          else {
            title = 'Warning';
            msg = res.msg;
          }

          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : title,
              content : msg,
              button : [{ text : 'OK', color : '#333' }],
            },
          });
        }, (fail: any) => {
          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : title,
              content : fail.msg,
              button : [{ text : 'OK', color : '#333' }],
            },
          });
        });
      }
    });
  }
}
