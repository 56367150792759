import { Injectable } from '@angular/core';

import { GUI } from 'dat.gui'

declare let THREE: any;
declare let Ammo: any;

interface MMD_INFO__ {
  id: number,
  path: string,
  label: string,
  category: string,
  delay?: number,
  motion?: any,
}

@Injectable({
  providedIn: 'root'
})
export class MMDService {
  public models: any = {
    default: [
      //'TDA_LuoTianyi_HairRecipe/TDA_LuoTianyi_HairRecipe.pmx',
      // 'TDA_LuoTianyi_ChinaDress_White/TDA_LuoTianyi_ChinaDress_White.pmx',
      // 'TDA_LuoTianyi_ChinaDress_Canary/TDA_LuoTianyi_ChinaDress_Canary.pmx',
      // 'simakaze/simakaze.pmx',
      // 'TDA Short Kimono Gumi/TDA Short Kimono Gumi.pmx',
      // 'TDA Short Kimono Ia/TDA Short Kimono Ia.pmx',
      // 'TDA Short Kimono Luka/TDA Short Kimono Luka.pmx',
      // 'TDA Short Kimono Meiko/TDA Short Kimono Meiko.pmx',
      // 'TDA Short Kimono Miku/TDA Short Kimono Miku.pmx',
      // 'TDA Short Kimono Neru/TDA Short Kimono Neru.pmx',
      // 'TDA Short Kimono Rin/TDA Short Kimono Rin.pmx',
      // 'TDA Short Kimono Teto/TDA Short Kimono Teto.pmx',
      // 'TDA Short Kimono Yukari/TDA Short Kimono Yukari.pmx',
      // 'TDA Kimono Haku.pmx/TDA Kimono Haku.pmx',
      'haku/Tda式改変ハク・ワンピccv_bc1.01/Tda式改変ハク・ワンピccv_bc ver1.01.pmx',
      'haku/Tda式改変ハク・ワンピccv1.01/Tda式改変ハク・ワンピccv(u) ver1.01.pmx',
      
      'TDA_YueZhengLing/TDA_YueZhengLing.pmx',
      'TDA_YueZhengLing_ChinaDress/TDA_YueZhengLing_ChinaDress.pmx',
      'TDA_YueZhengLing_ChinaDress_Canary/TDA_YueZhengLing_ChinaDress_Canary.pmx',
      'TDA_YanHe_ChinaDress/TDA_YanHe_ChinaDress.pmx',
      'TDA_YanHe_ChinaDress_Canary/TDA_YanHe_ChinaDress_Canary.pmx',
      'TDA_PingYi_ChinaDress_Short/TDA_PingYi_ChinaDress_Short.pmx',
      'TDA_SuWen/TDA_SuWen.pmx',
      'TDA_HMSillustrious_Prom_Dress/TDA_HMSillustrious_Prom_Dress.pmx',
      'TDA_HMSillustrious_ChinaDress/TDA_HMSillustrious_ChinaDress.pmx',
      'TDA_HMSillustrious_WeddingDress/TDA_HMSillustrious_WeddingDress.pmx',
    ],
    Haku : [
      'haku/Tda式改変ハク・ワンピccv_bc1.01/Tda式改変ハク・ワンピccv_bc ver1.01.pmx',
      'haku/Tda式改変ハク・ワンピccv1.01/Tda式改変ハク・ワンピccv(u) ver1.01.pmx',
    ],
    Luka : [
      'TDA Luka Lace Dress/(Short) TDA Luka Lace Dress Pink Ver2.5.pmx',
      'TDA Luka Lace Dress/(Short) TDA Luka Lace Dress White Ver2.5.pmx'
    ],
    Gumi : [
      'TDA Gumi Lace Dress/(Short) TDA Gumi Lace Dress Green Ver2.5.pmx',
      'TDA Gumi Lace Dress/(Short) TDA Gumi Lace Dress White Ver2.5.pmx',
      'TDA_Gumi/Tda式改変GUMI（ノーマル）.pmx',
      'TDA_Gumi/Tda式改変GUMI（ヘッドセット）Ver1.03.pmx'
    ],
    Miku : [
      'TDA Miku Lace Dress/(Short) TDA Miku Lace Dress Blue Ver2.5.pmx',
      'TDA Miku Lace Dress/(Short) TDA Miku Lace Dress White Ver2.5.pmx',
      'TDA_Miku/Tda式初音ミク・アペンド_Ver1.10.pmx',
      'TDA_Miku_JK/Tda式改変ミク　JKStyle.pmx',
      'TDA_Miku_Onepiece/Tda式ミクワンピースRSP.pmx'
    ],
    Teto : [
      'TDA Teto Lace Dress/(Short) TDA Teto Lace Dress Red Ver2.5.pmx',
      'TDA Teto Lace Dress/(Short) TDA Teto Lace Dress White Ver2.5.pmx',
      'TDA_Teto_TypeS/Tda式重音テトTypeS.pmx',
    ],
    Neru : [
      'TDA Neru Lace Dress/(Short) TDA Neru Lace Dress Oranges Ver2.5.pmx',
      'TDA Neru Lace Dress/(Short) TDA Neru Lace Dress White Ver2.5.pmx',
    ],
    LuoTianyi :[
      'TDA_LuoTianyi_HairRecipe/TDA_LuoTianyi_HairRecipe.pmx',
      'TDA_LuoTianyi_ChinaDress_White/TDA_LuoTianyi_ChinaDress_White.pmx',
      'TDA_LuoTianyi_ChinaDress_Canary/TDA_LuoTianyi_ChinaDress_Canary.pmx',
      'TDA_LuoTianyi_ChinaDress_Canary/TDA_LuoTianyi_ChinaDress_Canary_young.pmx',
    ],
    YueZhengLing : [
      'TDA_YueZhengLing/TDA_YueZhengLing.pmx',
      'TDA_YueZhengLing_ChinaDress/TDA_YueZhengLing_ChinaDress.pmx',
      'TDA_YueZhengLing_ChinaDress_Canary/TDA_YueZhengLing_ChinaDress_Canary.pmx',
    ],
    SuWen : [
      'TDA_SuWen/TDA_SuWen.pmx',
    ],
    PingYi : [
      'TDA_PingYi_ChinaDress_Short/TDA_PingYi_ChinaDress_Short.pmx',
    ],
    YanHe : [
      'TDA_YanHe_ChinaDress/TDA_YanHe_ChinaDress.pmx',
      'TDA_YanHe_ChinaDress_Canary/TDA_YanHe_ChinaDress_Canary.pmx',
      'TDA_YanHe_ChinaDress_Canary/TDA_YanHe_ChinaDress_Canary_young.pmx',
    ],
    HMSillustrious : [
      'TDA_HMSillustrious_Prom_Dress/TDA_HMSillustrious_Prom_Dress.pmx',
      'TDA_HMSillustrious_ChinaDress/TDA_HMSillustrious_ChinaDress.pmx',
      'TDA_HMSillustrious_WeddingDress/TDA_HMSillustrious_WeddingDress.pmx',
      'TDA_HMSillustrious_Young/TDA_HMSillustrious_Young.pmx',
    ]
  };

  constructor() { 
    
  }

  public init_stageMMD(canvasId: string, threeMMD: any, myStageSetting: any, loading: any, model: string, motion: string, category: string, delay: number,  modelList:Array<MMD_INFO__> = [], motionList:Array<MMD_INFO__> = [], guiId = '') {
    const root_path = '/assets/models/mmd/'
    
    loading.complete = false;
  
    threeMMD.scene = undefined;
    threeMMD.camera = undefined;
    threeMMD.mesh = undefined;
    threeMMD.renderer = undefined;
    threeMMD.effect = undefined;
    threeMMD.helper = undefined;
    threeMMD.fid = undefined;
    threeMMD.ready = false;
    threeMMD.clock = undefined;
    threeMMD.physicsHelper = undefined;
    threeMMD.ikHelper = undefined;

    console.log(myStageSetting);

    const init = () => {
      threeMMD.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000);
      threeMMD.camera.position.x = myStageSetting?.camera_x || 5;
      threeMMD.camera.position.y = myStageSetting?.camera_y || 18;
      threeMMD.camera.position.z = myStageSetting?.camera_z || 24;
      threeMMD.camera.lookAt(
        new THREE.Vector3(
          myStageSetting?.look_at_x || 3, 
          myStageSetting?.look_at_y || 15, 
          myStageSetting?.look_at_z || 0
        )
      );

      threeMMD.scene = new THREE.Scene();
      // threeMMD.scene.background = new THREE.Color(0x000000, 0);


      const listener = new THREE.AudioListener();
      threeMMD.camera.add(listener);
      threeMMD.scene.add(threeMMD.camera);

      const ambient = new THREE.AmbientLight(0xCCCCCC);
      
      threeMMD.scene.add(ambient);


      threeMMD.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
      threeMMD.renderer.setPixelRatio(window.devicePixelRatio);
      threeMMD.renderer.setSize(window.innerWidth, window.innerHeight);
      threeMMD.renderer.setClearColor( 0x000000, 0 ); 
      threeMMD.renderer.shadowMap.enabled = true;
      
      
      document.getElementById(canvasId)?.appendChild(threeMMD.renderer.domElement);

      // # outline in mmd
      threeMMD.effect = new THREE.OutlineEffect(threeMMD.renderer);
      threeMMD.helper = new THREE.MMDAnimationHelper();

      let mmdLoader = new THREE.MMDLoader();    // If you want raw content of MMD resources, use .loadPMD/PMX/VMD/VPD methods
      let mmdModels = [];

      // vmd: vocaloid motion data
      // vpd: vocaloid pose data
      
      
      mmdModels.push({model: root_path + model, motion: root_path + motion, category, delay});

      let load_model = (idx: number, mmdModels: Array<any>, callback: any) => {
        if (idx >= mmdModels.length)
          return callback();

        if (mmdModels[idx].category == 'motion') {
          mmdLoader.loadWithAnimation(mmdModels[idx].model, mmdModels[idx].motion, (mmd : any) => {
            let mesh = mmd.mesh;
            threeMMD.mesh = mesh;

            threeMMD.helper.add(mesh, {
              animation: mmd.animation,
              physics: true,
            });
            
            threeMMD.scene.add(mesh);
            
            if (mmdModels[idx]?.delay) {
              threeMMD.helper.update(mmdModels[idx].delay);
            }
            
            return load_model(idx + 1, mmdModels, callback);
          }, null, null);
        }
        else if (mmdModels[idx].category == 'pose') {
          mmdLoader.load(mmdModels[idx].model, (mesh : any) => {
            threeMMD.mesh = mesh
  
            console.log(mmdModels[idx].motion);
  
            if (mmdModels[idx].motion) {
              mmdLoader.loadVPD(mmdModels[idx].motion, false, (motion: any) => {
                threeMMD.helper.pose(mesh, motion);
              })
            }
  
            threeMMD.scene.add(mesh);
            
            return load_model(idx + 1, mmdModels, callback);
          }, null, null);
        }
        else {
          console.log('else case!');
          console.log(mmdModels[idx]);
          
          load_model(idx + 1, mmdModels, callback);
        }
      }

      let load_motion = (idx: number, motionList: Array<any>, callback: any) => {
        if (idx >= motionList.length)
          return callback();
        
        if (motionList[idx].category == 'pose') {
          mmdLoader.loadVPD(root_path + motionList[idx].path, false, (motion: any) => {
            motionList[idx].motion = motion;

            return load_motion(idx + 1, motionList, callback);
          });
        } else if (motionList[idx].category == 'motion') {
          mmdLoader.loadAnimation(root_path + motionList[idx].path, threeMMD.mesh, (motion: any) => {
            motionList[idx].motion = motion;
  
            return load_motion(idx + 1, motionList, callback);
          });
        }
      }
      
      load_model(0, mmdModels, () => {
        threeMMD.helper.enable('animation', true);
        threeMMD.helper.enable('physics', true);
        threeMMD.clock = new THREE.Clock();
        threeMMD.ready = true;
        loading.complete = true;

        if (motionList?.length) {
          load_motion(0, motionList, initGui);
        }
      })
    }

    const onWindowResize = () => {
      threeMMD.camera.aspect = window.innerWidth / window.innerHeight;
      threeMMD.camera.updateProjectionMatrix();

      threeMMD.effect.setSize(window.innerWidth, window.innerHeight);
    }
    const animate = () => {
      threeMMD.fid = requestAnimationFrame(animate);
      render();
    }

    const render = () => {
      if (threeMMD.ready) {
        threeMMD.helper.update(threeMMD.clock.getDelta());
      }
      threeMMD.effect.render(threeMMD.scene, threeMMD.camera);
    }

    const initGui = () => {
      const gui = new GUI({autoPlace: false});
  
      const dictionary = threeMMD.mesh.morphTargetDictionary;
  
      const controls: any = {};
      const keys: Array<string> = [];
  
      const poses = gui.addFolder('Poses');
      const morphs = gui.addFolder('Morphs');
  
      if (guiId)
        gui.domElement.id = guiId;
  
      const initControls = () => {
        for (const key in dictionary) {
          controls[key] = 0.0;
        }
  
        controls.pose = - 1;
  
        for (let i = 0; i < motionList?.length; i++) {
          controls[motionList[i].label] = false;
        }
      }
  
      const initKeys = () => {
        for (const key in dictionary) {
          keys.push(key);
        }
      }
  
      const initPoses = () => {
        const files: any = {default: - 1};
  
        for (let i = 0; i < motionList?.length; i++) {
          files[motionList[i].label] = i;
        }
  
        poses.add(controls, 'pose', files).onChange(onChangePose);
      }
  
      const initMorphs = () => {
        for ( const key in dictionary ) {
          morphs.add(controls, key, 0.0, 1.0, 0.01).onChange(onChangeMorph);
        }
      }
  
      const onChangeMorph = () => {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = controls[key];
          threeMMD.mesh.morphTargetInfluences[i] = value;
        }
      }
  
      const onChangePose = () => {
        const index = parseInt(controls.pose);
  
        if (index === -1) {
          threeMMD.mesh.pose();
        } else {
          switch (motionList[index].category) {
            case 'pose':
              try {
                threeMMD.helper.remove(threeMMD.mesh);
              }
              catch (err) {
                console.log(err);
              }
              
              threeMMD.helper.add(threeMMD.mesh);
              threeMMD.helper.pose(threeMMD.mesh, motionList[index].motion);
              threeMMD.helper.enable('physics', false);
              break;
            case 'motion':
              try {
                threeMMD.helper.remove(threeMMD.mesh);
              }
              catch (err) {
                console.log(err);
              }
              threeMMD.helper.add(threeMMD.mesh, {
                animation: motionList[index].motion,
                physics: true,
              });
              threeMMD.helper.enable('physics', true);
              
              if (motionList[index]?.delay) {
                threeMMD.helper.update(motionList[index].delay);
              }
              
              break;
          }
        }
      }
  
      initControls();
      initKeys();
      initPoses();
      initMorphs();
  
      onChangeMorph();
      onChangePose();
  
      poses.open();
      morphs.open();

      document.getElementById(canvasId)?.appendChild(gui.domElement);
    }

    window.addEventListener('resize', onWindowResize);
  
    if (typeof(Ammo) == 'function') {
      Ammo().then(function (AmmoLib: any) {
        Ammo = AmmoLib;
        init();
        animate();
      });
    }
    else {
      init();
      animate();
    }
  }

  public set_datGUI(threeMMD: any) {
    let gui = new GUI();
    let guiMMD = gui.addFolder('Parameter');
    let api = {
      'animation': true,
      'cameraAnimation': true,
      'ik': true,
      'outline': true,
      'physics': true,
      'show IK bones': false,
      'show rigid bodies': false
    };

    guiMMD.add(api, 'animation').onChange(() => {
      threeMMD.helper.enable('animation', api['animation']);
    });
    guiMMD.add(api, 'cameraAnimation').onChange(() => {
      threeMMD.helper.enable('cameraAnimation', api['cameraAnimation']);
    });
    guiMMD.add(api, 'ik').onChange(() => {
      threeMMD.helper.enable('ik', api['ik']);
    });
    guiMMD.add(api, 'outline').onChange(() => {
      threeMMD.effect.enabled = api['outline'];
    });
    guiMMD.add(api, 'physics').onChange(() => {
      threeMMD.helper.enable('physics', api['physics']);
    });
    guiMMD.add(api, 'show IK bones').onChange(() => {
      threeMMD.ikHelper.visible = api['show IK bones'];
    });
    guiMMD.add(api, 'show rigid bodies').onChange(() => {
      if (threeMMD.physicsHelper !== undefined)
        threeMMD.physicsHelper.visible = api['show rigid bodies'];
    });
  }

  public set_ikHelper(threeMMD: any, mesh: any) {
    threeMMD.ikHelper = threeMMD.helper.objects.get(mesh).ikSolver.createHelper();
    threeMMD.ikHelper.visible = false;
    threeMMD.scene.add(threeMMD.ikHelper);
  }
  public set_physicsHelper(threeMMD: any, mesh: any) {
    threeMMD.physicsHelper = threeMMD.helper.objects.get(mesh).physics.createHelper();
    threeMMD.physicsHelper.visible = false;
    threeMMD.scene.add(threeMMD.physicsHelper);
  }
}
