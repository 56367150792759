import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule, MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { DragDropModule } from '@angular/cdk/drag-drop';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './page/login/login.component';
import { PostComponent } from './page/post/post.component';
import { UserManagementComponent } from './page/user-management/user-management.component';
import { SelectMusicComponent } from './page/select-music/select-music.component';
import { PlayComponent } from './page/play/play.component';
import { ShopComponent } from './page/shop/shop.component';
import { ResultPageComponent } from './page/result-page/result-page.component';
import { PointHistoryComponent } from './page/point-history/point-history.component';
import { CraftNoteComponent } from './page/craft-note/craft-note.component';

import { ProgressBarComponent } from './component/progress-bar/progress-bar.component';
import { ContextMenuComponent } from './component/context-menu/context-menu.component';

import { DragAndDropDirective } from './directive/drag-and-drop.directive';

import { RelativeDatePipe } from './pipe/relative-date.pipe';

import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { PurchaseDialogComponent } from './dialog/purchase-dialog/purchase-dialog.component';
import { FriendDialogComponent } from './dialog/friend-dialog/friend-dialog.component';
import { FriendInfoDialogComponent } from './dialog/friend-info-dialog/friend-info-dialog.component';
import { DrawingDialogComponent } from './dialog/drawing-dialog/drawing-dialog.component';
import { DrawingFabricDialogComponent } from './dialog/drawing-fabric-dialog/drawing-fabric-dialog.component';
import { SettingDialogComponent } from './dialog/setting-dialog/setting-dialog.component';
import { PointlogDialogComponent } from './dialog/pointlog-dialog/pointlog-dialog.component';
import { TicketlogDialogComponent } from './dialog/ticketlog-dialog/ticketlog-dialog.component';
import { CraftListComponent } from './page/craft-list/craft-list.component';

import { MusicStoreComponent } from './page/music-store/music-store.component';
import { MusicChoiceComponent } from './page/music-choice/music-choice.component';
import { MusicResultComponent } from './page/music-result/music-result.component';
import { RankingDialogComponent } from './dialog/ranking-dialog/ranking-dialog.component';
import { CommentDialogComponent } from './dialog/comment-dialog/comment-dialog.component';
import { PauseDialogComponent } from './dialog/pause-dialog/pause-dialog.component';
import { MainMenuComponent } from './page/main-menu/main-menu.component';
import { ImageDialogComponent } from './dialog/image-dialog/image-dialog.component';
import { KalidoComponent } from './page/kalido/kalido.component';
import { ClickerComponent } from './page/clicker/clicker.component';

import { AccountDialogComponent } from './dialog/account-dialog/account-dialog.component';
import { ChatDialogComponent } from './dialog/chat-dialog/chat-dialog.component';
import { PlayNetworkComponent } from './page/play-network/play-network.component';
import { PlayRoomDialogComponent } from './dialog/play-room-dialog/play-room-dialog.component';
import { AdsDialogComponent } from './dialog/ads-dialog/ads-dialog.component';
import { StageSettingDialogComponent } from './dialog/stage-setting-dialog/stage-setting-dialog.component';
import { StageWallpaperDialogComponent } from './dialog/stage-wallpaper-dialog/stage-wallpaper-dialog.component';
import { StageMusicDialogComponent } from './dialog/stage-music-dialog/stage-music-dialog.component';
import { CraftMusicListDialogComponent } from './dialog/craft-music-dialog/craft-music-list-dialog.component';
import { ShopDialogComponent } from './dialog/shop-dialog/shop-dialog.component';
import { PlayReplayComponent } from './page/play-replay/play-replay.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PostComponent,
    UserManagementComponent,
    SelectMusicComponent,
    PlayComponent,
    ShopComponent,
    ResultPageComponent,
    PointHistoryComponent,
    CraftNoteComponent,
    
    ProgressBarComponent,
    ContextMenuComponent,
    
    DragAndDropDirective,
    
    RelativeDatePipe,

    ConfirmDialogComponent,
    PurchaseDialogComponent,
    FriendDialogComponent,
    FriendInfoDialogComponent,
    DrawingDialogComponent,
    DrawingFabricDialogComponent,
    SettingDialogComponent,
    PointlogDialogComponent,
    TicketlogDialogComponent,
    CraftListComponent,
    
    MusicStoreComponent,
    MusicChoiceComponent,
    MusicResultComponent,
    RankingDialogComponent,
    CommentDialogComponent,
    PauseDialogComponent,
    MainMenuComponent,
    ImageDialogComponent,
    KalidoComponent,
    ClickerComponent,
    
    AccountDialogComponent,
    ChatDialogComponent,
    PlayNetworkComponent,
    PlayRoomDialogComponent,
    AdsDialogComponent,
    StageSettingDialogComponent,
    StageWallpaperDialogComponent,
    StageMusicDialogComponent,
    CraftMusicListDialogComponent,
    ShopDialogComponent,
    PlayReplayComponent,
  ],
  imports: [
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatMenuModule,
    MatAutocompleteModule,
    // MatAutocompleteSelectedEvent,
    MatChipsModule,
    // MatChipInputEvent,

    DragDropModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
