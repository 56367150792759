<!-- <h1 mat-dialog-title class="padding-horizon-24">Gallery</h1> -->
<!-- <div mat-dialog-content> -->
<div class="stage-wallpaper-image-wrapper" (click)="close()">
  <div class="stage-wallpaper-image-grid">
    <div  class="stage-wallpaper-image-item" *ngFor="let item of myWallpaperList">
      <img class="stage-wallpaper-image" [src]="item.image" (click)="selectImage($event, item)"/>
    </div>
  </div>
</div>
<!-- <div mat-dialog-actions class="padding-horizon-24">
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text" (click)="updateClosedReason(button.text)">{{button.text}}</button>
  </div>
</div> -->