import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { StatusService } from 'src/app/service/status.service';
import { NetworkService } from 'src/app/service/network.service';
import { PlayService } from "../../service/play.service";
import { ShopService } from "../../service/shop.service";
import { GameManagerService } from 'src/app/service/game-manager.service';


const showItemNum = 5;
  
@Component({
  selector: 'app-music-choice',
  templateUrl: './music-choice.component.html',
  styleUrls: ['./music-choice.component.scss']
})
export class MusicChoiceComponent implements OnInit {
  public clientHeight: number = 0;
  public clientWidth: number = 0;
  public playCardContainerWidth: number = 1280;
  public musicList: Array<any> = [];
  private musicListKeys: Array<string> = [];
  public tid: string = '';
  public currentMusicIdx: number = 0;
  public currentMusicId: string = '';
  public currentMusic: any = {};
  public nearestMusicIdx: Array<number> = [];
  public wheelDelta: number = 0;

  
  @ViewChild('visualizationCanvas') visualizationCanvas: ElementRef = {} as ElementRef;
  @ViewChild('audio') audioSelector: ElementRef = {} as ElementRef;

  private visualizationContext: CanvasRenderingContext2D = {} as CanvasRenderingContext2D;
  private visualizerHandle: any = undefined;
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private location: LocationStrategy,
    public dialog: MatDialog,
    public networkService : NetworkService,
    public status: StatusService,
    public playService : PlayService,
    public shopService : ShopService,
    public gms: GameManagerService,
  ) { 
    this.status.sidebar = false;

    this.tid = route.snapshot.params['tid'] || '';
    this.currentMusicId = route.snapshot.params['mid'] || '';
    
    history.pushState(null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.onResize(null);
    this.load_list();
  }
  ngOnDestroy():void {
    this.status.sidebar = true;
  }
  
  public load_list = () => {
    if (this.currentMusic?.id !== undefined) {
      this.currentMusicId = this.currentMusic.id;
    }

    this.shopService.load_refresh();
    this.playService.get_list(this.tid).subscribe((res:any)=>{
      if (res.status) {
        if (!res.data?.length) {
          res.data = [];
        }

        for (let music of res.data) {
          if (music.able) {
            music.expirationNotice = `You can play <b class="sb-ticket dark">${music?.expired_count || 0} game${music?.expired_count > 1 ? 's':''}</b>`;

            if (music.expired_date) {
              let d = new Date(music.expired_date);
            
              if (d < new Date()) {

              }
              else if (d < new Date('2099-01-01')) {
                music.expirationNotice = `You can play until <b class="sb-point dark">${d.toLocaleString()}</b>`;
              }
              else {
                music.expirationNotice = ``;
              }
            }
          }
        }

        this.musicList = res.data;
        this.musicListKeys = Object.keys(this.musicList);
        this.nearestMusicIdx = new Array<number>(showItemNum < res.data.length? showItemNum : res.data.length);

        if (this.currentMusicId) {
          this.currentMusicIdx = this.musicList.findIndex((item, i) => {
            return item?.id == this.currentMusicId;
          });
        }
        this.onFocus(this.currentMusicIdx);
      }
    });
  }
  
  public onResize(event: any) {
    this.clientHeight = +(window.innerHeight || 0);
    this.clientWidth = +(window.innerWidth || 0);
    this.playCardContainerWidth = Math.floor((+(window.innerWidth || 0) - 277) / 320) * 320;
  }

  public onKeyup(event: any) {
    if (this.status.isOpenDialog) {
      return;
    }

    this.networkService.onKeyEvent(event);

    switch (event.keyCode) {
      case 37: // ArrowLeft
        break;
      case 38: // ArrowUP
        this.currentMusicIdx = (this.currentMusicIdx + this.musicList.length - 1) % this.musicList.length;
        this.onFocus(this.currentMusicIdx);
        break;
      case 39: // ArrowRight
        break;
      case 40: // ArrowDown
        this.currentMusicIdx = (this.currentMusicIdx + 1) % this.musicList.length;
        this.onFocus(this.currentMusicIdx);
        break;
      case 13:  // Return
        this.gms.play_music(this.currentMusic, this.load_list);
        return;
    };
  }
  public onFocus(idx: number = 0) {
    this.currentMusicIdx = idx % this.musicList.length;
    this.currentMusic = this.musicList[this.currentMusicIdx];
    
    // if (this.musicList.length >= showItemNum) {
      for (let i = 0; i < this.nearestMusicIdx.length; i++) {
        this.nearestMusicIdx[i] = +this.musicListKeys[(this.currentMusicIdx + i - Math.floor(this.nearestMusicIdx.length / 2) + this.musicListKeys.length) % this.musicListKeys.length];
      }
    // }
    // else {
    //   const offset = Math.floor((showItemNum - this.musicList.length) / 2);
    //   for(let i = 0; i < this.musicList.length; i++) {
    //     this.nearestMusicIdx[i + offset] = +this.musicListKeys[(this.currentMusicIdx + i - Math.floor(this.nearestMusicIdx.length / 2) + this.musicListKeys.length) % this.musicListKeys.length];
    //   }
    // }
  }
  
  public onScroll(event: any){
    const threshold = 900;
    this.wheelDelta += event.wheelDelta;

    while (this.wheelDelta >= threshold) {
      this.wheelDelta = 0;
      this.onKeyup({keyCode:38});
    }
    while (this.wheelDelta <= -threshold) {
      this.wheelDelta = 0;
      this.onKeyup({keyCode:40});
    }
  }

  public onCanPlay() {
    this.init_visualization();
  }

  public init_visualization() {
    if (this.audioSelector?.nativeElement == undefined) {
      setTimeout(() => this.init_visualization(), 100);
    }
    else if(this.visualizerHandle == undefined) {
      const audioContext = new AudioContext();
        
      const audioSourceNode = audioContext.createMediaElementSource(this.audioSelector.nativeElement);
      const audioAnalyser = audioContext.createAnalyser();
      
      audioAnalyser.fftSize = 512;
      audioSourceNode.connect(audioAnalyser);
      audioSourceNode.connect(audioContext.destination);
      
      let frequencyData = new Uint8Array(audioAnalyser.frequencyBinCount);
      let barSize = 2;
      let barMaxHeight = 70;
      let barHeightRatio = 255 / barMaxHeight;

      this.visualizationContext = this.visualizationCanvas.nativeElement.getContext('2d');
      this.visualizationContext.canvas.width = frequencyData.length * barSize;
      this.visualizationContext.canvas.height = barMaxHeight * 2 + 10;

    
      const renderFrame = () => {
        audioAnalyser.getByteFrequencyData(frequencyData);
        this.visualizationContext.clearRect(0, 0, this.visualizationContext.canvas.width, this.visualizationContext.canvas.height);
        
        this.visualizationContext.fillStyle = '#FFFFFF';
        this.visualizationContext.fillRect(0, this.visualizationContext.canvas.height / 2 - 1, this.visualizationContext.canvas.width * 0.7, 1);

        for (let i = 0; i < frequencyData.length; i++) {
          let height = frequencyData[i] / barHeightRatio;
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 - height, barSize, height);
        }
        
        this.visualizationContext.fillStyle = '#FFFFFF66';
        
        for (let i = 0; i < frequencyData.length; i++) {
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 + 5, barSize, frequencyData[i] / barHeightRatio);
        }

        this.visualizerHandle = requestAnimationFrame(renderFrame);  
      }

      renderFrame();
    }
  }

  public back() {
    this.router.navigate([`/menu/select_category`]);
  }
  public onLeave(event: any) {
    setTimeout(()=>this.back(), 0);
  }
}
