import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PlayService {
  private result_cache: any = {};
  
  constructor(private httpService: HttpService) { 
    this.set_result('0', {
      rank: 'F',
      point: Math.random()*1000000,
      score: Math.random()*1000000,
      combo: {
        life: 1,
        maxCombo: Math.floor(Math.random()*1000000),
        count: Math.floor(Math.random()*1000000),
        perfect : Math.floor(Math.random()*100000),
        excellent : Math.floor(Math.random()*10000),
        good : Math.floor(Math.random()*1000),
        bad : Math.floor(Math.random()*100),
        miss: Math.floor(Math.random()*10),
        total: Math.floor(Math.random()*1),
      }
    })
  }

  public get_result(key: string) {
    return this.result_cache[key];
  }
  public set_result(key:string, data: any) {
    this.result_cache[key] = data;
  }
  public get_category(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/play/category`);
  }
  public get_list(tid: string = ''): Observable<any> {
    if (tid?.length > 4) {
      return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/play/list/${tid}`);
    }
    else {
      return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/play/list`);
    }
  }
  public get_metadata(mid: string): Observable<any> {
    let param = {mid: mid};

    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/play/music/meta`, this.httpService.package_param(param));
  }
  public get_music(mid: string): Observable<any> {
    let param = {mid: mid};

    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/play/music`, this.httpService.package_param(param));
  }
  public send_result(game_id: string, mid: string, score: number, maxCombo: number, miss: number, total: number, log: any = undefined): Observable<any> {
    let param = {mid, score, maxCombo, miss, total, log};

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/play/result/${game_id}`, param);
  }

  public load_ranking(mid: string): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/play/ranking/${mid}`);
  }
  public load_replay(replayId: string): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/play/replay/${replayId}`);
  }
}
