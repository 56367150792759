import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

import { SettingService } from 'src/app/service/setting.service';
import { StatusService } from 'src/app/service/status.service';
import { GameManagerService } from 'src/app/service/game-manager.service';

@Component({
  selector: 'app-stage-music-dialog',
  templateUrl: './stage-music-dialog.component.html',
  styleUrls: ['./stage-music-dialog.component.scss']
})
export class StageMusicDialogComponent {
  @ViewChild('audio') audioSelector: ElementRef = {} as ElementRef;
  
  public myMusicList: Array<any> = [];
  public currentMusic: any = {}
  public init: boolean = false;

  public shuffle: boolean = false;
  public loop: boolean = false;
  public mute: boolean = false;
  public isPlay: boolean = true;
  public seekBarWidthPercent: number = 0;
  public seekBarTime: string = "--:--";
  
  private onPaintHandle: number = 0;

  constructor(
    public dialogRef: MatDialogRef<StageMusicDialogComponent>,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private status: StatusService,
    private setting: SettingService,
    public gms: GameManagerService,
  ) { 
    this.status.isOpenDialog = true;
    this.myMusicList = this.data.myMusicList;

    this.shuffle = localStorage.getItem('stage-music-shuffle') == '1';
    this.loop = localStorage.getItem('stage-music-loop') == '1';

    this.get_musicList();


    const animate = () => {
      this.onPaintHandle = requestAnimationFrame(animate);
      
      if (this?.audioSelector?.nativeElement?.duration) {
        this.seekBarWidthPercent = (this.audioSelector.nativeElement.currentTime / this.audioSelector.nativeElement.duration) * 100;
        this.seekBarTime = `${('00' + Math.floor(this.audioSelector.nativeElement.currentTime / 60)).slice(-2)}:${('00' + Math.floor(this.audioSelector.nativeElement.currentTime) % 60).slice(-2)}`
      }
    }
    animate();
  }

  ngOnDestroy(): void {
    if (this.onPaintHandle) {
      cancelAnimationFrame(this.onPaintHandle);
      this.onPaintHandle = 0;
    }

    for (const key in this.currentMusic) {
      this.data.selectMusic[key] = this.currentMusic[key];
    }

    this.status.isOpenDialog = false;
  }
  updateClosedReason(reason: string): void {
    this.data.closedReason = reason;
  }
  close (reason:string | undefined = undefined): void {
    if (reason) {
      this.dialogRef.close(reason);
    }
    else {
      this.dialogRef.close();
    }
  }

  public onKeyUp(event: any) {
    switch(event.code) {
      case 'ArrowLeft':
        if (this.audioSelector?.nativeElement?.currentTime) {
          this.audioSelector.nativeElement.currentTime = Math.max(this.audioSelector.nativeElement.currentTime - 5, 0);
        }
        break;
      case 'ArrowRight':
        if (this.audioSelector?.nativeElement?.currentTime) {
          this.audioSelector.nativeElement.currentTime = Math.max(this.audioSelector.nativeElement.currentTime + 5, 0);
          
          if (this.audioSelector.nativeElement.currentTime > this.audioSelector.nativeElement.duration) {
            this.next_music(1);
          }
        }
        break;
      case 'ArrowDown':
        this.next_music(-1, true);
        break;
      case 'ArrowUp':
        this.next_music(1);
        break;
    }
    console.log(event);
  }
  public onSeek(event: any, isChild: boolean = false) {
    const percent = event?.offsetX / ((isChild ? event?.target?.parentElement?.clientWidth : event?.target?.clientWidth) || 1);

    this.audioSelector.nativeElement.currentTime = this.audioSelector.nativeElement.duration * percent;
    console.log(event);
    console.log(percent);

    event.preventDefault();
    event.stopPropagation();

    return false;
  }
  
  public get_musicList(){
    const open_failedDialog = (error = undefined) => {
      this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'Warning',
          content : error ? JSON.stringify(error) : 'Sorry, failed the music loading.<br>Please try again in a moment.',
          button : [
            { text : 'OK', color : '#333' },
          ],
        },
      });
    }

    this.setting.get_availableMusic().subscribe((res) => {
      if (res.status) {
        for (let i = 0; i < res?.data?.length; i++) {
          res.data[i].index = i;
        }
        
        this.myMusicList = res.data;
        this.init = true;

        if (this.myMusicList?.length) {
          this.currentMusic = this.myMusicList[0];
        }
      } else {
        return open_failedDialog();
      }
    }, error => open_failedDialog(error))
  }

  public update_currentMusic(item: any) {
    this.currentMusic = item;
    console.log(this.currentMusic);
  }

  public togglePlay() {
    this.isPlay = !this.isPlay;
    this.isPlay ? this.audioSelector.nativeElement.play() : this.audioSelector.nativeElement.pause();
  }
  public toggleShuffle() {
    this.shuffle = !this.shuffle;
    localStorage.setItem('stage-music-shuffle', this.shuffle ? '1' : '0');
  }
  public toggleLoop() {
    this.loop = !this.loop;
    localStorage.setItem('stage-music-loop', this.loop ? '1' : '0');
  }

  public next_music(offset: number, force: boolean = false) {
    let index = this.currentMusic.index;
    let _offset = offset;
    
    if (this.shuffle) {
      _offset = Math.floor(10000 * Math.random())
    }
    if (!force && offset < 0 && this.audioSelector?.nativeElement?.currentTime > 5) {
      this.audioSelector.nativeElement.currentTime = 0;
      _offset = 0;
    }

    let target = (index + this.myMusicList?.length + _offset) % this.myMusicList?.length;
    
    this.currentMusic = this.myMusicList[target];
  }
}