import { Component, OnInit } from '@angular/core';

import { ShopService } from "../../service/shop.service";

@Component({
  selector: 'app-point-history',
  templateUrl: './point-history.component.html',
  styleUrls: ['./point-history.component.scss']
})
export class PointHistoryComponent implements OnInit {
  public point_log: Array<any> = []
  public page: number = 1;
  public endpage: number = 0;
  public total: number = 0;
  public numOfRows: number = 20;

  public src = 'ko';
  public dest = 'en';
  public input = '';
  public output = '';


  constructor(
    public shopService: ShopService,
  ) { 
    this.load_page(0);
  }

  ngOnInit(): void {
  }

  public load_page(offset: number) {
    this.page += offset;

    this.shopService.get_point_log(this.page - 1, this.numOfRows).subscribe((res)=>{
      console.log(res);

      if (res.status){
        this.point_log = res.data;
        this.endpage = Math.ceil(res.total / this.numOfRows);
      }
    })
  }
}
