<div class="stage-setting-wrapper" [style.--background]="controlPanel.image.image_url" [style.--filter]="controlPanel.image.filter">
  
  <div class="toolbar">
    <div class="soulbeat-icon-wrapper">
      <div class="soulbeat-icon-rect" (click)="request_sdxl()">
        <div class="soulbeat-icon-info">
          <div class="soulbeat-icon-level"><i class="fa-solid fa-paint-brush"></i></div>
          <div class="soulbeat-icon-label">AI Drawing</div>
        </div>
      </div>
      <div class="soulbeat-icon-rect" (click)="select_wallpaper()">
        <!-- <input type="file" accept='.png, .jpg, .jpeg, .webp, .gif, .mp4' (change)="fileBrowseHandler($event, 'wallpaper')"/> -->
        <!-- <input type="file" accept='.png, .jpg, .jpeg, .webp, .gif, .mp4' (change)="fileBrowseHandler($event, 'wallpaper')"/> -->
        <div class="soulbeat-icon-info">
          <div class="soulbeat-icon-level"><i class="fa-solid fa-image"></i></div>
          <div class="soulbeat-icon-label">Wallpaper</div>
        </div>
      </div>

      <div class="soulbeat-icon-rect" (click)="select_music()">
        <div class="soulbeat-icon-info">
          <div class="soulbeat-icon-level"><i class="fa-solid fa-music"></i></div>
          <div class="soulbeat-icon-label">Music</div>
        </div>
      </div>

      <div class="soulbeat-icon-rect" (click)="select_model()">
        <!-- <input type="file" accept='.pmx' (change)="fileBrowseHandler($event, 'model')"/> -->
        <!-- <input type="file" accept='.pmx' (change)="fileBrowseHandler($event, 'model')"/> -->
        <div class="soulbeat-icon-info">
          <div class="soulbeat-icon-level">
            <i class="fa-solid fa-street-view"></i>
          </div>
          <div class="soulbeat-icon-label">Model</div>
        </div>
      </div>
      <div class="soulbeat-icon-rect" (click)="select_motion()">
        <div class="soulbeat-icon-info">
          <div class="soulbeat-icon-level">
            <i class="fa-solid fa-person-running"></i>
          </div>
          <div class="soulbeat-icon-label">Motion</div>
        </div>
      </div>
      <div class="soulbeat-icon-rect" (click)="select_emotion()">
        <div class="soulbeat-icon-info">
          <div class="soulbeat-icon-level">
            <i class="fa-solid fa-face-grin"></i>
          </div>
          <div class="soulbeat-icon-label">Emotion</div>
        </div>
      </div>  
    </div>
  </div>
  
  <div class="control-panel-wrapper">
    <div class="control-panel">
      <div class="title red-color">AI Drawing</div>
      <div class="slider">
        <div class="label">Seed</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.sdxl.seed" step="1" min="0" value="0" max="65535" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">CFG</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.sdxl.cfg" step="1" min="0" value="0" max="20" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Step</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.sdxl.step" step="1" min="0" value="0" max="40" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="textarea">
        <div class="label">Prompt</div>
        <textarea rows="5" matInput [(ngModel)]="controlPanel.sdxl.prompt"></textarea>
      </div>
      <div class="textarea">
        <div class="label">Negative<br>Prompt</div>
        <textarea rows="5" matInput [(ngModel)]="controlPanel.sdxl.negativePrompt"></textarea>
      </div>
    </div>

    <div class="control-panel">      
      <div class="title yellow-color">Wallpaper</div>
      <div class="slider">
        <div class="label">Blur</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.image.blur" step="1" min="0" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">GrayScale</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.image.grayscale" step="0.01" min="0" value="0" max="1" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Brightness</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.image.brightness" step="0.01" min="1" value="1" max="10" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Contrast</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.image.contrast" step="0.01" min="1" value="1" max="10" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">HueRotate</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.image.hue_rotate" step="1" min="-360" value="0" max="360" (change)="onChange($event)"></mat-slider>
      </div>
    </div>

    <div class="control-panel">  
      <div class="title green-color">Music</div>
      <div class="slider">
        <div class="label">Highlight</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.music.highlight" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Volume</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.music.volume" step="0.01" min="0" value="0" max="1" (change)="onChange($event)"></mat-slider>
      </div>
    </div>

    <div class="control-panel">  
      <div class="title green-color">Model</div>
      <div class="slider">
        <div class="label">X</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.model.x" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Y</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.model.y" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Z</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.model.z" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
    </div>

    <div class="control-panel">  
      <div class="title blue-color">Camera</div>
      <div class="slider">
        <div class="label">X</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.camera.x" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Y</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.camera.y" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Z</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.camera.z" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
    </div>

    <div class="control-panel">  
      <div class="title purple-color">LookAt</div>
      <div class="slider">
        <div class="label">X</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.lookAt.x" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Y</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.lookAt.y" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
      <div class="slider">
        <div class="label">Z</div>
        <mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="controlPanel.lookAt.z" step="0.1" min="-30" value="0" max="30" (change)="onChange($event)"></mat-slider>
      </div>
    </div>
  </div>

  <div id="stage-mmd-wrapper" [class]="{'fade-in':loading?.complete}"></div>
</div>

<audio [volume]="gms.setting?.setting?.volume" [muted]="mute" [src]="controlPanel?.music?.audio_url" autoplay loop></audio>