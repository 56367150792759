import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { HttpService } from './http.service';

const defaultKey = ['KeyS','KeyD','KeyF','Space','KeyJ','KeyK','KeyL']
const defaultSubKey = ['', '', '', '', '', '', ''];
const defaultNoteColor = ['#FF4444', '#4444FF', '#FF4444', '#4444FF', '#FF4444', '#4444FF', '#FF4444'];
const defaultEffectColor = ['#000000', '#FFFFFF', '#000000', '#FFFFFF', '#000000', '#FFFFFF', '#000000'];

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  public setting: any = {}
  public stage: any = {}
  public myPoseList: any = [];
  public myModelList: any = [];
  
  constructor(
    private httpService: HttpService
  ) { 
    this.get_setting();
    this.get_stage();
  }

  public get_setting() {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/setting`).subscribe((res: any)=>{
      if (res.status) {
        this.setting = res.data;
      }

      this.setting.volume = this.setting.volume || 0.4;
      this.setting.boardWidth = this.setting.boardWidth || 480;
      this.setting.noteSpeed = this.setting.noteSpeed || 1.5;
      this.setting.answerLine = this.setting.answerLine || 0.85;
      this.setting.mmdDisplay = this.setting.mmdDisplay || "1";
      this.setting.videoDisplay = this.setting.videoDisplay || "1";
      this.setting.bgImageDisplay = this.setting.bgImageDisplay || "1";
      this.setting.hitEffectDisplay = this.setting.hitEffectDisplay || "1";
      this.setting.keyPressEffectDisplay = this.setting.keyPressEffectDisplay || "1";
      this.setting.difficult = this.setting.difficult || "0";
      
      if (this.setting.lines == undefined) {
        this.setting.lines = [];
        this.setting.volume = 0.4;
        this.setting.noteSpeed = 1.5;
        
        for (let i = 0; i < 7; i++) {
          this.setting.lines.push({
            key : defaultKey[i],
            subkey : defaultSubKey[i],
            color : defaultNoteColor[i],
            effect : defaultEffectColor[i],
          })
        }
      }

      this.setting.init = true;
    });
  }
  public set_setting(setting: any) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/setting`, setting).subscribe((res:any) => {
      this.get_setting();
    });
  }


  public get_stage() {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/stage`).subscribe((res: any)=>{
      if (res.status) {
        this.stage = res.data;
        this.myPoseList = res.pose;
        this.myModelList = res.model;
        this.update_stageFilter();
      }
    });
  }
  public set_stage(setting: any) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/stage`, setting).subscribe((res:any) => {
      this.get_stage();
    });
  }

  public get_wallpaper(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/stage/wallpaper`);
  }
  public upload_wallpaper(file: File): Observable<any> {
    const formdata = new FormData();
  
    formdata.append('file', file);
    
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/stage/wallpaper`, formdata);;
  }
  public create_wallpaper(cfg: number, seed: number, step: number, prompt: string, negative_prompt: string): Observable<any> {
    let data = { cfg, seed, step, prompt, negative_prompt };

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/stage/wallpaper/ai`, data);
  }
  public get_availableMusic(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/stage/music`);
  }
  

  public update_stageFilter() {
    this.stage.cover_filter = `blur(${this.stage.blur}px) grayscale(${this.stage.grayscale}) brightness(${this.stage.brightness}) contrast(${this.stage.contrast}) hue-rotate(${this.stage.hue_rotate}deg)`
  }
}
