import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NetworkService } from 'src/app/service/network.service';
import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-drawing-dialog',
  templateUrl: './drawing-dialog.component.html',
  styleUrls: ['./drawing-dialog.component.scss']
})
export class DrawingDialogComponent {
  @ViewChild('canvas', { static: true })
  canvas!: ElementRef<HTMLCanvasElement>;

  private ctx!: CanvasRenderingContext2D;

  public drawing = false;
  public prevX = 0;
  public prevY = 0;
  public penColor = '#000000';
  public penSize = 5;
  public penStyle = 'round';
  public penJoinStyle = 'round';
  public canvasColor = 'transparent';
  public penOpacity = 1;
  public eraserActive = false;


  constructor(
    public dialogRef: MatDialogRef<DrawingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private status: StatusService,
    public network: NetworkService,
  ) { 
    this.status.isOpenDialog = true;
  }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    this.ctx.canvas.width = window.innerHeight * 0.8;
    this.ctx.canvas.height = window.innerHeight * 0.45;
    this.canvas.nativeElement.width = window.innerHeight * 0.8;
    this.canvas.nativeElement.height = window.innerHeight * 0.45;
    this.changeCanvasColor();

    this.network.socket?.on('drawing', (data: any) => {
      this.drawFromSocket(data);
    });
  }
  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }
  


  public drawFromSocket(data: any): void {
    this.ctx.strokeStyle = data.color;
    this.ctx.globalAlpha = data.opacity;
    this.ctx.lineWidth = data.lineWidth;
    this.ctx.lineCap = data.lineCap as CanvasLineCap;
    this.ctx.lineJoin = data.lineJoin as CanvasLineJoin;
    
    this.ctx.beginPath();
    this.ctx.moveTo(data.prevX, data.prevY);
    this.ctx.quadraticCurveTo((data.prevX + data.currentX) / 2, (data.prevY + data.currentY) / 2, data.currentX, data.currentY);
    this.ctx.stroke();
    this.ctx.closePath();
  }
  public changeCanvasColor(): void {
    this.ctx.fillStyle = this.canvasColor;
    this.ctx.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }
  public activateEraser(): void {
    this.eraserActive = !this.eraserActive;
    this.ctx.globalCompositeOperation = this.eraserActive ? 'destination-out' : 'source-over';
  }

  public onPointerMove(event: any) {
    if (!this.drawing) {
      return;
    }
    
    console.log(event);
    
    const pressure = event.pressure || 0.1;
    const lineWidth = pressure * this.penSize;

  
    const currentX = event.clientX - this.canvas.nativeElement.offsetLeft;
    const currentY = event.clientY - this.canvas.nativeElement.offsetTop;
    const data = {
      prevX: this.prevX,
      prevY: this.prevY,
      currentX,
      currentY,
      color: this.penColor,
      opacity: this.penOpacity,
      lineWidth,
      lineCap: this.penStyle,
      lineJoin: this.penJoinStyle
    };
  
    this.ctx.strokeStyle = this.penColor;
    this.ctx.globalAlpha = this.penOpacity;
    this.ctx.lineWidth = lineWidth;
    this.ctx.lineCap = this.penStyle as CanvasLineCap;
    this.ctx.lineJoin = this.penJoinStyle as CanvasLineJoin;
  
    this.ctx.beginPath();
    this.ctx.moveTo(this.prevX, this.prevY);
    this.ctx.quadraticCurveTo((this.prevX + currentX) / 2, (this.prevY + currentY) / 2, currentX, currentY);
    this.ctx.stroke();
    this.ctx.closePath();
  
    this.prevX = currentX;
    this.prevY = currentY;

    this.network.socket?.emit('drawing', data);
  }
  public onPointerDown(event: any) {
    this.drawing = true;
    this.prevX = event.clientX - this.canvas.nativeElement.offsetLeft;
    this.prevY = event.clientY - this.canvas.nativeElement.offsetTop;
  }
  public onPointerUp(event: any) {
    this.drawing = false;
  }

  public async saveCanvasAsFile() {
    const dataURL = this.canvas.nativeElement.toDataURL('image/png');
    const response = await fetch(dataURL);
    const blob = await response.blob();
    const file = new File([blob], 'image.png', { type: 'image/png' });


    console.log(file);
    
    this.data.file = file;
    this.dialogRef.close();

    return file;
  }
  

  close(): void {
    this.dialogRef.close();
  }
}
