<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <div class="pointlog-wrapper">
    <table>
      <tr>
        <th>No.</th>
        <th>Title</th>
        <th>Num</th>
        <th>Date</th>
      </tr>
      <tr *ngFor="let log of ticket_log">
        <td>{{log.idx}}</td>
        <td>{{log.description}}
          <div class="subtext" *ngIf="log.title"> [<span class="highlight">{{log.title}}</span>] </div>
        </td>
        <td class="ticket" [ngClass]="log.type">
            <span class="sb-ticket" *ngIf="log.ticket"><i class="fa fa-credit-card-alt"></i>&nbsp;{{log.ticket}}</span>
        </td>
        <td class="time">{{log.time}}</td>
      </tr>    
    </table>

    <div class="pagenation-wrapper">
      <span class="pagenation-indicator">
        <span class="pagenation-indicator">
          <span (click)="load_page(-1)" *ngIf="page > 1">
            <i class="fa fa-angle-left"></i> &nbsp;
          </span>
          <span>{{page}} / {{endpage}}</span>
          <span (click)="load_page(1)" *ngIf="page < endpage">
            &nbsp; <i class="fa fa-angle-right"></i>
          </span>
        </span>
      </span>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text">{{button.text}}</button>
    <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
  </div>
</div>