import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { interval, firstValueFrom, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CraftService {
  constructor(private httpService: HttpService) { }


  public get_list(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/craft/list`);
  }
  public get_tags(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/craft/tag/all`);
  }
  public get_music(cmid: string): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/craft/music/${cmid}`);
  }
  public publish_music(cmid: string): Observable<any> {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/craft/music/publish/${cmid}`, {});
  }

  public async upsert_music(
    cmid: string, title: string, description: string, artist: string, 
    notes: any, mmd: any, 
    thumbnail: any, cover:any, audio: any, video: any, duration: number, audio_highlight: number,
    dlc_expired_count: number, dlc_day_price: number, dlc_lifelong_price: number, tags: Array<string>, 
    divideTime: number, audioRate: number, autoCreateNoteThreshold: number, autoCreateNoteTimeThreshold: number, autoCreateNoteMaxNote: number,
    callback: any
  ) {
    let param = {
      cmid: cmid,
      title : title,
      description: description,
      note: JSON.stringify(notes, null, 4),
      thumbnail: thumbnail,
      cover: cover,
      audio: audio,
      audio_highlight: audio_highlight,
      artist : artist,
      video: video,
      mmd: mmd ? JSON.stringify(mmd, null, 4) : null,
      duration: duration,
      dlc_expired_count: dlc_expired_count,
      dlc_day_price: dlc_day_price,
      dlc_lifelong_price: dlc_lifelong_price,
      tags: JSON.stringify(tags),
      craft_setting: JSON.stringify({divideTime, audioRate, autoCreateNoteThreshold, autoCreateNoteTimeThreshold, autoCreateNoteMaxNote})
    };

    console.log('thumbnail', typeof(thumbnail), thumbnail);
    console.log('cover', typeof(cover), cover);
    console.log('audio', typeof(audio), audio);
    console.log('video', typeof(video), video);

    if (thumbnail && typeof(thumbnail) === "object") {      
      const {path} =  await firstValueFrom(this.upload_file(thumbnail))
      param.thumbnail = path;
    }
    if (cover && typeof(cover) === "object") {
      const {path} = await firstValueFrom(this.upload_file(cover));
      param.cover = path;

    }
    if (audio && typeof(audio) === "object") {
      const {path} = await firstValueFrom(this.upload_file(audio))
      param.audio = path;
    }
    if (video && typeof(video) === "object") {
      const {path} = await firstValueFrom(this.upload_file(video))
      param.video = path;
    }
  
    console.log(param);

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/craft/music`, param).subscribe((res: any)=>{
      callback(res);
    });
  }
  
  public upload_file(file: File): Observable<any> {
    const formdata = new FormData();

  
    formdata.append('file', file);

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/craft/upload`, formdata);
  }
  public download_youtubeUrl(url: string): Observable<any> {
    let param = {url : url};

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/craft/youtube`, param);
  }
}