<div class="select-music-wrapper" (window:resize)="onResize($event)">
  <div class="play-card-wrapper">
    <div class="play-card-container" [style.width.px]='playCardContainerWidth'>
      <div class="play-card" [ngClass]="{'denied' : (!item.id || !item.able) && item.dlc_day_price}" *ngFor="let item of list" (mouseleave)="audio = null" (mouseenter)="onFocus($event, item)" (click)="play(item)">
        <div *ngIf="item.price == 0" class="ribbon ribbon-top-left">
          <span>Free to Play</span>
        </div>
        <div *ngIf="item.soldout" class="ribbon ribbon-top-left ribbon-red">
          <span>SOLD OUT!</span>
        </div>
        <div class="thumbnail-container">
          <div class="thumbnail" [style.background-image]="item.thumbnail_url">
            <div class="thumbnail-inner" *ngIf="item.id && item.able">
              <span class="thumbnail-inner-text" *ngIf="item.max_rank">
                <div class="rank">{{item.max_rank}}<b>Rank</b></div>
                <div class="accuracy">{{item.max_accuracy}}<b>%</b></div>
                <div class="score">{{item.max_score}}<b>pt</b></div>
              </span>
              <span class="thumbnail-inner-text" *ngIf="!item.max_rank">
                <div class="rank">Play Now!</div>
              </span>
            </div>
            <div class="thumbnail-inner" *ngIf="item.soldout">
              <span class="thumbnail-inner-text">
                <div class="score">Sold out!<br>You can't play this music.</div>
              </span>
            </div>
            <div class="thumbnail-inner" *ngIf="item.price && item.id && !item.able">
              <span class="thumbnail-inner-text">
                <div class="score">This music is Expired!<br>You can play again, only</div>
                <div class="require-point">{{item.price}}<i class="fa fa-star sb-point-icon"></i></div>
              </span>
            </div>
            <div class="thumbnail-inner" *ngIf="item.price && !item.id">
              <span class="thumbnail-inner-text">
                <div class="score">You can buy this music!</div>
                <div class="require-point">{{item.price}}<i class="fa fa-star sb-point-icon"></i></div>
              </span>
            </div>
          </div>
        </div>
        <div class="play-card-content">
          <div class="play-card-content-header">
            <span class="play-card-title">{{item.title}}</span>
            <span class="play-card-duration">{{item.duration_parse}}</span>
          </div>
          <div class="play-card-content-header">
            <span class="play-card-tag" *ngFor="let tag of item.tags">#{{tag}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<audio *ngIf="audio" [src]="audio" [volume]="0.2" autoplay></audio>