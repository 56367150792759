
<div class="screen">
  <div class="side-bar" *ngIf="account.session && status.sidebar" [ngClass]="{'fold-sidebar': !status.sidebarExpand}">
    <div class="expand-icon" (click)="click.toggleMenu()">
      <i class="fa fa-chevron-right"></i>
    </div>
    <img class="logo" src="../../../assets/images/logo.png">
    <div class="logo-text">SoulBeat</div>
    <div class="menu">
      <!-- <div routerLink="select">Play</div> -->
      <div routerLink="choice">Play</div>
      <div routerLink="shop">Shop</div>
      <div routerLink="craft">Craft</div>
      <div routerLink="account" *ngIf="account.session.permission >= 5">Account</div>
    </div>
    <div class="footer">
      <div class="point">
        <span class="sb-point" (click)="gms.dialog_pointlog()">{{gms.shop.point}}<i class="fa fa-star"></i></span>
        <span class="sb-ticket" (click)="gms.dialog_ticketlog()"><i class="fa fa-credit-card-alt"></i>{{gms.shop.ticket}}</span>
      </div>
      <div class="footer-menu" (click)="gms.dialog_accountSetting()">Account</div>
      <div class="footer-menu" (click)="gms.dialog_setting()">Setting</div>
      <div class="footer-menu" (click)="gms.dialog_logout()">Logout</div>
      <div class="copyright">Siya Games Corp. All Rights Reserved.</div>
    </div>
  </div>
  <div class="board">
    <router-outlet>

    </router-outlet>
  </div>
</div>

<audio [src]="network.chatAlarmSrc" style="opacity: 0;" autoplay></audio>