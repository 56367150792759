import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { ConfirmDialogComponent } from "../../dialog/confirm-dialog/confirm-dialog.component"

import { NetworkService } from 'src/app/service/network.service';
import { StatusService } from 'src/app/service/status.service';
import { PostService } from 'src/app/service/post.service';
import { GameManagerService } from 'src/app/service/game-manager.service';
import { ImageDialogComponent } from 'src/app/dialog/image-dialog/image-dialog.component';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  @ViewChild('messageWrapper', { static: false }) 
  private messageWrapper!: ElementRef;
  
  public setting = {
    init: false,
    darkmode: false,
    toggle: {
      darkmode: () => { 
        this.setting.darkmode = !this.setting.darkmode; 
        localStorage.setItem('darkmode', `${this.setting.darkmode}`)
        console.log(this.setting);
      }
    }
  }
  public chat: any = {
    width: 307,
    messages: this.status.youtubeChat,
    replyMessage: undefined,
    input: '',
    fold: false,
    requireUpload: false,
    files: [],
    toggle: () => {
      this.chat.fold = !this.chat.fold;
    },
    reply: (chat: any) => {
      this.chat.replyMessage = chat;
    },
    send: () => {
      const chat = this.chat.input.trim();
      
      for (let i = 0; i < this.chat.files.length; i++) {
        const file = this.chat.files[i];
        
        this.postService.upload_file(file.file).subscribe((res)=>{
          if (res.status) {
            this.network.youtube_file(res?.path);
          }
        })
      }

      if (chat?.length > 0 || this.chat.replyMessage) {
        if (this.chat.replyMessage) {
          this.network.youtube_chat(`<pre>${this.chat.replyMessage?.messageHTML}</pre>${chat}`, '');
        }
        else {
          this.network.youtube_chat(chat, '');
        }
        this.chat.input = '';
      }

      this.chat.files = [];
      this.chat.replyMessage = undefined;
    }
  }
  public url: string = '';
  public alarmURL: string = '';
  public sec: number = 0;
  public foreground: boolean = false;
  

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2,

    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public network: NetworkService,
    public status: StatusService,
    public postService: PostService,
    public gms: GameManagerService,
  ) { 
    this.network.connect('');
    this.network.joinRoom(route.snapshot.params['roomid'] || 'general');
    this.setting.darkmode = localStorage.getItem('darkmode') == 'true';

    window.addEventListener('focus', () => {
      this.foreground = true;
    });
    window.addEventListener('blur', () => {
      this.foreground = false;
    });
  }

  ngOnDestroy(): void {
    this.status.sidebar = true;
  }
  ngOnInit(): void {
    this.status.sidebar = false;
    this.status.youtubeChatCallback = () => {
      this.alarmURL = ``;

      setTimeout(() => {
        if (this.foreground == false || document.visibilityState !== 'visible') {
          this.alarmURL = `https://soulbeat.siya.co.kr/static/system/message_alarm.mp3`;
        } 

        this.scrollToBottom();
      }, 50);
    }
  }

  public update_url() {
    this.network.youtube_changeURL(this.url);
  }
  public update_time() {
    if(this.sec < 0) {
      this.sec = 0;
    }
    this.network.youtube_onSeek(this.sec);
  }
  public pause() {
    this.status.youtubePause = !this.status.youtubePause;
    this.network.youtube_onPause(this.status.youtubePause);
  }

  public init_click(){
    this.setting.init = true;
  }

  public onKeyup(event: any) {
    switch(event.code) {
      case 'Space':
        this.pause();
        break;
      case 'ArrowLeft':
        this.sec = this.status.youtubeCurrentTime + Math.round((new Date().getTime() - this.status.youtubeCurrentTimePivot) / 1000) - 10;
        this.update_time();
        break;
      case 'ArrowRight':
        this.sec = this.status.youtubeCurrentTime + Math.round((new Date().getTime() - this.status.youtubeCurrentTimePivot) / 1000) + 10;
        this.update_time();
        break;
    }
  }

  public onPrevent(event: any) {
    switch(event.key) {
      case 'Enter':
        if (!event?.shiftKey) {
          event.preventDefault();
          event.stopPropagation();
        }
        // event.code = 'ArrowDown'
        break;
    }
  }

  public onResize(event: any) {
    console.log('onResize');
    console.log(event);
  }

  public onClickChat(e: any) {
    const event = e?.target?.className;
    
    if (event == "chat-time") {
      let timeToken = e?.target?.innerText.split(/:/gim);
      let time = 0;
      
      for (let t of timeToken) {
        time *= 60;
        time += +t;
      }

      if (time < 0) {
        time = 0;
      }
      
      this.sec = time;
      this.network.youtube_onSeek(this.sec);
    }
    else if (event == 'chat-iamge') {
      console.log(e);
      this.dialog.open(ImageDialogComponent, {
        width : '95vw',
        height : '95vh',
        panelClass: 'transparent',
        data : {
          image: e?.target?.src,
        },
      });
    }
    else if (event == "chat-link") {
      let url = e?.target?.innerText;
      
      window.open(url, '#');
    }
    else if (event == "chat-youtube-link") {
      let url = e?.target?.innerText;
      
      this.url = url;
      this.network.youtube_changeURL(this.url);
    }
  }

  private scrollToBottom(): void {
    try {
      this.messageWrapper.nativeElement.scrollTop = this.messageWrapper.nativeElement.scrollHeight;
    } catch (err) {
      console.log(err);
    }
  }
  
  public onPaste(event: ClipboardEvent) {
    const items: any = event.clipboardData?.items;
  
    if (items) {
      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();
          this.prepareFilesList([file]);
        }
      }
    }
  }
  public drawing() {
    this.gms.dialog_drawing((data: Array<File>)=>{
      this.prepareFilesList(data);
    })
  }
  public drawing_fabric() {
    this.gms.dialog_drawing_fabric((data: Array<File>)=>{
      this.prepareFilesList(data);
    })
  }

  public onFileDropped($event: any) {
    this.prepareFilesList($event);
  }
  public fileBrowseHandler(files: any) {
    this.prepareFilesList(files?.target?.files);
  }
  public deleteFile(idx: number) {
    if (idx >= 0 && idx < this.chat.files.length) {
      this.chat.files.splice(idx, 1);
    }
  }
  public prepareFilesList(files: Array<any>) {
    console.log(files);
    
    for (const item of files) {
      let url = URL.createObjectURL(item);

      this.chat.files.push({
        file: item, 
        url: url,
        safeURL: this.sanitizer.bypassSecurityTrustResourceUrl(url),
        backgroundURL: `url('${url}')`
      })

      this.chat.requireUpload = true;
    }
  }


  
  public onMouseDown(event: MouseEvent): void {
    let sx = event.clientX;
    let sw = this.chat.width;
  
    event.preventDefault();
    
    let mousemoveListener = this.renderer.listen('document', 'mousemove', (e: MouseEvent) => {   
      const dx = e.clientX - sx;
      
      this.chat.width = sw - dx;
    });
    let mouseupListener = this.renderer.listen('document', 'mouseup', () => {
      if (mousemoveListener) {
        mousemoveListener();
      }
      if (mouseupListener) {
        mouseupListener();
      }
    });
  }
}
