
<div class="video-container">
  <div class="video-guard">
    <div class="video-info">You can get <span class="sb-point">{{point}}<i class="fa fa-star"></i></span> after {{sec}} second!!</div>
  </div>
  <iframe 
    *ngIf="url"
    #videoFrame allowfullscreen
    width="100%" height="100%" frameborder="0"
    [src]="url"
    allow="autoplay; encrypted-media"
    autoplay
  ></iframe>
</div>
    