import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FriendService } from 'src/app/service/friend.service';
import { StatusService } from 'src/app/service/status.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-friend-info-dialog',
  templateUrl: './friend-info-dialog.component.html',
  styleUrls: ['./friend-info-dialog.component.scss']
})
export class FriendInfoDialogComponent {
  public friendCode = '';
  public progress: number = 0;
  public requestUpload: boolean = false;
  public profileChanged: boolean = false;
  public profile = {
    name: '',
    message: '',
  }
  public files: any = {
    profile_image: undefined,
  };

  constructor(
    public dialogRef: MatDialogRef<FriendInfoDialogComponent>,
    public friend: FriendService,
    private status: StatusService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
  ) { 
    this.status.isOpenDialog = true;
    this.files.profile_image_url = this.friend?.me?.profile;
    this.profile.name = this.friend?.me?.name;
    this.profile.message = this.friend?.me?.message;
  }

  
  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }

  close(): void {
    this.status.isOpenDialog = false;
    this.dialogRef.close();
  }
  public onInput(event: any){
    return event.preventDefault();
  }

  public onFileDropped($event: any, key: string) {
    this.prepareFilesList($event, key);
  }
  public fileBrowseHandler(files: any, key: string) {
    this.prepareFilesList(files?.target?.files, key);
  }
  public deleteFile(key: string) {
    this.files[key] = undefined;
    this.files[`${key}_url`] = undefined;
    this.profileChanged = true;
  }
  public prepareFilesList(files: Array<any>, key: string) {
    for (const item of files) {
      let url = URL.createObjectURL(item);

      this.progress = 0;
      this.files[key] = item;
      this.files[`${key}_url`] = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.profileChanged = true;
    }
  }

  


  public friendContextMenu: any = {
    show: false,
    x: 0,
    y: 0,
    title: undefined,
    content: undefined,
    option: [{
      label: 'Show detail',
      key: 'friend_info',
      color: '#333',
      hover: {
        color: 'purple',
      }
    }, {
      label: 'Send note',
      key: 'friend_note',
      color: '#333',
      hover: {
        color: 'purple',
      }
    }, {
      label: 'Start chat',
      key: 'friend_chat',
      color: '#333',
      hover: {
        color: 'purple',
      }
    }, {
      label: 'Delete',
      key: 'delete_friend',
      color: '#333',
      hover: {
        color: 'red',
      }
    }],
    data: {},
    open: (event: MouseEvent, friend: any) => {
      event.preventDefault();
      this.friendContextMenu.show = true;
      this.friendContextMenu.x = event.clientX;
      this.friendContextMenu.y = event.clientY;
      this.friendContextMenu.data = friend;
    },
    close: () => {
      this.friendContextMenu.show = false;
    }
  }

  onMenuItemSelected(select: string) {
    const target = this.friendContextMenu.data;
    this.friendContextMenu.close();
    switch(select) {
      default:
        console.log(select, target);
        break;
    }
  }

}
