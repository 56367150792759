<div class="login-wrapper">
  <div class="fullscreen">
    <div id="background-room">
      <div class="house" id="h"> 
        <div class="floor">
          <div class="floor__top face"> 
            <div class="light"></div>
            <div class="light"></div>
          </div>
        </div>
        <div class="left-wall">
          <div class="left-wall__right face"></div>
        </div>
        <div class="right-wall">
          <div class="right-wall-window__front"></div>

          <div class="wall">
            <div class="wall__front face"></div>
            <div class="wall__right face"></div>
          </div>

          <div class="wall">
            <div class="wall__front face"></div>
            <div class="wall__right face"></div>
            <div class="wall__left face"></div>
          </div>
        </div>
        <div class="door">
          <div class="door-front">
            <div class="door-front__front face"> </div>
            <div class="door-front__back face"> </div>
            <div class="door-front__right face"> </div>
            <div class="door-front__left face"> </div>
            <div class="door-front__top face"> </div>
            <div class="door-front__bottom face"> </div>
          </div>
        </div>
        
        <div class="ad-frame">
          <div class="ad-frame__front face"> </div>
          <div class="ad-frame__back face"> </div>
          <div class="ad-frame__right face"> </div>
          <div class="ad-frame__left face"> </div>
          <div class="ad-frame__top face"> </div>
          <div class="ad-frame__bottom face"> </div>
        </div>
        <div class="window-desk">
          <div class="window-desk__front face"></div>
          <div class="window-desk__top face"></div>
          <div class="window-desk__bottom face"></div>

          <div class="macbook-pro">
            <div class="macbook-pro-pane">
              <div class="macbook-pro-pane__front face"></div>
              <div class="macbook-pro-pane__back face"></div>
              <div class="macbook-pro-pane__right face"></div>
              <div class="macbook-pro-pane__left face"></div>
              <div class="macbook-pro-pane__top face"></div>
              <div class="macbook-pro-pane__bottom face"></div>
            </div>
            <div class="macbook-pro-pane">
              <div class="macbook-pro-pane__front face"></div>
              <div class="macbook-pro-pane__back face"></div>
              <div class="macbook-pro-pane__right face"></div>
              <div class="macbook-pro-pane__left face"></div>
              <div class="macbook-pro-pane__top face"></div>
              <div class="macbook-pro-pane__bottom face"></div>
            </div>
          </div>

          <div class="monitor">
            <div class="monitor__front face"></div>
            <div class="monitor__back face"></div>
            <div class="monitor__right face"></div>
            <div class="monitor__left face"></div>
            <div class="monitor__top face"></div>
            <div class="monitor__bottom face"></div>
            <div class="monitor__poll">
              <div class="monitor__poll__front face"></div>
              <div class="monitor__poll__back face"></div>
              <div class="monitor__poll__right face"></div>
              <div class="monitor__poll__left face"></div>
              <div class="monitor__poll__top face"></div>
              <div class="monitor__poll__bottom face"></div>
            </div>
            <div class="monitor__poll">
              <div class="monitor__poll__front face"></div>
              <div class="monitor__poll__back face"></div>
              <div class="monitor__poll__right face"></div>
              <div class="monitor__poll__left face"></div>
              <div class="monitor__poll__top face"></div>
              <div class="monitor__poll__bottom face"></div>
            </div>
            <div class="monitor__poll">
              <div class="monitor__poll__front face"></div>
              <div class="monitor__poll__back face"></div>
              <div class="monitor__poll__right face"></div>
              <div class="monitor__poll__left face"></div>
              <div class="monitor__poll__top face"></div>
              <div class="monitor__poll__bottom face"></div>
            </div>
          </div>

          <div class="monitor">
            <div class="monitor__front face"></div>
            <div class="monitor__back face"></div>
            <div class="monitor__right face"></div>
            <div class="monitor__left face"></div>
            <div class="monitor__top face"></div>
            <div class="monitor__bottom face"></div>
            <div class="monitor__poll">
              <div class="monitor__poll__front face"></div>
              <div class="monitor__poll__back face"></div>
              <div class="monitor__poll__right face"></div>
              <div class="monitor__poll__left face"></div>
              <div class="monitor__poll__top face"></div>
            </div>
            <div class="monitor__poll">
              <div class="monitor__poll__front face"></div>
              <div class="monitor__poll__right face"></div>
              <div class="monitor__poll__left face"></div>
              <div class="monitor__poll__top face"></div>
            </div>
            <div class="monitor__poll">
              <div class="monitor__poll__front face"></div>
              <div class="monitor__poll__right face"></div>
              <div class="monitor__poll__left face"></div>
              <div class="monitor__poll__top face"></div>
            </div>
          </div>
        </div>
        
        <div class="fotos"> 
          <div class="foto">
            <div class="foto__front face"> </div>
            <div class="foto__back face"> </div>
            <div class="foto__right face"> </div>
            <div class="foto__left face"> </div>
            <div class="foto__top face"> </div>
            <div class="foto__bottom face"> </div>
          </div>
          <div class="foto">
            <div class="foto__front face"> </div>
            <div class="foto__back face"> </div>
            <div class="foto__right face"> </div>
            <div class="foto__left face"> </div>
            <div class="foto__top face"> </div>
            <div class="foto__bottom face"> </div>
          </div>
        </div>
        <div class="tv">
          <div class="tv__front face"> </div>
          <div class="tv__back face"> </div>
          <div class="tv__right face"> </div>
          <div class="tv__left face"> </div>
          <div class="tv__top face"> </div>
          <div class="tv__bottom face"> </div>
        </div>
        <div class="repisa-t">
          <div class="repisa-t__front face"> </div>
          <div class="repisa-t__back face"> </div>
          <div class="repisa-t__right face"> </div>
          <div class="repisa-t__top face"> </div>
          <div class="repisa-t__bottom face"> </div>
        </div>
        <div class="repisa-b">
          <div class="repisa-b__front face"> </div>
          <div class="repisa-b__back face"> </div>
          <div class="repisa-b__right face"> </div>
          <div class="repisa-b__top face"> </div>
          <div class="repisa-b__bottom face"> </div>
        </div>
        <div class="sofa">
          <div class="sofa-back">
            <div class="sofa-back__front face"></div>
            <div class="sofa-back__back face"></div>
            <div class="sofa-back__right face"></div>
            <div class="sofa-back__left face"></div>
            <div class="sofa-back__top face"></div>
          </div>
          <div class="sofa-top">
            <div class="sofa-top__front face"></div>
            <div class="sofa-top__back face"></div>
            <div class="sofa-top__right face"></div>
            <div class="sofa-top__left face"></div>
            <div class="sofa-top__top face"></div>
          </div>
          <div class="sofa-left">
            <div class="sofa-left__front face"></div>
            <div class="sofa-left__back face"></div>
            <div class="sofa-left__right face"></div>
            <div class="sofa-left__left face"></div>
            <div class="sofa-left__top face"></div>
          </div>
          <div class="sofa-right">
            <div class="sofa-right__front face"></div>
            <div class="sofa-right__back face"></div>
            <div class="sofa-right__right face"></div>
            <div class="sofa-right__left face"></div>
            <div class="sofa-right__top face"></div>
          </div>
          <div class="sofa-shadow"></div>
          <div class="sofa-rug"></div>
        </div>
        <div class="table">
          <div class="table-top">
            <div class="table-top__top face"></div>
          </div>
          <div class="table-poll">
            <div class="table-poll__front face"></div>
            <div class="table-poll__back face"></div>
            <div class="table-poll__right face"></div>
            <div class="table-poll__left face"></div>
            <div class="table-poll__top face"></div>
          </div>
          <div class="table-poll">
            <div class="table-poll__front face"></div>
            <div class="table-poll__back face"></div>
            <div class="table-poll__right face"></div>
            <div class="table-poll__left face"></div>
            <div class="table-poll__top face"></div>
          </div>
          <div class="table-poll">
            <div class="table-poll__front face"></div>
            <div class="table-poll__back face"></div>
            <div class="table-poll__right face"></div>
            <div class="table-poll__left face"></div>
            <div class="table-poll__top face"></div>
          </div>
          <div class="table-poll">
            <div class="table-poll__front face"></div>
            <div class="table-poll__back face"></div>
            <div class="table-poll__right face"></div>
            <div class="table-poll__left face"></div>
            <div class="table-poll__top face"></div>
          </div>
          <div class="table-shadow"></div>
        </div>
        
        <div class="chair">
          <div class="chair-t">
            <div class="chair-t__front face"> </div>
            <div class="chair-t__back face"> </div>
            <div class="chair-t__right face"> </div>
            <div class="chair-t__left face"> </div>
            <div class="chair-t__top face"> </div>
            <div class="chair-t__bottom face"> </div>
          </div>
          <div class="chair-lb">
            <div class="chair-lb__front face"> </div>
            <div class="chair-lb__back face"> </div>
            <div class="chair-lb__right face"> </div>
            <div class="chair-lb__left face"> </div>
            <div class="chair-lb__top face"> </div>
            <div class="chair-lb__bottom face"> </div>
          </div>
          <div class="chair-lt">
            <div class="chair-lt__front face"> </div>
            <div class="chair-lt__back face"> </div>
            <div class="chair-lt__right face"> </div>
            <div class="chair-lt__left face"> </div>
            <div class="chair-lt__top face"> </div>
            <div class="chair-lt__bottom face"> </div>
          </div>
          <div class="chair-rb">
            <div class="chair-rb__front face"> </div>
            <div class="chair-rb__back face"> </div>
            <div class="chair-rb__right face"> </div>
            <div class="chair-rb__left face"> </div>
            <div class="chair-rb__top face"> </div>
            <div class="chair-rb__bottom face"> </div>
          </div>
          <div class="chair-rt">
            <div class="chair-rt__front face"> </div>
            <div class="chair-rt__back face"> </div>
            <div class="chair-rt__right face"> </div>
            <div class="chair-rt__left face"> </div>
            <div class="chair-rt__top face"> </div>
            <div class="chair-rt__bottom face"> </div>
          </div>
        </div>
        <div class="chair chair--2">
          <div class="chair-t">
            <div class="chair-t__front face"> </div>
            <div class="chair-t__back face"> </div>
            <div class="chair-t__right face"> </div>
            <div class="chair-t__left face"> </div>
            <div class="chair-t__top face"> </div>
            <div class="chair-t__bottom face"> </div>
          </div>
          <div class="chair-lb">
            <div class="chair-lb__front face"> </div>
            <div class="chair-lb__back face"> </div>
            <div class="chair-lb__right face"> </div>
            <div class="chair-lb__left face"> </div>
            <div class="chair-lb__top face"> </div>
            <div class="chair-lb__bottom face"> </div>
          </div>
          <div class="chair-lt">
            <div class="chair-lt__front face"> </div>
            <div class="chair-lt__back face"> </div>
            <div class="chair-lt__right face"> </div>
            <div class="chair-lt__left face"> </div>
            <div class="chair-lt__top face"> </div>
            <div class="chair-lt__bottom face"> </div>
          </div>
          <div class="chair-rb">
            <div class="chair-rb__front face"> </div>
            <div class="chair-rb__back face"> </div>
            <div class="chair-rb__right face"> </div>
            <div class="chair-rb__left face"> </div>
            <div class="chair-rb__top face"> </div>
            <div class="chair-rb__bottom face"> </div>
          </div>
          <div class="chair-rt">
            <div class="chair-rt__front face"> </div>
            <div class="chair-rt__back face"> </div>
            <div class="chair-rt__right face"> </div>
            <div class="chair-rt__left face"> </div>
            <div class="chair-rt__top face"> </div>
            <div class="chair-rt__bottom face"> </div>
          </div>
        </div>
        <div class="outside"></div>
      </div>
    </div>
  </div>
  
  <div class="screen" *ngIf="!backgroundMode">
    <div *ngIf="step=='signup'" class="middle">
      <img class="logo" src="../../../assets/images/logo_white.png">
      <div class="title">{{title}}</div>
      <div class="subtitle">
        <b>이 페이지는 비상업적인 용도로 제작되어 이용하실 수 없습니다.</b>
        <br><br>
        페이지 이용을 위해 계정을 등록해주세요.
        <br>
        등록된 계정은 관리자 승인 후 사용 가능합니다.
      </div>
      <br>
      <div class="input-box">
        <input placeholder="닉네임" [(ngModel)]="input.signup.name" type="text" (keydown)="validation.signup.nameCheck()" class="text-input">
        <p *ngIf="input.signup.name.length > 0 && !validation.signup.name" class="input-validation">이름은 최소 4글자여야 합니다.</p>
        <input placeholder="이메일" [(ngModel)]="input.signup.email" (keydown)="validation.signup.emailCheck()" class="text-input">
        <p *ngIf="input.signup.email.length > 0 && !validation.signup.email" class="input-validation">이메일 형식이 맞지 않습니다.</p>
        <input placeholder="비밀번호" [(ngModel)]="input.signup.password" type="password" (keydown)="validation.signup.passwordCheck()" (keydown.enter)="click.signup()" class="text-input">
        <p *ngIf="input.signup.password.length > 0 && !validation.signup.password" class="input-validation">비밀번호는 최소 8글자 이상이여야 합니다.</p>
        <input *ngIf="signup_captcha" placeholder="보안문자" [(ngModel)]="input.signup.captcha" (keydown.enter)="click.signup()" type="text" class="text-input">
        <div *ngIf="validation.signup.name && validation.signup.email && validation.signup.password" (click)="click.signup()" class="facebook-signup">등록</div>
        <div *ngIf="!validation.signup.name || !validation.signup.email || !validation.signup.password" style="opacity : 0.33;" class="facebook-signup">등록</div>
        <img style="width:100%; padding:10px 0px;" *ngIf="signup_captcha" src="{{signup_captcha}}">
        <div class="agree">계속하시면 이용약관과 개인정보 취급방침에 동의하는 것으로 간주합니다.</div>
      </div>
    </div>
    <div *ngIf="step=='signin'" class="middle">
      <img class="logo" src="../../../assets/images/logo_white.png">
      <div class="title">{{title}}</div>
      <div class="subtitle">비트가 살아 숨쉬는 이 곳<br>{{title}}에 오신 여러분을 진심으로 환영합니다.</div>
      <br>
      <div class="input-box">
        <input placeholder="이메일" type="email" [(ngModel)]="input.signin.email" (keydown)="validation.signin.allCheck()" class="text-input">
        <input placeholder="비밀번호" type="password" [(ngModel)]="input.signin.password" (keydown)="validation.signin.allCheck()" (keydown.enter)="click.signin()" class="text-input">
        <input *ngIf="signin_captcha" placeholder="보안문자" [(ngModel)]="input.signin.captcha" (keydown.enter)="click.signin()" type="text" class="text-input">
        <div *ngIf="validation.signin.all" (click)="click.signin()" class="facebook-signup">로그인</div>
        <div *ngIf="!validation.signin.all" style="opacity : 0.33;" class="facebook-signup">로그인</div>
        <img style="width:100%; padding:10px 0px;" *ngIf="signin_captcha" src="{{signin_captcha}}">
        <div style="cursor:pointer;" (click)="click.reset_password()" class="agree">혹시 비밀번호가 기억나지 않으신가요?</div>
      </div>
    </div>
    <div class="top-right">
      <span *ngIf="step=='signin'" class="message">계정이 없으신가요?</span>
      <span *ngIf="step=='signup'" class="message">계정이 이미 있으신가요?</span>
      <div (click)="click.toggle_bgm()" class="button">
        <i *ngIf="!useBGM" class="fa fa-volume-off" aria-hidden="true"></i>
        <i *ngIf="useBGM" class="fa fa-volume-up" aria-hidden="true"></i>
        {{useBGM ? 'ON' : 'OFF'}}
      </div>
      <div *ngIf="step=='signup'" (click)="click.change_step('signin')" class="button">로그인</div>
      <div *ngIf="step=='signin'" (click)="click.change_step('signup')" class="button">가입</div>
    </div>
  </div>
</div>