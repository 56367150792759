import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PlayService } from "../../service/play.service";
import { StatusService } from 'src/app/service/status.service';
import { SettingService } from 'src/app/service/setting.service';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.scss']
})
export class ResultPageComponent implements OnInit {
  public result: any = {};
  public audioURL: any = null;
  public volume: number = 0.5;
  public mid: number = 0;

  constructor(
    private zone: NgZone,
    private router: Router, 
    private route: ActivatedRoute,
    public status: StatusService,
    public playService: PlayService,
    public settingService: SettingService,
  ) {     
    let key = route.snapshot.params['key'];
    this.mid = route.snapshot.params['mid'];

    this.result = this.playService.get_result(key);
    this.audioURL = `https://soulbeat.siya.co.kr/static/system/${this.result.rank !='F' ? 'success' : 'fail'}.mp3`; 
    this.volume = Math.min(Math.max(this.settingService.setting.volume, 0) * 1.5, 1);
    this.result.rankClass = this.result.rank.replace('+', 'p').replace('-', 'm');
    this.result.score = this.result.score.toFixed(0);
    this.result.point = this.result.point.toFixed(0);
  }

  ngOnInit(): void {
  }

  public back() {    
    this.router.navigate([`/choice/${this.mid}`]);
  }
}
