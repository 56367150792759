import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-purchase-dialog',
  templateUrl: './purchase-dialog.component.html',
  styleUrls: ['./purchase-dialog.component.scss']
})
export class PurchaseDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PurchaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private status: StatusService,
  ) { 
    this.status.isOpenDialog = true;
    data.item.select_price_option = -1
  }
  
  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }

  close(): void {
    this.status.isOpenDialog = false;
    this.dialogRef.close();
  }
}
