import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  constructor(private httpService: HttpService) { }

  public get_post(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/post`);
  }
  public create_post(title: string, content: string): Observable<any> {
    let param: any = {
      title: title,
      content: content,
    };

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/post`, param);
  }
  public update_post(id: number, title: string, content: string): Observable<any> {
    let param: any = {
      id: id,
      title: title,
      content: content,
    };

    return this.httpService.httpClient.put<any>(`${this.httpService.apiServer}/api/v1/post`, param);
  }
  public delete_post(id: number): Observable<any> {
    let param: any = {
      id: id
    };

    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/post`, this.httpService.package_param(param));
  }


  public upload_file(file: File): Observable<any> {
    const formdata = new FormData();
  
    formdata.append('file', file);

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/chat/upload`, formdata);
  }
}
