import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { interval, firstValueFrom, Observable, throwError } from 'rxjs';

import { HttpService } from './http.service';


interface Chat__ {
  name: string;
  message: string;
  ts: Date;
}
interface ChatRoom__ {
  chat: Array<Chat__>;
}
interface ChatRoomList__ {
  lastIdx: number,
  chatRoom: Array<ChatRoom__>
}

@Injectable({
  providedIn: 'root'
})
export class FriendService {
  public me: any = {};
  public friend: Array<any> = [];
  public friendMap: any = {};
  public follow: Array<any> = [];
  public followMap: any = {};
  public follower: Array<any> = [];
  public followerMap: any = {};
  public chatRoomList: ChatRoomList__ = {
    lastIdx: 0,
    chatRoom: [{
      chat: [{
        name: '',
        message: '',
        ts: new Date(),
      }],
    }, {
      chat: [],
    }, {
      chat: [],
    }],
  }
  
  constructor(
    private httpService: HttpService
  ) { 
    this.get_friend();
  }

  public get_friend() {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/friend`).subscribe((res: any)=>{
      this.me = res?.me;
      this.friend = res?.friend;
      this.follow = res?.follow;
      this.follower = res?.follower;

      for (let i = 0; i < this.friend.length; i++) {
        this.friendMap[this.friend[i]?.id] = this.friend[i];
      }
      for (let i = 0; i < this.follow.length; i++) {
        this.followMap[this.follow[i]?.id] = this.follow[i];
      }
      for (let i = 0; i < this.follower.length; i++) {
        this.followerMap[this.follower[i]?.id] = this.follower[i];
      }
    });
  }

  public find_friend(invite_code: string) {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/friend/${encodeURIComponent(invite_code)}`);
  }
  public add_friend(invite_code: string, message: string) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/friend/${encodeURIComponent(invite_code)}`, {message});
  }
  public delete_friend(invite_code: string) {
    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/user/friend/${encodeURIComponent(invite_code)}`, {});
  }
  public accept_friend(friend_id: string) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/friend/request/${encodeURIComponent(friend_id)}`, {});
  }
  public reject_friend(friend_id: string) {
    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/user/friend/request/${encodeURIComponent(friend_id)}`, {});
  }
  public update_afk(user: any) {
    const current_status = user?.status ? (user?.afk ? 'afk' : 'online') : 'offline'
    
    if (this.friendMap[user?.id]) {
      this.friendMap[user.id].current_status = current_status;
    }
    if (this.followMap[user?.id]) {
      this.followMap[user.id].current_status = current_status;
    }
    if (this.followerMap[user?.id]) {
      this.followerMap[user.id].current_status = current_status;
    }
  }

  public async update_profile(profile_image: any, message: string, callback: any) {
    let param = {
      image: undefined,
      message: message,
    };

    console.log('thumbnail', typeof(profile_image), profile_image);

    if (typeof(profile_image) === "object") {
      const {path} = await firstValueFrom(this.upload_file(profile_image))
      param.image = path;
    }
  
    console.log(param);

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/account/profile`, param).subscribe((res: any)=>{
      callback(res);
    });
  }
  public upload_file(file: File): Observable<any> {
    const formdata = new FormData();
  
    formdata.append('file', file);
    formdata.append('size', '200');

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/account/upload`, formdata);
  }


  public request_chat(target: any, uuid: string | undefined = undefined) {
    console.log('request_chat')
    console.log(target);
  }
  public invite_game(target: any, uuid: string | undefined = undefined) {
    console.log('request_game')
    console.log(target);
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/network/invite/${encodeURIComponent(target?.friend_id)}`, target).subscribe((res) => {
      console.log(res);
    });
  }
}
