import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from "../../dialog/confirm-dialog/confirm-dialog.component"

import { AccountService } from "../../service/account.service"

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  public search_keyword: string = '';
  public userGroups : Array<any> = [];
  public permissionsKeys : Array<any> = [];
  public permissions : any = {};
  public selectUser : any = null;
  
  constructor(
    public dialog: MatDialog,
    public accountService: AccountService,

    @Inject(LOCALE_ID) public localID: string,
  ) { 
  }

  ngOnInit(): void {
    this.get_users();
  }

  onDragDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      console.log(event);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  public get_hash(user: any){
    let str = user.name + user.email + user.description + user.permission;
    let hash = 0;
      
    for (let i = 0; i < str.length; i++) {
      hash = ((hash << 5) - hash) + str.charCodeAt(i);
      hash = hash & hash;
    }
      
    return hash;
  }
  public check_hash(user: any){
    user.unsave = user.hash != this.get_hash(user);
  }
  public get_users() {
    this.accountService.get_alluser(0, 100, formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', this.localID)).subscribe((res : any)=>{
      if(res.status) {
        let groups: Array<any> = [];
        let groupsDict: any = {};

        this.permissions = {};

        for (let i = 0; i < res.permission.length; i++) {
          this.permissions[res.permission[i].id] = res.permission[i];
        }
        for (let i = 0; i < res.data.length; i++) {
          let key = res.data[i].permission;

          res.data[i].permission_form = new FormControl(res.data[i].permission, Validators.required)
          res.data[i].hash = this.get_hash(res.data[i]);

          if (!groupsDict[key]) {
            groupsDict[key] = {
              id : key,
              permission_level : res.data[i].permission,
              name : res.data[i].permission_label,
              data : [],
            };
          }
          
          groupsDict[key].data.push(res.data[i]);
        }

        let groupsDictKeys = Object.keys(groupsDict).sort((a, b)=>{return +b - +a});
        
        for (let i = 0; i < groupsDictKeys.length; i++)
          groups.push(groupsDict[groupsDictKeys[i]]);
        
        this.userGroups = groups;
        this.permissionsKeys = res.permission;

        console.log(this.userGroups);
        console.log(this.permissionsKeys);
        console.log(this.permissions);
        
        this.match_keyword(this.search_keyword);
      }
    })
  }

  public get_user(user: any){
    this.selectUser = user;
    console.log(user);
  }
  public add_user(group: any){
    group.data.push({
      id: null,
      description: '',
      email: 'example@email.com',
      permission_form: new FormControl(group.name, Validators.required),
      permission_label: group.name,
      registered: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', this.localID),
      ts: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', this.localID),
      name: 'new user',
      permission: group.permission_level,
      unsave : true,
    })
  }
  public update_user(user: any){
    let permission = user.permission_form.value;

    if(user.id) {
      this.accountService.update_user(user.name, user.email, user.id, permission, user.description).subscribe((res)=>{
        let title = 'Warnning';
        let content = res.err;
        
        if(res.status) {
          user.hash = this.get_hash(user);
          this.check_hash(user);

          title = 'Notice';
          content = 'Successfully update the user information.';
          user.permission_form = new FormControl(user.permission, Validators.required);

          this.get_users();
        }

        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : title,
            content : content,
            button : [{ text : 'OK', color : '#333' }],
          },
        });
      })
    }
    else {
      let data = {
        width : '450px',
        data : {
          title : 'Create user',
          content : 'Please Input password',
          input : [
            {label : 'password', text : '', type : 'password'},
            {label : 'password confirm', text : '', type : 'password'},
          ],
          button : [
            {text : 'Cancel', color : '#333'},
            {text : 'Create', color : '#58bef6'}
          ],
        },
      }
      
      let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Create') {
          this.accountService.create_user(user.name, user.email, permission, user.description, data.data.input[0].text, data.data.input[1].text).subscribe((res)=>{
            let title = 'Warnning';
            let content = res.err;
            
            if(res.status) {
              user.hash = this.get_hash(user);
              this.check_hash(user);

              title = 'Notice';
              content = 'Successfully create the user.';
            }

            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : title,
                content : content,
                button : [{ text : 'OK', color : '#333' }],
              },
            });
          })
        }
      });
    }
  }

  public change_password(user: any){
    let data = {
      width : '450px',
      data : {
        title : 'Change Password',
        content : 'Please Input new password',
        input : [
          {label : 'password', text : '', type : 'password'},
          {label : 'password confirm', text : '', type : 'password'},
        ],
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Change', color : '#58bef6'}
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Change') {
        if (data.data.input[0].text == data.data.input[1].text) {
          this.accountService.change_password(data.data.input[0].text, user.id).subscribe((res)=>{
            if(res.status){
              this.dialog.open(ConfirmDialogComponent, {
                width : '450px',
                data : {
                  title : 'Notice',
                  content : 'Successfully reset the password.',
                  button : [{ text : 'OK', color : '#333' }],
                },
              });
            }
          })
        }
        else{
          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Notice',
              content : 'Password <b style="color:red !important;">Mismatch</b>.<br>Please check again.',
              button : [{ text : 'OK', color : '#333' }],
            },
          });
        }
      }
    });

  }
  public reset_token(user: any){
    this.accountService.reset_token(user.id).subscribe((res)=>{
      if(res.status){
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Notice',
            content : 'Successfully reset the token.',
            button : [
              { text : 'OK', color : '#333' }, 
            ],
          },
        });
      }
    })
  }
  
  public create_permissionGroup() {
    
  }
  public edit_permissionGroup(collection: any, prefix_tag: string) {
    collection.edit = !collection.edit;
    collection.edit_name = collection.name;

    setTimeout(function(){
      document.getElementById(`${prefix_tag}${collection.id}`)?.focus();
    }, 50);
  }
  public edit_permissionGroupName(collection : any, complete: boolean) {
    collection.edit = false;

    if (complete){
      collection.name = collection.edit_name;

      this.accountService.update_permissionLabel(collection.id, collection.name).subscribe((res : any)=>{
        if(res.status){
          this.get_users();
        }
      });
    }
  }
  public delete_permissionGroup(collection: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Warning',
        content : 'Are you sure you want to delete this permission?<br>The child user of this item is moved to "inValid" user.',
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Delete', color : '#F00'}
        ],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Delete') {
        this.accountService.delete_permission(collection.id).subscribe(res=>{
   
        })
      }
    });
  }
  public delete_user(user: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Warning',
        content : 'Are you sure you want to delete this user?',
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Delete', color : '#F00'}
        ],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Delete') {
        this.accountService.delete_user(user.id).subscribe(res=>{
          if(res.status){
            this.get_users(); 
            this.selectUser = null;
          }
        });
      }
    });
  }

  public match_keyword(keyword: string = ''){
    this.search_keyword = keyword;

    for (let i = 0; i < this.userGroups.length; i++) {
      for (let j = 0; j < this.userGroups[i].data.length; j++) {
        let search_target = this.userGroups[i].data[j].name + this.userGroups[i].data[j].email;
        this.userGroups[i].data[j].hidden = search_target.search(keyword) == -1;
      }
    }    

  }
  public dummy_click(a:any=null,b:any=null,c:any=null,d:any=null,e:any=null){
    console.log('dummy click');
  }
}
