<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  
  <div *ngIf="data.friend">
    <div class="my-profile" *ngFor="let friend of data.friend">
      <img class="my-profile-image" [src]="friend.profile">
      <span class="my-profile-body">
        <div class="my-profile-name">{{friend.name}}</div>
        <div class="my-profile-message">{{friend.message}}</div>
      </span>
      <br>
    </div>
  </div>
  
  <p [innerHTML]="data.content"></p>
  <img [src]="data.image" style="width: 100%; height : auto; max-height: 100%;"/>
  
  <div *ngIf="data.input">
    <mat-form-field *ngFor="let input of data.input" style="width:100%;">
      <mat-label>{{input.label}}</mat-label>
      <input matInput type="{{input.type}}" placeholder="{{input.placeholder}}" [(ngModel)]="input.text">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text" (click)="updateClosedReason(button.text)">{{button.text}}</button>
    <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
  </div>
</div>