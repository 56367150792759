import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

import { PlayService } from "../../service/play.service";
import { StatusService } from 'src/app/service/status.service';
import { SettingService } from 'src/app/service/setting.service';
import { UtilsService } from 'src/app/service/utils.service';
import { GameManagerService } from 'src/app/service/game-manager.service';

@Component({
  selector: 'app-music-result',
  templateUrl: './music-result.component.html',
  styleUrls: ['../music-choice/music-choice.component.scss', './music-result.component.scss']
})
export class MusicResultComponent implements OnInit {
  public result: any = {};
  public audioURL: any = null;
  public volume: number = 0.5;
  public mid: string = '';
  public tid: string = '';
  public currentMusic:any = {};

  @ViewChild('visualizationCanvas') visualizationCanvas: ElementRef = {} as ElementRef;
  @ViewChild('audio') audioSelector: ElementRef = {} as ElementRef;
  
  private visualizationContext: CanvasRenderingContext2D = {} as CanvasRenderingContext2D;
  private visualizerHandle: any = undefined;

  constructor(
    private zone: NgZone,
    private router: Router, 
    private route: ActivatedRoute,
    private location: LocationStrategy,
    public status: StatusService,
    public utils: UtilsService,
    public playService: PlayService,
    public setting: SettingService,
    public gms: GameManagerService,
  ) {
    let key = route.snapshot.params['key'];
    this.mid = route.snapshot.params['mid'];
    this.tid = utils.lastCategory;

    history.pushState(null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, window.location.href);
    });


    this.status.sidebar = false;
    this.result = this.playService.get_result(key);
    this.audioURL = `https://soulbeat.siya.co.kr/static/system/${this.result.rank !='F' ? 'success' : 'fail'}.mp3`; 

    console.log(this.result);

    this.volume = Math.min(Math.max(this.setting.setting.volume, 0) * 1.5, 1);
    this.result.rankClass = this.result.rank.replace('+', 'p').replace('-', 'm');
    this.result.score = (+this.result.score)?.toFixed(0);
    this.result.point = (+this.result.point)?.toFixed(0);

    this.result.combo.perfect_effect = Array.from(String(this.result.combo.perfect), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.combo.excellent_effect = Array.from(String(this.result.combo.excellent), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.combo.good_effect = Array.from(String(this.result.combo.good), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.combo.bad_effect = Array.from(String(this.result.combo.bad), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.combo.miss_effect = Array.from(String(this.result.combo.miss), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.combo.total_effect = Array.from(String(this.result.combo.total), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.combo.maxCombo_effect = Array.from(String(this.result.combo.maxCombo), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.score_effect = Array.from(String(this.result.score), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);
    this.result.point_effect = Array.from(String(this.result.point), num => [`r${Math.floor(Math.random() * 10)}`, `rolling-${num}`]);

    this.playService.get_metadata(this.mid).subscribe((res)=>{
      if(res.status){
        this.currentMusic = res.data;
      }
    })
  }

  ngOnInit(): void {
  }

  public onCanPlay() {
    this.init_visualization();
  }

  public init_visualization() {
    if (!this.audioSelector?.nativeElement) {
      setTimeout(this.init_visualization, 100);
    }
    else {
      const audioContext = new AudioContext();
        
      const audioSourceNode = audioContext.createMediaElementSource(this.audioSelector.nativeElement);
      const audioAnalyser = audioContext.createAnalyser();
      
      audioAnalyser.fftSize = 512;
      audioSourceNode.connect(audioAnalyser);
      audioSourceNode.connect(audioContext.destination);
      
      let frequencyData = new Uint8Array(audioAnalyser.frequencyBinCount);
      let barSize = 2;
      let barMaxHeight = 70;
      let barHeightRatio = 255 / barMaxHeight;

      this.visualizationContext = this.visualizationCanvas.nativeElement.getContext('2d');
      this.visualizationContext.canvas.width = frequencyData.length * barSize;
      this.visualizationContext.canvas.height = barMaxHeight * 2 + 10;
    
      const renderFrame = () => {
        audioAnalyser.getByteFrequencyData(frequencyData);
        this.visualizationContext.clearRect(0, 0, this.visualizationContext.canvas.width, this.visualizationContext.canvas.height);
        
        this.visualizationContext.fillStyle = '#FFFFFF';
        this.visualizationContext.fillRect(0, this.visualizationContext.canvas.height / 2 - 1, this.visualizationContext.canvas.width * 0.7, 1);

        for (let i = 0; i < frequencyData.length; i++) {
          let height = frequencyData[i] / barHeightRatio;
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 - height, barSize, height);
        }
        
        this.visualizationContext.fillStyle = '#FFFFFF66';
        
        for (let i = 0; i < frequencyData.length; i++) {
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 + 5, barSize, frequencyData[i] / barHeightRatio);
        }

        this.visualizerHandle = requestAnimationFrame(renderFrame);  
      }

      renderFrame();
    }
  }
  public back() {
    if(this.tid?.length)
      this.router.navigate([`/choice/${this.tid}/${this.mid}`]);
    else
      this.router.navigate([`/choice/0/${this.mid}`]);
  }
  public onLeave(event: any) {
    setTimeout(()=>this.back(), 0);
  }
}
