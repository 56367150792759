<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content (window:keyup)="onKeyup($event)">
  <div>
    <div class="comment-wrapper" *ngFor="let comment of data.comments">
      <img class="comment-profile-image" [src]="comment.profile"/>
      <div class="comment">
        <div class="comment-name">{{comment.name}}</div>
        <div class="comment-text">{{comment.text}}</div>
        <div class="comment-btn">
          <span [ngClass]="{'active': comment.my_score > 0}">
            <i class="fa fa-thumbs-up" (click)="vote_comment(comment, comment.cid, 1)"></i>
            {{comment.like}}
          </span>
          <span [ngClass]="{'active': comment.my_score < 0}">
            <i class="fa fa-thumbs-down" (click)="vote_comment(comment, comment.cid, -1)"></i>
            {{comment.dislike}}
          </span>
          <span><i class="fa fa-comment" (click)="open_writeReplyForm(comment, comment.name)"></i></span>
          <span *ngIf="comment.owner">
            <!-- <span><i class="fa fa-pencil" (click)="open_writeReplyForm(comment, comment.name)"></i></span> -->
            <span><i class="fa fa-close" (click)="delete_comment(comment.cid)"></i></span>
          </span>
        </div>
        <div class="comment-reply" *ngIf="comment.show_replys">
          <div class="comment-reply-btn" (click)="comment.show_replys = false">댓글 접기</div>
          <div class="comment-wrapper" *ngFor="let reply of comment.replys">
            <img class="comment-profile-image" [src]="reply.profile"/>
            <div class="comment">
              <div class="comment-name">{{reply.name}}</div>
              <div class="comment-text">{{reply.text}}</div>
              <div class="comment-btn">
                <span [ngClass]="{'active': reply.my_score > 0}">
                  <i class="fa fa-thumbs-up" (click)="vote_comment(reply, reply.cid, 1)"></i>
                  {{reply.like}}
                </span>
                <span [ngClass]="{'active': reply.my_score < 0}">
                  <i class="fa fa-thumbs-down" (click)="vote_comment(reply, reply.cid, -1)"></i>
                  {{reply.dislike}}
                </span>
                <span><i class="fa fa-comment" (click)="open_writeReplyForm(comment, reply.name)"></i></span>
                <span *ngIf="reply.owner">
                  <!-- <span><i class="fa fa-pencil" (click)="open_writeReplyForm(comment, reply.name)"></i></span> -->
                  <span><i class="fa fa-close" (click)="delete_reply(comment, reply.cid)"></i></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="comment-reply-btn" (click)="load_reply(comment)" *ngIf="!comment.show_replys && comment.reply">{{comment.reply}}개의 댓글 펼치기</div>

        <div class="comment-wrapper" *ngIf="comment.replyInput">
          <input class="input-form" placeholder="Wrtie your comment" [(ngModel)]="comment.replyInput.text"  (keydown.enter)="write_reply($event, comment)">
          <button mat-button class="primary-btn" (click)="write_reply($event, comment)">Write</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="footer">
    <div class="comment-wrapper">
      <img class="comment-profile-image" [src]="friend?.me?.profile"/>
      <div class="comment">
        <mat-form-field style="width:100%;">
          <mat-label>Comment</mat-label>
          <input matInput placeholder="Wrtie your comment" [(ngModel)]="data.input.text"  (keydown.enter)="write_comment($event)">
        </mat-form-field>
      </div>
    </div>
    <div class="buttons">
      <button mat-button class="primary-btn" (click)="write_comment($event)">Write</button>
      <button mat-button class="secondary-btn" [mat-dialog-close]="'Close'">Close</button>
      <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
    </div>
  </div>
</div>