import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  constructor(
    private httpService: HttpService
  ) { 

  }

  public set_favorite(mid: string) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/favorite/${mid}`, {});
  }
  public unset_favorite(mid: string) {
    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/ingame/favorite/${mid}`);
  }
}
