import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { GameManagerService } from 'src/app/service/game-manager.service';
import { FriendService } from 'src/app/service/friend.service';
import { StatusService } from 'src/app/service/status.service';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-play-room-dialog',
  templateUrl: './play-room-dialog.component.html',
  styleUrls: ['./play-room-dialog.component.scss', '../friend-dialog/friend-dialog.component.scss']
})
export class PlayRoomDialogComponent {
  public friendCode = '';
  public progress: number = 0;
  public volume: number = 0;
  public requestUpload: boolean = false;
  public profileChanged: boolean = false;
  public profile = {
    name: '',
    message: '',
  }
  public files: any = {
    profile_image: undefined,
  };

  @ViewChild('visualizationCanvas') visualizationCanvas: ElementRef = {} as ElementRef;
  @ViewChild('audio') audioSelector: ElementRef = {} as ElementRef;

  private visualizationContext: CanvasRenderingContext2D = {} as CanvasRenderingContext2D;
  private visualizerHandle: any = undefined;

  constructor(
    public dialogRef: MatDialogRef<PlayRoomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public friend: FriendService,
    public gms: GameManagerService,
    private status: StatusService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
  ) { 
    this.status.isOpenDialog = true;
    this.files.profile_image_url = this.friend?.me?.profile;
    this.profile.name = this.friend?.me?.name;
    this.profile.message = this.friend?.me?.message;

    this.volume = this.gms.setting?.setting?.volume || 0.5;
    this.gms.setting.setting.volume = 0;

    console.log(data);
  }

  saveConfirm(): void {
    const profile_image = this.files?.profile_image;
    const profile_image_url = this.files?.profile_image_url;
    const name = this.profile?.name;
    const message = this.profile?.message;

    console.log(profile_image_url);
    console.log(this.friend.me.message);
    console.log(this.profile.message);

    if (this.profileChanged || this.friend.me.message != this.profile.message) {
      let data = {
        width: '450px',
        data : {
          title: 'Notice',
          friend: [
            { 
              profile: profile_image_url,
              name,
              message,
            }
          ],
          content: `Do you want to update this profile?`,
          button : [
            {text : 'Update', color : 'purple'},
            {text : 'Cancel', color : '#333'},
          ],
        },
      }
      let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Update') {
          this.friend.update_profile(profile_image, message, (res: any) => {
            if (res?.status) {
             let data = {
                width: '450px',
                data : {
                  title: 'Notice',
                  content: `Profile information has been updated.`,
                  button : [
                    {text : 'OK', color : '#333'},
                  ],
                },
              }
              let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
              this.friend.get_friend();
            }
          });
        }
      }); 
    }
  }
  ngOnDestroy(): void {
    this.gms.setting.setting.volume = this.volume || 0.5;
    this.saveConfirm();
    this.status.isOpenDialog = false;
  }

  close(): void {
    this.saveConfirm();
    this.status.isOpenDialog = false;
    this.dialogRef.close();
  }
  public onInput(event: any){
    return event.preventDefault();
  }

  public onFileDropped($event: any, key: string) {
    this.prepareFilesList($event, key);
  }
  public fileBrowseHandler(files: any, key: string) {
    this.prepareFilesList(files?.target?.files, key);
  }
  public deleteFile(key: string) {
    this.files[key] = undefined;
    this.files[`${key}_url`] = undefined;
    this.profileChanged = true;
  }
  public prepareFilesList(files: Array<any>, key: string) {
    for (const item of files) {
      let url = URL.createObjectURL(item);

      this.progress = 0;
      this.files[key] = item;
      this.files[`${key}_url`] = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.profileChanged = true;
    }
  }

  public add_friend() {
    const friendCode = this.friendCode;

    this.friend.find_friend(friendCode).subscribe((res)=> {
      console.log(res);
      this.friendCode = '';

      if (res?.status) {
        let data = {
          width: '450px',
          data : {
            title: 'Friend',
            content: `
              <div class="my-profile">
                <img class="my-profile-image" src="${res?.profile}">
                <span class="my-profile-body">
                  <div class="my-profile-name">${res?.name}</div>
                  <div class="my-profile-message">${res?.message}</div>
                </span>
              </div>
              <br>
              Do you want to add this user as a friend?
            `,
            input: [
              {label : 'Introduce message', text : '', type : 'text'},
            ],
            button : [
              {text : 'Add', color : 'purple'},
              {text : 'Cancel', color : '#333'},
            ],
          },
        }
        let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Add') {
            this.friend.add_friend(friendCode, data.data?.input[0]?.text).subscribe((res)=> {
              console.log(res);

              if (res?.status) {
               let data = {
                  width: '450px',
                  data : {
                    title: 'Friend',
                    content: 'A friend request has been sent.',
                    button : [
                      {text : 'OK', color : '#333'},
                    ],
                  },
                }
                let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
              }
            });
          }
        });
      }
      else {
        let data = {
          width: '450px',
          data : {
            title: 'Friend',
            content: res?.message,
            button : [
              {text : 'OK', color : '#333'},
            ],
          },
        }
        let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
        this.friend.get_friend();
      }
    });
  }

  public delete_friend(target: any) {
    let data = {
      width: '450px',
      data : {
        title: 'Friend',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${target?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${target?.name}</div>
              <div class="my-profile-message">${target?.message}</div>
            </span>
          </div>
          <br>
          Do you want to delete this friend?
        `,
        button : [
          {text : 'Delete', color : 'red'},
          {text : 'Cancel', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Delete') {
        this.friend.delete_friend(target?.friend_id).subscribe((res)=> {
          if (res?.status) {
           let data = {
              width: '450px',
              data : {
                title: 'Friend',
                content: `A friend request has been deleted.`,
                button : [
                  {text : 'OK', color : '#333'},
                ],
              },
            }
            let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
            this.friend.get_friend();
          }
        });
      }
    });
  }
  public accept_friendRequest(target: any) {
    let data = {
      width: '450px',
      data : {
        title: 'Friend',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${target?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${target?.name}</div>
              <div class="my-profile-message">${target?.message}</div>
            </span>
          </div>
          <br>
          Do you want to accept this request?
        `,
        button : [
          {text : 'Accept', color : 'purple'},
          {text : 'Cancel', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Accept') {
        this.friend.accept_friend(target?.friend_id).subscribe((res)=> {
          if (res?.status) {
           let data = {
              width: '450px',
              data : {
                title: 'Friend',
                content: `A friend request has been accept.`,
                button : [
                  {text : 'OK', color : '#333'},
                ],
              },
            }
            let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
            this.friend.get_friend();
          }
        });
      }
    });
  }
  public reject_friendRequest(target: any, isFollower: boolean) {
    let data = {
      width: '450px',
      data : {
        title: 'Friend',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${target?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${target?.name}</div>
              <div class="my-profile-message">${target?.message}</div>
            </span>
          </div>
          <br>
          Do you want to ${isFollower ? 'reject': 'delete'} this request?
        `,
        button : [
          {text : isFollower ? 'Reject' : 'Delete', color : 'red'},
          {text : 'Cancel', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Reject' || result == 'Delete') {
        this.friend.reject_friend(target?.friend_id).subscribe((res)=> {
          if (res?.status) {
           let data = {
              width: '450px',
              data : {
                title: 'Friend',
                content: `A friend request has been ${isFollower ? 'rejected': 'deleted'}.`,
                button : [
                  {text : 'OK', color : '#333'},
                ],
              },
            }
            let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
            this.friend.get_friend();
          }
        });
      }
    });
  }

  public info_friendDialog(target: any) {
    let data = {
      width: '450px',
      data : {
        title: 'Friend',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${target?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${target?.name}</div>
              <div class="my-profile-message">${target?.message}</div>
            </span>
          </div>
        `,
        button : [
          {text : 'OK', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
  }


  public friendContextMenu: any = {
    show: false,
    x: 0,
    y: 0,
    title: undefined,
    content: undefined,
    option: [{
      label: 'Show detail',
      key: 'friend_info',
      color: '#333',
      hover: {
        color: 'purple',
      }
    }, {
      label: 'Send note',
      key: 'friend_note',
      color: '#333',
      hover: {
        color: 'purple',
      }
    }, {
      label: 'Start chat',
      key: 'request_chat',
      color: '#333',
      hover: {
        color: 'purple',
      }
    }, {
      label: 'Play Game',
      key: 'request_game',
      color: '#333',
      hover: {
        color: 'purple',
      }
    }, {
      label: 'Delete',
      key: 'delete_friend',
      color: '#333',
      hover: {
        color: 'red',
      }
    }],
    data: {},
    open: (event: MouseEvent, friend: any) => {
      event.preventDefault();
      this.friendContextMenu.show = true;
      this.friendContextMenu.x = event.clientX;
      this.friendContextMenu.y = event.clientY;
      this.friendContextMenu.data = friend;
    },
    close: () => {
      this.friendContextMenu.show = false;
    }
  }

  onMenuItemSelected(select: string) {
    const target = this.friendContextMenu.data;
    this.friendContextMenu.close();
    switch(select) {
      case 'friend_info':
        this.info_friendDialog(target);
        break;
      case 'request_chat':
        // this.friend.request_chat(target);
        this.gms.dialog_chat(target);
        break;
      case 'request_game':
        this.gms.dialog_inviteGame(target);
        break;
      case 'delete_friend': 
        this.delete_friend(target);
        break;
      default:
        console.log(select, target);
        break;
    }
  }


  public onCanPlay() {
    this.init_visualization();
  }

  public init_visualization() {
    if (this.audioSelector?.nativeElement == undefined) {
      setTimeout(() => this.init_visualization(), 100);
    }
    else if(this.visualizerHandle == undefined) {
      const audioContext = new AudioContext();
        
      const audioSourceNode = audioContext.createMediaElementSource(this.audioSelector.nativeElement);
      const audioAnalyser = audioContext.createAnalyser();
      
      audioAnalyser.fftSize = 512;
      audioSourceNode.connect(audioAnalyser);
      audioSourceNode.connect(audioContext.destination);
      
      let frequencyData = new Uint8Array(audioAnalyser.frequencyBinCount);
      let barSize = 1.5;
      let barMaxHeight = 55;
      let barHeightRatio = 255 / barMaxHeight;

      this.visualizationContext = this.visualizationCanvas.nativeElement.getContext('2d');
      this.visualizationContext.canvas.width = frequencyData.length * barSize;
      this.visualizationContext.canvas.height = barMaxHeight * 2 + 10;

    
      const renderFrame = () => {
        audioAnalyser.getByteFrequencyData(frequencyData);
        this.visualizationContext.clearRect(0, 0, this.visualizationContext.canvas.width, this.visualizationContext.canvas.height);
        
        this.visualizationContext.fillStyle = '#FFFFFF';
        this.visualizationContext.fillRect(0, this.visualizationContext.canvas.height / 2 - 1, this.visualizationContext.canvas.width * 0.7, 1);

        for (let i = 0; i < frequencyData.length; i++) {
          let height = frequencyData[i] / barHeightRatio;
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 - height, barSize, height);
        }
        
        this.visualizationContext.fillStyle = '#FFFFFF66';
        
        for (let i = 0; i < frequencyData.length; i++) {
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 + 5, barSize, frequencyData[i] / barHeightRatio);
        }

        this.visualizerHandle = requestAnimationFrame(renderFrame);  
      }

      renderFrame();
    }
  }
}
