<div class="menu-wrapper">
  <div class="cover-image" [style.--background]="setting.stage.image_url" [style.--filter]="setting.stage.cover_filter">
    <div class="profile">
      <div class="profile-image-wrapper">
        <div class="soulbeat-icon-rect">
          <div class="profile-image" [style.--background]="accountService?.user?.profile_url" (click)="gms.dialog_account()"></div>
        </div>
      </div>
      <div class="profile-info">
        <canvas #visualizationCanvas class="visualizer"></canvas>
        <div class="name">
          <b class="level">Lv.{{accountService.user.permission}}</b>
          {{accountService?.user?.name}}
        </div>
        <div class="point">
          <span class="sb-point dark text-shadow" (click)="gms.dialog_pointlog()">{{gms.shop.point}}<i class="fa fa-star"></i></span> | 
          <span class="sb-ticket dark text-shadow" (click)="gms.dialog_ticketlog()"><i class="fa fa-credit-card-alt"></i>{{gms.shop.ticket}}</span>
        </div>
      </div>
    </div>

    <div class="right-top-wrapper">
      <div class="soulbeat-icon-wrapper">
        <div class="soulbeat-icon-rect" (click)="pause()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-alien-8bit"></i>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="pause()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-block-question"></i>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="pause()" [ngClass]="{'active': setting.stage?.music_play}">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-music" style="margin-left: -4px;"></i>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="navigate_stage()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-wand-magic-sparkles"></i>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_account()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-user-gear"></i>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_setting()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-gear"></i>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="dialog_ads()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <!-- <i class="fa-solid fa-rectangle-ad"></i> -->
              <i class="fa-solid fa-display"></i>
            </div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_logout()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-right-from-bracket"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="left-wrapper">
      <div class="soulbeat-icon-wrapper">
        <div class="soulbeat-icon-rect" (click)="navigate_shop()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-bag-shopping"></i>
            </div>
            <div class="soulbeat-icon-label">Shop</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="navigate_craft()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-hammer"></i>
            </div>
            <div class="soulbeat-icon-label">Craft</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_friend()">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-user-group"></i>
            </div>
            <div class="soulbeat-icon-label">Friend</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_chat()">
          <div class="soulbeat-icon-info">
            <div *ngIf="network.chatAlarm" class="soulbeat-icon-alarm"></div>
            <div class="soulbeat-icon-level">
              <i class="fa fa-comments"></i>
            </div>
            <div class="soulbeat-icon-label">Chat</div>
          </div>
        </div>
      </div>
    </div>

    <div class="event-banner">
      <div class="event-banner-image" [style.--background]="banner.imageUrl" (click)="click_banner(banner)"></div>
      <div class="event-banner-content-container">
        <div class="event-banner-content" [innerHTML]="banner.content" (click)="click_banner(banner)"></div>
      </div>
      <div class="event-banner-indicator left" (click)="update_banner(-1)">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="event-banner-indicator right" (click)="update_banner(1)">
        <i class="fa fa-angle-right"></i>
      </div>
    </div>

    <div class="right-bottom-wrapper">
      <div class="soulbeat-icon-wrapper">
        <div class="soulbeat-icon-rect" (click)="gms.play_music_network(null)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-plug"></i>
            </div>
          </div>
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-label">Multi Play</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="choicePlayCategoryStep = true">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level">
              <i class="fa-solid fa-guitar"></i>
              <!-- <i class="fa-solid fa-guitar-electric"></i> -->
            </div>
          </div>
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-label">Play</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <div class="category-choice" *ngIf="choicePlayCategoryStep" (scroll)="onScroll($event)">
    <!-- <div class="back-btn" (click)="choicePlayCategoryStep = false"><i class="fa fa-chevron-left"></i></div> -->
    
    <div class="select-category-wrapper" (click)="close_selectCategory()">
      <div class="flip category" (mouseenter)="onFocus($event, category)" (click)="navigate_choice(category.id)" *ngFor="let category of categoryList">
        <div class="front" [style.background]="category.cover_url">
          <h1>{{category.label}}</h1>
        </div>
        <div class="back" [style.background]="category.cover_url">
          <h1>{{category.label}}</h1>
          <p>There is {{category.music_count}} music!</p>
        </div>
      </div>
    </div>
  </div>

  <div id="mmd-wrapper" [class]="{'fade-in':loading?.complete}"></div>
</div>

<audio #audio (canplaythrough)="onCanPlay()" [muted]="mute" [volume]="gms.setting?.setting?.volume" [src]="audioSrc ?? setting.stage?.music_audio_url" loop autoplay></audio>