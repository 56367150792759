<div class="music-choice-wrapper" (window:keyup)="onKeyup($event)" (window:popstate)="onLeave($event)">
  <div class="left-side">
    <div class="music-header-wrapper">
      <span (click)="back()"><i class="fa fa-chevron-left"></i></span>
    </div>
    <div class="music-body-wrapper" (wheel)="onScroll($event)">
      <div class="music-title"><i class="fa fa-chevron-up" (click)="onKeyup({keyCode:38})"></i></div>
      <div *ngFor="let i of nearestMusicIdx">
        <div class="music-title" [ngClass]="{'active': i == currentMusicIdx}" (click)="onFocus(i)">{{musicList[i].title}} <i class="fa fa-lock" *ngIf="!musicList[i].able"></i></div>
      </div>
      <div class="music-title"><i class="fa fa-chevron-down" (click)="onKeyup({keyCode:40})"></i></div>
    </div>
    <div class="music-footer-wrapper">
      <!-- <span (click)="dialog_pointlog()"><i class="fa fa-bars"></i></span> -->
      <span (click)="gms.dialog_setting()">
        <i class="fa-solid fa-gear"></i>
      </span>
      <span (click)="gms.dialog_friend()">
        <i class="fa-solid fa-user-group"></i>
      </span>
      <span class="assets">
        <span class="sb-point dark" (click)="gms.dialog_pointlog()">{{gms.shop.point}}<i class="fa fa-star"></i></span>
        <span class="sb-ticket dark" (click)="gms.dialog_ticketlog()"><i class="fa fa-credit-card-alt"></i>{{gms.shop.ticket}}</span>
      </span>
      <!-- <span (click)="dialog_pointlog()"><i class="fa fa-sort-alpha-asc"></i></span>
      <span (click)="dialog_pointlog()"><i class="fa fa-sort-amount-asc"></i></span>
      <span (click)="dialog_pointlog()"><i class="fa fa-sort-numeric-asc"></i></span> -->

      <!-- <div class="point">
        <span class="sb-point" (click)="dialog_pointlog()">{{shopService.point}}<i class="fa fa-star"></i></span>
        <span class="sb-ticket" (click)="dialog_ticketlog()"><i class="fa fa-credit-card-alt"></i>{{shopService.ticket}}</span>
      </div> -->
    </div>
  </div>

  <div class="cover-image" [ngClass]="{'disabled': !currentMusic.able}" [style.--background]="currentMusic.cover_url" *ngIf="currentMusic.id">
    <div class="cover-image-body">
      <div class="additional-wrapper">
        <div class="soulbeat-icon-rect" (click)="gms.play_music_network(currentMusic, load_list)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.ranking}">
              <i class="fa-solid fa-plug"></i>
            </div>
            <div class="soulbeat-icon-label">Multi-Play</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_ranking(currentMusic, currentMusic.id)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.ranking}">
              <i class="fa-solid fa-user-group"></i>
            </div>
            <div class="soulbeat-icon-label">Friends</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_ranking(currentMusic, currentMusic.id)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.ranking}"><i class="fa fa-trophy"></i></div>
            <div class="soulbeat-icon-label">Ranking</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.dialog_comment(currentMusic, currentMusic.id)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.comments}"><i class="fa fa-comments"></i></div>
            <div class="soulbeat-icon-label">Comment</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.toggle_favorite(currentMusic, currentMusic.id)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.favorite}"><i class="fa fa-tag"></i></div>
            <div class="soulbeat-icon-label">Favorite</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.vote(currentMusic, currentMusic.id, 1)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.my_vote > 0}"><i class="fa fa-thumbs-up"></i></div>
            <div class="soulbeat-icon-label">{{currentMusic.like ? currentMusic.like : 'Like'}}</div>
          </div>
        </div>
        <div class="soulbeat-icon-rect" (click)="gms.vote(currentMusic, currentMusic.id, -1)">
          <div class="soulbeat-icon-info">
            <div class="soulbeat-icon-level" [ngClass]="{'active' : currentMusic.my_vote < 0}"><i class="fa fa-thumbs-down"></i></div>
            <div class="soulbeat-icon-label">{{currentMusic.dislike ? currentMusic.dislike : 'Dislike'}}</div>
          </div>
        </div>
      </div>
      
      <div class="difficult-wrapper"  *ngIf="!currentMusic.able">
        <div class="difficult-rect" (click)="gms.buy_music(currentMusic, load_list)">
          <div class="difficult-info">
            <div class="difficult-level">
              <i class="fa-solid fa-lock"></i>
            </div>
            <div class="difficult-label">Buy</div>
          </div>
        </div>
      </div>

      <div class="difficult-wrapper"  *ngIf="currentMusic.able">
        <div class="difficult-rect" (click)="gms.play_music(currentMusic, load_list)">
          <div class="difficult-info">
            <div class="difficult-level">4</div>
            <div class="difficult-label">Easy</div>
          </div>
        </div>
        <div class="difficult-rect" (click)="gms.play_music(currentMusic, load_list)">
          <div class="difficult-info">
            <div class="difficult-level">10</div>
            <div class="difficult-label">Normal</div>
          </div>
        </div>
        <div class="difficult-rect" (click)="gms.play_music(currentMusic, load_list)">
          <div class="difficult-info">
            <div class="difficult-level">14</div>
            <div class="difficult-label">Hard</div>
          </div>
        </div>
        <div class="difficult-rect" (click)="gms.play_music(currentMusic, load_list)">
          <div class="difficult-info">
            <div class="difficult-level">18</div>
            <div class="difficult-label">Crazy</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="cover-image-footer">
      <div class="thmubnail" [style.background-image]="currentMusic.thumbnail_url"></div>
      <div class="metadata">
        <div class="title">{{currentMusic.title}}</div>
        <div class="subtitle">
          <div class="artist">{{currentMusic.artist}}</div>
          <span class="tags" *ngFor="let tag of currentMusic.tags">#{{tag}}</span>
        </div>
        <div class="expire" [innerHTML]="currentMusic.expirationNotice"></div>
      </div>
      <canvas #visualizationCanvas></canvas>

      <div class="history" *ngIf="currentMusic.max_rank">
        <div class="item">
          <div class="label">Rank</div>
          <div class="value">{{currentMusic.max_rank}}</div>
        </div>
        <div class="item">
          <div class="label">Score</div>
          <div class="value">{{currentMusic.max_score}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<audio #audio (canplaythrough)="onCanPlay()" [volume]="gms.setting?.setting?.volume" [src]="currentMusic.audio_url" autoplay loop></audio>