import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AccountDialogComponent } from '../dialog/account-dialog/account-dialog.component';
import { ConfirmDialogComponent } from "../dialog/confirm-dialog/confirm-dialog.component"
import { PurchaseDialogComponent } from '../dialog/purchase-dialog/purchase-dialog.component';
import { RankingDialogComponent } from "../dialog/ranking-dialog/ranking-dialog.component"
import { CommentDialogComponent } from "../dialog/comment-dialog/comment-dialog.component"
import { FriendDialogComponent } from '../dialog/friend-dialog/friend-dialog.component';
import { DrawingDialogComponent } from "../dialog/drawing-dialog/drawing-dialog.component"
import { SettingDialogComponent } from "../dialog/setting-dialog/setting-dialog.component"
import { PointlogDialogComponent } from "../dialog/pointlog-dialog/pointlog-dialog.component"
import { TicketlogDialogComponent } from "../dialog/ticketlog-dialog/ticketlog-dialog.component"
import { DrawingFabricDialogComponent } from '../dialog/drawing-fabric-dialog/drawing-fabric-dialog.component';
import { PlayRoomDialogComponent } from '../dialog/play-room-dialog/play-room-dialog.component';

import { AccountService } from "./account.service";
import { PlayService } from './play.service';
import { ShopService } from './shop.service';
import { FriendService } from './friend.service';
import { SettingService } from 'src/app/service/setting.service';
import { FavoriteService } from './favorite.service';
import { CommunityService } from './community.service';
import { StatusService } from "./status.service";
import { ChatDialogComponent } from '../dialog/chat-dialog/chat-dialog.component';
import { AdsDialogComponent } from '../dialog/ads-dialog/ads-dialog.component';
import { StageSettingDialogComponent } from '../dialog/stage-setting-dialog/stage-setting-dialog.component';
import { CraftMusicListDialogComponent } from '../dialog/craft-music-dialog/craft-music-list-dialog.component';
import { ShopDialogComponent } from '../dialog/shop-dialog/shop-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class GameManagerService {
  constructor(
    public dialog: MatDialog,
    public account : AccountService,    
    private router: Router, 
    private route: ActivatedRoute,
    public play : PlayService,
    public shop : ShopService,
    public friend: FriendService,
    public setting: SettingService,
    public favorite: FavoriteService,
    public community: CommunityService,
    public status : StatusService,
  ) { 
  }

  public onLoad(event: any) {
    console.log(event);
  }

  public refresh() {
    this.shop.load_refresh();
    this.setting.get_setting();
    this.setting.get_stage();
    this.friend.get_friend();
  }

  public play_music(item: any, successCallback: any = undefined) {
    if (item?.id && item?.able) {
      this.router.navigate([`/play/${item.id}`], { relativeTo: this.route });
    }
    else {
      this.buy_music(item, successCallback);
    }
  }
  public play_music_network(item: any, successCallback: any = undefined) {
    let data = Object.assign({}, item);

    if (item?.id && item?.able) {
      let dialogRef = this.dialog.open(PlayRoomDialogComponent, {
        width: '80vw',
        data: data
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Play') {
          let playDialogRef = this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Notice',
              content : 'Are you sure play now?',
              button : [
                { text : 'Close', color : '#333' },
                { text : 'Play', color : 'purple' }
              ],
            },
          });

          playDialogRef.afterClosed().subscribe(res => {
            if (successCallback) {
              successCallback();
            }
            if (res == 'Play') {
              this.router.navigate([`/play-network/${item.id}`], { relativeTo: this.route });
            }
          });      
        }
      });
    }
    else {
      this.buy_music(item, successCallback);
    }
  }
  public buy_music = (item: any, successCallback: any = undefined) => {
    let mid: string = item?.id;

    let data = {
      width : '450px',
      data : {
        title : 'Purchase',
        content : `
          Do you want to buy ${item.title}?<br>
          You can play only <span class="sb-point">${item.price}<i class="fa fa-star"></i>.</span><br>
          Also, There are many other purchase options.
        `,
        price_options: item.dlc_price_option,
        item: item,
        // audio: this.currentMusic.audio_url.split('#')[0],
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Buy', color : 'purple'}
        ],
      },
    }

    if (item.soldout){
      data.data.content = `
        Sorry! ${item.title} is <b>SOLD OUT!</b><br>
        Please check it again next time.
      `
      data.data.button = [
        {text : 'Cancel', color : '#333'}
      ];
    } else if (item.price == 0) {
      data.data.title = 'Free to Play!'
      data.data.content = `
        Do you want to buy ${item.title}?<br>
        You can purchase it for free!! just <span class="sb-point">0<i class="fa fa-star">!</i></span><br>
      `
    }


    let dialogRef = this.dialog.open(PurchaseDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Buy') {
        let title = 'Error', msg = 'Unknown error';

        this.shop.buy_music(mid, item.select_price_option).subscribe((res: any)=>{
          if (res.status) {
            let playDialogRef = this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Notice',
                content : 'Thank you for purchasing it.<br>Do you want Play now?',
                button : [
                  { text : 'Close', color : '#333' },
                  { text : 'Play Now', color : 'purple' }
                ],
              },
            });

            playDialogRef.afterClosed().subscribe(res => {
              if (successCallback) {
                successCallback();
              }
              if (res == 'Play Now') {
                this.play_music({id: mid, able: true});
              }
            });
          }
          else {
            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Warning',
                content : res.msg,
                button : [
                  { text : 'Close', color : '#333' }
                ],
              },
            });
          }
        }, (fail: any) => {
          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : title,
              content : fail.msg,
              button : [{ text : 'OK', color : '#333' }],
            },
          });
        });
      }
    });
  }
  
  public toggle_favorite(data: any, mid: string) {
    if(data.favorite) {
      this.favorite.unset_favorite(mid).subscribe(res=>{
        data.favorite = 0;
      })
    }
    else {
      this.favorite.set_favorite(mid).subscribe(res=>{
        data.favorite = 1;
      })
    }
  }

  public vote(data: any, mid: string, score: number) {
    this.community.vote_music(mid, score).subscribe((res)=>{
      if(res.status) {
        data.like = +res.like;
        data.dislike = +res.dislike;
        data.my_vote = +res.score;
      }
    })
  }


  public dialog_ads(callback: any = undefined) {
    this.shop.get_ads().subscribe((res) => {
      if (res.status) {
        const {url, duration, point, secret} = res;
        
        let data = {
          width : '95vw',
          panelClass: 'dialog-padding-0',
          disableClose: true,
          data : {url, duration, point},
        }
        let dialogRef = this.dialog.open(AdsDialogComponent, data);
    
        dialogRef.afterClosed().subscribe(result => {
          if (callback) {
            callback();
          }
          
          this.shop.watch_ads(secret).subscribe((res) => {
            this.shop.get_point();
            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Notice',
                content : `Thanks for watching! You got <span class="sb-point">${point}<i class="fa fa-star"></i></span>!`,
                button : [{ text : 'OK', color : '#333' }],
              },
            });
          }, (error) => {
            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Warning',
                content : `Don't cheat, just watch the ad until the end.`,
                button : [{ text : 'OK', color : '#333' }],
              },
            });
          });
        });
      }
    })
  }

  public dialog_notice(content: string, button: Array<any> | undefined = undefined) {
    let data = {
      width: '450px',
      data : {
        title: 'Notice',
        content,
        button : button || [
          {text : 'OK', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
  }
  public dialog_ranking(music: any, mid: string) {
    let data = {
      width: '450px',
      data : {
        title : 'Ranking',
        mid,
      },
    }
    let dialogRef = this.dialog.open(RankingDialogComponent, data);
  }
  public dialog_comment(music: any, mid: string) {
    let data = {
      width: '450px',
      data : {
        title : 'Comment',
        mid,
      },
    }
    let dialogRef = this.dialog.open(CommentDialogComponent, data);
  }
  public dialog_pointlog() {
    let data = {
      width : '450px',
      data : {
        title : 'Point Log',
        content : ``,
        button : [
          {text : 'Close', color : '#333'}
        ],
      },
    }
    let dialogRef = this.dialog.open(PointlogDialogComponent, data);
  }
  public dialog_ticketlog() {
    let data = {
      width : '450px',
      data : {
        title : 'Ticket Log',
        content : ``,
        button : [
          {text : 'Close', color : '#333'}
        ],
      },
    }
    let dialogRef = this.dialog.open(TicketlogDialogComponent, data);
  }
  public dialog_accountSetting() {
    let data = {
      width : '450px',
      data : {
        title : 'Change Password',
        content : 'Please Input new password',
        input : [
          {label : 'current password', text : '', type : 'password'},
          {label : 'new password', text : '', type : 'password'},
          {label : 'new password confirm', text : '', type : 'password'},
        ],
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Change', color : '#58bef6'}
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Change') {
        if (data.data.input[1].text == data.data.input[2].text) {
          this.account.change_password(data.data.input[1].text, undefined, data.data.input[0].text).subscribe((res)=>{
            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Notice',
                content : res.status ? 'Successfully reset the password.' : '<b style="color:red !important;">Fail to reset the password.</b>',
                button : [{ text : 'OK', color : '#333' }],
              },
            });
          })
        }
        else{
          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Notice',
              content : 'Password <b style="color:red !important;">Mismatch</b>.<br>Please check again.',
              button : [{ text : 'OK', color : '#333' }],
            },
          });
        }
      }
    });
  }
  public resetToken() {
    this.account.reset_token(undefined).subscribe((res)=>{
      if(res.status){
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Notice',
            content : 'Successfully reset the token.',
            button : [
              { text : 'OK', color : '#333' }, 
            ],
          },
        });
      }
    })
  }
  public dialog_setting() {
    let data = {
      width : '480px',
      data : {
        title : 'Setting',
        content : ``,
        setting: this.setting.setting,
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Save', color : 'purple'}
        ],
      },
    }
    let dialogRef = this.dialog.open(SettingDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Save') {
        this.setting.set_setting(data.data.setting);
      }
    });
  }

  public dialog_stage(callback: Function | undefined) {
    let dialogRef = this.dialog.open(StageSettingDialogComponent, {
      width : '80vw',
      height: '80vh',
      panelClass: 'dialog-padding-0',
      data : {
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Save', color : 'purple'}
        ],
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback();
      }
    })
  }

  public dialog_shop(callback: Function | undefined) {
    let dialogRef = this.dialog.open(ShopDialogComponent, {
      width : '100vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: ['dialog-transparent', 'dialog-height-100vh'],
      data : {
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Save', color : 'purple'}
        ],
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback();
      }
    })
  }

  public dialog_craft(callback: Function | undefined) {
    let dialogRef = this.dialog.open(CraftMusicListDialogComponent, {
      width : '100vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: ['dialog-transparent', 'dialog-height-100vh'],
      data : {
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Save', color : 'purple'}
        ],
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback();
      }
    })
  }
  
  public dialog_account() {
    let dialogRef = this.dialog.open(AccountDialogComponent, {
      width: '480px',
      height: '70vh',
      data: {
        setting: this.setting.setting,
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Save', color : 'purple'}
        ],
      }
    });
  }
  public dialog_friend() {
    let dialogRef = this.dialog.open(FriendDialogComponent);
  }
  public dialog_requestFriend(request: any) {
    let data = {
      width: '450px',
      data : {
        title: 'Friend',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${request?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${request?.name}</div>
              <div class="my-profile-message">${request?.message}</div>
            </span>
          </div>
          <pre>${request?.invite_msg}</pre>
          <br>
          Do you want to accept this request?
        `,
        button : [
          {text : 'Accept', color : 'purple'},
          {text : 'Cancel', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Accept') {
        this.friend.accept_friend(request?.friend_id).subscribe((res)=> {
          if (res?.status) {
           let data = {
              width: '450px',
              data : {
                title: 'Friend',
                content: `A friend request has been accept.`,
                button : [
                  {text : 'OK', color : '#333'},
                ],
              },
            }
            let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
            this.friend.get_friend();
          }
        });
      }
    });
  }
  public dialog_chat(target: any = {}) {
    let dialogRef = this.dialog.open(ChatDialogComponent, {
      width: '820px',
      height: '70vh',
      panelClass: 'dialog-padding-0',
      position: { bottom: '0px', right: '0px' },
      data: {
        target: target,
        setting: this.setting.setting,
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Save', color : 'purple'}
        ],
      }
    });
  }
  public dialog_requestChat(request: any) {
    let data = {
      width: '450px',
      data : {
        title: 'Chat',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${request?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${request?.name}</div>
              <div class="my-profile-message">${request?.message}</div>
            </span>
          </div>
          <pre>${request?.request_message}</pre>
          <br>
          Do you want to accept this request?
        `,
        button : [
          {text : 'Accept', color : 'purple'},
          {text : 'Cancel', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Accept') {
        console.log('accept chat!');
      }
    });
  }
  public dialog_inviteGame(request: any) {
    let data = {
      width: '450px',
      data : {
        title: 'Invite',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${request?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${request?.name}</div>
              <div class="my-profile-message">${request?.message}</div>
            </span>
          </div>
          <br>
          Do you want to play games with this friend?<br>
          Please write an invitation message!
        `,
        input: [{
          label: 'Invite message',
          placeholder: 'You can write an invitation message here.',
          type: 'text',
          text: '',
        }],
        button : [
          {text : 'Invite', color : 'purple'},
          {text : 'Cancel', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Invite') {
        request.request_message = data.data.input[0].text;
        this.friend.invite_game(request);
      }
    });
  }

  public dialog_invitedGame(request: any) {
    let data = {
      width: '450px',
      data : {
        title: 'Invite',
        content: `
          <div class="my-profile">
            <img class="my-profile-image" src="${request?.profile}">
            <span class="my-profile-body">
              <div class="my-profile-name">${request?.name}</div>
              <div class="my-profile-message">${request?.message}</div>
            </span>
          </div>
          <pre>${request?.request_message}</pre>
          <br>
          Do you want to accept this request?
        `,
        button : [
          {text : 'Play', color : 'purple'},
          {text : 'Cancel', color : '#333'},
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Play') {
        console.log('accept play!');
      }
    });
  }


  public dialog_drawing(callback: any) {
    let res = {
      data: {
        file: undefined,
      },
    };
    let dialogRef = this.dialog.open(DrawingDialogComponent, res);

    dialogRef.afterClosed().subscribe(result => {
      if (callback && res?.data.file && typeof(res?.data.file) == 'object') {
        callback([res?.data.file]);
      }
    });
  }
  public dialog_drawing_fabric(callback: any) {
    let res = {
      data: {
        file: undefined,
      },
    };
    let dialogRef = this.dialog.open(DrawingFabricDialogComponent, res);

    dialogRef.afterClosed().subscribe(result => {
      if (callback && res?.data.file && typeof(res?.data.file) == 'object') {
        callback([res?.data.file]);
      }
    });
  }

  public dialog_logout() {
    let data = {
      width : '450px',
      data : {
        title : 'Warning',
        content : `Do you want to logout?`,
        button : [
          {text : 'Cancel', color : '#333'},
          {text : 'Logout', color : 'purple'}
        ],
      },
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Logout') {
        this.account.signout().subscribe((res)=>{
          if(res.status) { 
            location.href = '/'
            this.account.session = null;
            this.status.sidebar = false;
          }
        })
      }
    });
  }
}
