<div class="result-flexbox">
    <div class="result-wrapper" *ngIf="result.score != undefined">
        <div class="title"># Result</div>        
        <div class="point">
            <span>You got <span class="sb-point">{{result.point}}<i class="fa fa-star">.</i></span></span>
        </div>

        <span class="result-container">
            <span class="result-container-left">
                <div>
                    <span class="label">Perfect</span>
                    <span class="Sp">{{result.combo.perfect}}</span>
                </div>
                <div>
                    <span class="label">Excellent</span>
                    <span class="Ap">{{result.combo.excellent}}</span>
                </div>
                <div>
                    <span class="label">Good</span>
                    <span class="Bp">{{result.combo.good}}</span>
                </div>
                <div>
                    <span class="label">Bad</span>
                    <span class="Dp">{{result.combo.bad}}</span>
                </div>
                <div>
                    <span class="label">Miss</span>
                    <span class="F">{{result.combo.miss}}</span>
                </div>
                <div>
                    <span class="label">Total</span>
                    <span class="Err">{{result.combo.total}}</span>
                </div>
            </span>
            <span class="result-container-right">
                <div class="rank" [ngClass]="result.rankClass">
                    <span>{{result.rank}}</span>
                    <span class="text">rank</span>
                </div>
                <div class="score">{{result.score}}</div>                
            </span>
        </span>
        <div class="back-btn" (click)="back()">BACK</div>
    </div>
</div>
<audio *ngIf="audioURL" style="display: none;" [src]="audioURL" [volume]="volume" autoplay></audio>