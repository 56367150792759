<h1 mat-dialog-title>Friend</h1>
<div mat-dialog-content class="padding-0">
  <div class="my-profile">
    <!-- <img class="my-profile-image" [src]="friend?.me?.profile"> -->

    <div class="my-profile-image">
      <div class="drag-and-drop-container" [ngClass]="{'no-border' : files.profile_image_url}" appDragAndDrop (fileDropped)="onFileDropped($event, 'profile_image')">
        <input type="file" #fileDropRef id="fileDropRef" multiple accept='.png, .jpg, .jpeg' (change)="fileBrowseHandler($event, 'profile_image')"/>
        <div class="drag-and-drop-message" *ngIf="!files.profile_image_url">Profile</div>

        <div *ngIf="files.profile_image_url">
          <img [src]="files.profile_image_url">
          <div class="icons">
            <i class="fa fa-trash" (click)="deleteFile('profile_image')"></i>
          </div>
        </div>
      </div>
    
      <div class="my-profile-status" [ngClass]="friend?.me?.current_status"></div>
    </div>

    <span class="my-profile-body">
      <div class="my-profile-name">{{friend?.me?.name}}</div>
      <!-- <div class="my-profile-message">{{profile.message}}</div> -->
      <input class="my-profile-message" [(ngModel)]="profile.message" placeholder="Profile message">
    </span>
    <span class="my-profile-option">

    </span>
  </div>

  <div class="invite-friend-form">
    <mat-form-field class="invite-friend-input">
      <mat-label>Add Friend</mat-label>
      <input matInput type="text" placeholder="Friend Code" [(ngModel)]="friendCode">
    </mat-form-field>
    <button mat-button class="invite-friend-button" (click)="add_friend()">Add</button>
  </div>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>Friend</ng-template>
      <div class="friend-tab">
        <div class="friend-item" *ngFor="let item of friend.friend" (contextmenu)="friendContextMenu.open($event, item)" (click)="friendContextMenu.open($event, item)">
          <img class="friend-profile" [src]="item?.profile">
          <div class="friend-profile-status" [ngClass]="item?.current_status"></div>
          <span class="friend-body">
            <span class="friend-name">{{item?.name}}</span>
            <span class="friend-message">{{item?.message}}</span>
          </span>
          <div class="friend-option">
            <div class="friend-option-row">
              <!-- <i class="fa fa-times" (click)="delete_friend(item)"></i> -->
              <i class="fa fa-commenting"></i>
              <i class="fa fa-ellipsis-h"></i>
            </div>
            <div class="friend-option-row">
              <span class="friend-date" *ngIf="item?.current_status == 'offline'">{{item?.ts | relativeDate}}</span>
              <span class="friend-date" *ngIf="item?.current_status != 'offline'">접속 중</span>
            </div>
          </div>
        </div>
      </div>    
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Follower</ng-template>
      <div class="friend-tab">
        <div class="friend-item" *ngFor="let item of friend.follower">
          <img class="friend-profile" [src]="item?.profile">
          <!-- <div class="friend-profile-status" [ngClass]="item?.current_status"></div> -->
          <span class="friend-name">{{item?.name}}</span>
          <span class="friend-message">{{item?.ivite_msg}}</span>
          <span class="friend-option">
            <i class="fa fa-check" (click)="accept_friendRequest(item)"></i>
            <i class="fa fa-times" (click)="reject_friendRequest(item, true)"></i>
          </span>
        </div>
      </div>    
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Follow</ng-template>
      <div class="friend-tab">
        <div class="friend-item" *ngFor="let item of friend.follow">
          <img class="friend-profile" [src]="item?.profile">
          <!-- <div class="friend-profile-status" [ngClass]="item?.current_status"></div> -->
          <span class="friend-name">{{item?.name}}</span>
          <span class="friend-message">{{item?.ivite_msg}}</span>
          <span class="friend-option">
            <i class="fa fa-times" (click)="reject_friendRequest(item, false)"></i>
          </span>
        </div>
      </div>    
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button mat-button [mat-dialog-close]="close">Close</button>
  </div>
</div>

<app-context-menu [data]="friendContextMenu" (menuItemSelected)="onMenuItemSelected($event)"></app-context-menu>
