<div (keyup)="onKeyup($event)">
  <h1 mat-dialog-title>Drawing</h1>
  <div mat-dialog-content class="padding-0">
    <canvas 
      #canvas 
      id="canvas"
      width="600"
      height="400"
    ></canvas>
    <div>
      <div class="paint-tools">
        <span class="paint-tool-button" (click)="mode('pointer')">
          <i class="fa fa-mouse-pointer"></i>
        </span>
        <span class="paint-tool-button" (click)="mode('brush')">
          <i class="fa fa-paint-brush"></i>
        </span>
        
        <span class="paint-tool-button" (click)="add_text()">
          <i class="fa fa-font"></i>
        </span>
        <!-- <button (click)="bringToFront()">
          <i class="fa fa-picture-o"></i>
        </button> -->

        <span class="paint-tool-button" (click)="group_object()">
          <i class="fa fa-object-group"></i>
        </span>
        <span class="paint-tool-button" (click)="ungroup_object()">
          <i class="fa fa-object-ungroup"></i>
        </span>
        
        <span class="paint-tool-button" (click)="change_zIndex('front')">
          <i class="fa fa-caret-square-o-up"></i>
        </span>
        <span class="paint-tool-button" (click)="change_zIndex('back')">
          <i class="fa fa-caret-square-o-down"></i>
        </span>

        
        <span class="paint-tool-button" (click)="flip_object('horizontal')">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-flip-horizontal" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 12l18 0"></path>
            <path d="M7 16l10 0l-10 5l0 -5"></path>
            <path d="M7 8l10 0l-10 -5l0 5"></path>
          </svg>
        </span>
        <span class="paint-tool-button" (click)="flip_object('vertical')">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-flip-vertical" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 3l0 18"></path>
            <path d="M16 7l0 10l5 0l-5 -10"></path>
            <path d="M8 7l0 10l-5 0l5 -10"></path>
          </svg>
        </span>


      </div>

      <div class="paint-tools">
        <span class="paint-tool-button" (click)="add_object('circle', true)">
          <i class="fa fa-circle"></i>
        </span>
        <span class="paint-tool-button" (click)="add_object('circle', false)">
          <i class="fa fa-circle-o"></i>
        </span>

        <span class="paint-tool-button" (click)="add_object('rect', true)">
          <i class="fa fa-square"></i>
        </span>
        <span class="paint-tool-button" (click)="add_object('rect', false)">
          <i class="fa fa-square-o"></i>
        </span>
      </div>
      
      <label for="brush-size">브러시 크기: </label>
      <input type="range" id="brush-size" [(ngModel)]="brushSize" min="1" max="50" />
      <br />
      <input type="color" [(ngModel)]="fgColor" />
      <input type="color" [(ngModel)]="bgColor" />

      <mat-form-field style="width:100px;">
        <mat-label>Canvas Width</mat-label>
        <input matInput type="number" placeholder="canvas width" [(ngModel)]="canvasWidth" (change)="change_canvasSize()">
      </mat-form-field>

      <mat-form-field style="width:100px;">
        <mat-label>Canvas Height</mat-label>
        <input matInput type="number" placeholder="canvas height" [(ngModel)]="canvasHeight" (change)="change_canvasSize()">
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="buttons">
      <button mat-button mat-dialog-close="close">Close</button>
      <button class="primary" mat-button mat-dialog-close="success" (click)="saveCanvasAsFile()">Send</button>
    </div>
  </div>
</div>