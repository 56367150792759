<div *ngIf="!loading.complete || !init_press" class="loading" [style.background-image]="cover">
  <div class="title">{{title}}</div>
  <div class="press-any-key" *ngIf="loading.complete">Press any key</div>
  <div class="progress-wrapper">
    <div class="progress" *ngFor="let key of loading.keys">
      <div class="progress-bar" [style.width.%]="loading.list[key].percent"></div>
      <div class="progress-label">
        <span>{{loading.list[key].filename}}</span>
        <span>{{loading.list[key].size}}</span>
      </div>
    </div>
  </div>
</div>
<div class="select-music-wrapper" (window:keyup)="onKeyup($event)" (window:keydown)="onKeydown($event)" (window:resize)="onResize($event)" (window:popstate)="onLeave($event)">
  <div class="background-wrapper">
    <div id="board" [ngStyle]="{'width.px' : setting.boardWidth}">
      <div id="board-combo-container" [ngClass]="combo.lastClass">
        <div class="combo-text" [ngClass]="{'heartbeat': keyPress}">{{combo.lastJudge}}</div>
        <div class="combo" *ngIf="combo.count > 0" [ngClass]="{'heartbeat': keyPress}">{{combo.count}}</div>
      </div>
      <div id="score" *ngIf="score" [ngClass]="{'heartbeat': keyPress}">{{scorePrint}}</div>
      <div *ngFor="let line of effectMap; index as i;" class="line" (click)="onKeyTouch(i)">
        <!-- <div *ngFor="let note of line" class="note" [ngStyle]="{'top.px':note.y, 'height.px':note.py}"></div> -->
      </div>
      <div id="board-answerline" [style.top.%]="this.answerLinePer"></div>
      <div id="board-bottom-container" [style.top.%]="this.answerLinePer">
        <span class="key" *ngFor="let effect of effectMap; index as i;" (click)="onKeyTouch(i)">
          {{effect.key}}
          <!-- <div [ngClass]="{'effect': effect.keyPress}"></div> -->
        </span>
      </div>
      <canvas #noteCanvas class="note-canvas"></canvas>

      <div id="board-progress-bar">
        <div id="life-bar" [style.height.%]="combo.life"></div>
        <div id="time-bar" [style.height.%]="timebarWidth"></div>
      </div>
      <div class="countdown" *ngIf="countdown > 0">{{countdown}}</div>
    </div>
    <div class="right-side-wrapper">
      <div *ngFor="let reaction of currentReaction" class="reaction" [style.left.px]="reaction.x" [style.bottom.px]="reaction.y">{{reaction.reaction}}</div>
      <div class="thmubnail" [style.background-image]="thumbnailImage"></div>
      <div class="metadata">
        <div class="title">{{title}}</div>
        <div class="artist">{{artist}}</div>
      </div>
      <canvas #visualizationCanvas></canvas>
    </div>
    <div *ngIf="!videoURL && coverImage" class="cover-image" [style.background-image]="coverImage"></div>
    <div *ngIf="videoURL" class="background-movie">
      <video #video (canplaythrough)="onReadyVideo()" [muted]="'muted'">
        <source [src]="videoURL">
      </video>
    </div>
    <div id="mmd-wrapper" class="cover-image"></div>
  </div>
</div>

<audio #audio *ngIf="init_press && audioURL.length" (canplaythrough)="onCanPlay()" [src]="audioURL" [volume]="setting.volume"></audio>
<img id="effect2" style="display: none;" src="../../../assets/images/effect.png"/>
<img id="effect" style="display: none;" src="../../../assets/images/Spear1.png"/>