import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PlayService } from "../../service/play.service";
import { StatusService } from '../../service/status.service';
import { ShopService } from "../../service/shop.service";
import { CraftService } from 'src/app/service/craft.service';
import { GameManagerService } from 'src/app/service/game-manager.service';
import { SettingService } from '../../service/setting.service';

@Component({
  selector: 'app-craft-music-list-dialog',
  templateUrl: './craft-music-list-dialog.component.html',
  styleUrls: ['../shop-dialog/shop-dialog.component.scss', './craft-music-list-dialog.component.scss']
})
export class CraftMusicListDialogComponent {
  public clientHeight: number = 0;
  public clientWidth: number = 0;
  public cardContainerWidth: number = 1280;
  public list: Array<any> = [];
  public audio: any = undefined;
  public isCreatable = false;

  constructor(
    public dialogRef: MatDialogRef<CraftMusicListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, 
    private route: ActivatedRoute,
    
    public dialog: MatDialog,
    public playService : PlayService,
    public shopService : ShopService,
    public craftService : CraftService,
    public settingService: SettingService,
    public gms: GameManagerService,
    public status: StatusService,
  ) { 
    this.status.isOpenDialog = true;
  }

  ngOnInit(): void {
    this.onResize(null);
    this.load_list();
  }

  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }
  updateClosedReason(reason: string): void {
    this.data.closedReason = reason;
  }
  close(): void {
    this.dialogRef.close();
  }

  
  public load_list() {
    this.shopService.load_refresh();
    this.craftService.get_list().subscribe((res:any)=>{
      console.log(res);

      if (res.status){
        this.list = res.data;
        
        if (!this.list?.length || !this.list[0].craft_now) {
          this.isCreatable = true;
        }
      }
    });
  }
  public onResize(event: any) {
    this.clientHeight = +(window.innerHeight || 0);
    this.clientWidth = +(window.innerWidth || 0);
    this.cardContainerWidth = Math.floor((+(window.innerWidth || 0) - 277) / 320) * 320;

    console.log(this.cardContainerWidth);
  }

  public onFocus(event: any, item: any) {
    this.audio = item.audio_url;
  }
    
  public craft(event:any, item: any) {
    let cmid: number = item?.id || 0;

    event.stopPropagation();

    this.router.navigate([`../craft/${cmid}`], { relativeTo: this.route });
    this.close();
  }
}
