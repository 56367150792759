import { Component } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { UtilsService } from './service/utils.service';
import { StatusService } from "./service/status.service";
import { AccountService } from "./service/account.service";
import { NetworkService } from './service/network.service';
import { GameManagerService } from './service/game-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title: string = 'frontend';
  
  constructor(
    public dialog: MatDialog,
    public status: StatusService,
    public account: AccountService,
    public network: NetworkService,
    public gms: GameManagerService,
  ) {
  }

  ngOnInit(): void {
    // this.checkDevTools(); 
    this.gms.refresh();

    this.account.get_session().subscribe((res)=>{
      if (!res.status) {
        if (location.href != 'https://soulbeat.siya.co.kr/#/background' && location.href.search('https://soulbeat.siya.co.kr/#/chat') == -1) {
          location.href = '/#';
        }
        this.status.sidebar = false;
      }
      else {
        this.network.connect(this.account.session.uuid || res?.user?.id);
      }
    });
  }

  public checkDevTools() {
    let element = new Image();
    let isOpenDevTools: boolean = false;
    let checkDevToolsHandler: any;
    
    Object.defineProperty(element, 'id', {
      get: function() {
        isOpenDevTools = true;
        // clearInterval(checkDevToolsHandler);
        document.location.href = "https://www.naver.com";
      }
    });

    checkDevToolsHandler = setInterval(() => {
      console.log(Object.defineProperties(new Error, {
        toString: {
          value() {
            (new Error).stack?.includes('toString@') && alert('Safari devtools')
          }
        },
        message: {
          get() {
            document.location.href = "https://www.naver.com";
          }
        },
      }));

      console.dir(element);
    }, 1000);
  }

  public click = {
    toggleMenu: () => {
      this.status.sidebarExpand = !this.status.sidebarExpand;

      console.log(this.status.sidebarExpand);
    },
  }
}
