import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-pause-dialog',
  templateUrl: './pause-dialog.component.html',
  styleUrls: ['./pause-dialog.component.scss']
})
export class PauseDialogComponent implements OnInit {

  constructor(
    private status: StatusService,
  ) { 
    this.status.isOpenDialog = true;
  }

  ngOnDestroy():void {
    this.status.isOpenDialog = false;
  }
  ngOnInit(): void {
  }

}
