<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Ranking">
      <div class="ranking-scroll">
        <div class="ranking-wrapper" *ngFor="let row of data.ranking">
          <img class="ranking-profile-image" [src]="row.profile"/>
          <div class="ranking">
            <div class="center">
              <div class="name" [ngClass]="{'my-record' : row.my}">{{row.name}}</div>
              <div class="score">{{row.score}}</div>
            </div>
            <span class="replay-button" *ngIf="row.replay_id" (click)="show_replay(row.replay_id)">
              <i class="fa fa-history"></i>Replay
            </span>
          </div>
          <div class="ranking-right">
            <span class="center">
              <div>
                <div class="rank" [ngClass]="row.rankClass">{{row.rank}}</div>
              </div>
              <div>
                <div class="accuracy">{{row.accuracy}}%</div>
                <div class="date">{{row.ts}}</div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="My Record">
      <div class="ranking-scroll">
        <div class="ranking-wrapper" *ngFor="let row of data.my_ranking">
          <img class="ranking-profile-image" [src]="row.profile"/>
          <div class="ranking">
            <div class="center">
              <div class="name" [ngClass]="{'my-record' : row.my}">{{row.name}}</div>
              <div class="score">{{row.score}}</div>
            </div>
            <span class="replay-button" *ngIf="row.replay_id" (click)="show_replay(row.replay_id)">
              <i class="fa fa-history"> Replay</i>
            </span>
          </div>
          <div class="ranking-right">
            <span class="center">
              <div>
                <div class="rank" [ngClass]="row.rankClass">{{row.rank}}</div>
              </div>
              <div>
                <div class="accuracy">{{row.accuracy}}%</div>
                <div class="date">{{row.ts}}</div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <!-- <div class="title">Ranking</div>
  <div class="ranking-scroll">
    <div class="ranking-wrapper" *ngFor="let row of data.ranking">
      <img class="ranking-profile-image" src="../../../assets/images/logo.png"/>
      <div class="ranking">
        <div class="center">
          <div class="name" [ngClass]="{'my-record' : row.my}">{{row.name}}</div>
          <div class="score">{{row.score}}</div>
        </div>
      </div>
      <div class="ranking-right">
        <span class="center">
          <div>
            <div class="rank" [ngClass]="row.rankClass">{{row.rank}}</div>
          </div>
          <div>
            <div class="accuracy">{{row.accuracy}}%</div>
            <div class="date">{{row.ts}}</div>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div class="my-ranking">
    <div class="title">my Record</div>
    <div class="ranking-scroll">
      <div class="ranking-wrapper" *ngFor="let row of data.my_ranking">
        <img class="ranking-profile-image" src="../../../assets/images/logo.png"/>
        <div class="ranking">
          <div class="center">
            <div class="name" [ngClass]="{'my-record' : row.my}">{{row.name}}</div>
            <div class="score">{{row.score}}</div>
          </div>
        </div>
        <div class="ranking-right">
          <span class="center">
            <div>
              <div class="rank" [ngClass]="row.rankClass">{{row.rank}}</div>
            </div>
            <div>
              <div class="accuracy">{{row.accuracy}}%</div>
              <div class="date">{{row.ts}}</div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div> -->
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button mat-button class="secondary-btn" [mat-dialog-close]="'Close'">Close</button>
    <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
  </div>
</div>