<!-- <div mat-dialog-content *ngIf="!myMusicList?.length">
  <div class="data-placeholder" *ngIf="init">
    <span class="center">
      <i class="fa-solid fa-triangle-exclamation"></i><br>
      No music is available.<br>Only musics purchased as "lifelong" are available.
    </span>
  </div>
  <div class="data-placeholder" *ngIf="!init">
    <span class="center">
      <i class="fa-solid fa-truck-fast"></i><br>
      Loading the available music.
    </span>
  </div>
</div> -->
<div mat-dialog-content *ngIf="init && myMusicList?.length" class="stage-music-dialog" (window:keyup)="onKeyUp($event)">
  <div class="music-header">
    <span class="left">
      <label for="menuIcon">
        <i class="fa-solid fa-bars"></i>
      </label>
    </span>
    <span class="right">
      <i class="fa-solid fa-check" (click)="close('Select')"></i>
      <i class="fa-solid fa-close" (click)="close()"></i>
    </span>
  </div>
  <input type="checkbox" checked id="menuIcon">
  <div class="music-body">
    <div class="music-cover-wrapper">
      <div class="music-cover-image" [style.--background]="currentMusic.thumbnail_url"></div>
      <div class="music-cover-info">
        <div class="title">{{currentMusic.title}}</div>
        <div class="artist">{{currentMusic.artist}}</div>
        
        <div class="controller">
          <div class="audio-slider" style="min-width: unset; margin: 0;">
            <div class="controls" style="margin: 0;">
              <span class="current-time" style="font-size: 10px;">{{seekBarTime}}</span>
              <div class="slider" style="margin: 0 8px;">
                <div class="progress" [style.width.%]="seekBarWidthPercent"></div>
                <div class="seek-capturer" (click)="onSeek($event)"></div>
              </div>
            </div>
          </div>
          <i class="fa-solid fa-backward" (click)="next_music(-1)"></i>
          <i class="fa-solid" (click)="togglePlay()" [ngClass]="[isPlay ? 'fa-pause':'fa-play']"></i>
          <i class="fa-solid fa-forward" (click)="next_music(1)"></i>
        </div>
      </div>
    </div>
    <div class="music-row-wrapper">
      <div class="music-row" *ngFor="let item of myMusicList" (click)="update_currentMusic(item)" [ngClass]="{'active': currentMusic.uuid == item.uuid}">
        <div class="album-art" [style.--background]="item.thumbnail_url"></div>
        <div class="music-info">
          <div class="title">{{item.title}}</div>
          <div class="artist">{{item.artist}}<span class="duration">{{item.duration_parse}}</span></div>
          <div class="tag-wrapper">
            <span class="tag" *ngFor="let tag of item.tags">
              #{{tag}}
            </span>
          </div>
        </div>
      </div>
      <br>
    </div>
    <div class="music-controller">
      <div class="music-controller-body">
        <div class="audio-slider">
          <div class="controls">
            <span class="current-time">{{seekBarTime}}</span>
            <div class="slider">
              <div class="progress" [style.width.%]="seekBarWidthPercent"></div>
              <div class="seek-capturer" (click)="onSeek($event)"></div>
            </div>
          </div>
        </div>
        <div class="title">{{currentMusic.title}}</div>
        <div class="artist">&nbsp;{{currentMusic.artist}}&nbsp;</div>
        <div class="controller">
          <i class="fa-solid fa-backward" (click)="next_music(-1)"></i>
          <i class="fa-solid" (click)="togglePlay()" [ngClass]="[isPlay ? 'fa-pause':'fa-play']"></i>
          <i class="fa-solid fa-forward" (click)="next_music(1)"></i>
        </div>
      </div>
      <div class="music-controller-footer">
        <i class="fa-solid fa-bookmark"></i>
        <!-- <i class="fa-regular fa-bookmark"></i> -->
        <i class="fa-solid fa-stopwatch"></i>
        <i class="fa-solid fa-shuffle" [ngClass]="{'checked': shuffle}" (click)="toggleShuffle()"></i>
        <i class="fa-solid fa-repeat"  [ngClass]="{'checked': loop}" (click)="toggleLoop()"></i>
        <i class="fa-solid fa-bag-shopping"></i>
      </div>
    </div>
  </div>
</div>
<!-- <div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text" (click)="updateClosedReason(button.text)">{{button.text}}</button>
  </div>
</div> -->

<audio #audio [volume]="gms.setting?.setting?.volume" [muted]="mute" [src]="currentMusic.audio_url" (ended)="next_music(1)" autoplay></audio>