import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { HttpService } from './http.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  public point: number = 0;
  public ticket: number = 0;

  constructor(
    private httpService: HttpService,
    private accountService: AccountService,
  ) { }

  public load_refresh() {
    this.get_point();
    this.get_ticket();
  }

  public get_list(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/list`);
  }
  public get_point() {
    // return this.httpService.httpClient.get<any>(`https://demo-api.s.actionpower.kr/api/v1/demo/summary/example`).subscribe((res: any)=>{
    //   console.log(res);
    // })
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/point`).subscribe((res: any)=>{
      this.point = res.point || 0;
    }, (error) => {
      if (error.status == 403) {
        this.accountService.signout().subscribe((res)=> {
          location.href = this.httpService.apiServer;
        })
      }
    })
  }
  public get_ticket() {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/ticket`).subscribe((res: any)=>{
      this.ticket = res.ticket || 0;
    })
  }
  public get_point_log(page: number, num: number): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/point/log`, this.httpService.package_param({page: page, num: num}));
  }
  public get_ticket_log(page: number, num: number): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/ticket/log`, this.httpService.package_param({page: page, num: num}));
  }
  public buy_music(mid: string, select_price_option:number): Observable<any> {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/buy`, {mid:mid, select_price_option:select_price_option});
  }

  public get_ads(): Observable<any> {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/ads`, {});
  }
  public watch_ads(secret: string): Observable<any> {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/shop/ads/watch`, {secret});
  }



  public create_post(title: string, content: string): Observable<any> {
    let param: any = {
      title: title,
      content: content,
    };

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/post`, param);
  }
  public update_post(id: string, title: string, content: string): Observable<any> {
    let param: any = {
      id: id,
      title: title,
      content: content,
    };

    return this.httpService.httpClient.put<any>(`${this.httpService.apiServer}/api/v1/post`, param);
  }
  public delete_post(id: string): Observable<any> {
    let param: any = {
      id: id
    };

    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/post`, this.httpService.package_param(param));
  }
}
