import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { StageWallpaperDialogComponent } from '../stage-wallpaper-dialog/stage-wallpaper-dialog.component';
import { StageMusicDialogComponent } from '../stage-music-dialog/stage-music-dialog.component';

import { FriendService } from 'src/app/service/friend.service';
import { StatusService } from 'src/app/service/status.service';
import { ShopService } from 'src/app/service/shop.service';
import { AccountService } from 'src/app/service/account.service';
import { GameManagerService } from 'src/app/service/game-manager.service';
import { SettingService } from 'src/app/service/setting.service';
import { MMDService } from 'src/app/service/mmd.service';

declare let THREE: any;


@Component({
  selector: 'app-stage-setting-dialog',
  templateUrl: './stage-setting-dialog.component.html',
  styleUrls: ['./stage-setting-dialog.component.scss']
})
export class StageSettingDialogComponent {
  public progress: number = 0;
  public requestUpload: boolean = false;
  public isChange: boolean = false;
  public mute: boolean = false;
  public files: any = {
    model: undefined,
    pose: undefined,
    wallpaper: undefined,
  };

  public controlPanel = {
    image: {
      image: '',
      image_url: '',
      filter: '',
      blur: 0,
      grayscale: 0,
      brightness: 0,
      contrast: 0,
      hue_rotate: 0,
    },
    sdxl: {
      seed: Math.floor(Math.random()*65535),
      cfg: 8,
      step: 30,
      prompt: 'river, cherry blossom, mountain, fullmoon, midnight, asian, dynamic angle,16k,8k,4k,4k_resolution,8k_resolution,16k_resolution, <lora:more_details:0.5>,beautiful,best_quality',
      negativePrompt: 'worst quality, large head, low quality, extra digits, bad eye,  EasyNegativeV2,  ng_deepnegative_v1_75t',
    },
    music: {
      uuid: 0,
      audio: '',
      audio_url: '',
      highlight: 0,
      volume: 0.5,
    },
    model: {
      x: 0,
      y: 0,
      z: 0,
    },
    camera: {
      x: 5,
      y: 12,
      z: 25,
    },
    lookAt: {
      x: 3,
      y: 15,
      z: 0,
    },
  }

  private threeMMD: any = {}
  public loading: any = {};

  constructor(
    public dialogRef: MatDialogRef<StageSettingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private dialog: MatDialog,
    private sanitizer: DomSanitizer,

    public gms: GameManagerService,
    public friend: FriendService,
    public setting: SettingService,
    public shopService: ShopService,
    private status: StatusService,
    private mmd: MMDService,
  ) { 
    let { 
      image, image_url, filter, blur, grayscale, brightness, contrast, hue_rotate,
      music_audio, music_audio_url, music_highlight, music_volume, sdxl_setting,
      model_x, model_y, model_z,
      camera_x, camera_y, camera_z,
      look_at_x, look_at_y, look_at_z,
     } = this.setting.stage;

    this.status.isOpenDialog = true;
    
    console.log(sdxl_setting);
    console.log(this.setting.stage);

    this.controlPanel.sdxl.seed = sdxl_setting?.seed || this.controlPanel.sdxl.seed;
    this.controlPanel.sdxl.cfg = sdxl_setting?.cfg || this.controlPanel.sdxl.cfg;
    this.controlPanel.sdxl.step = sdxl_setting?.step || this.controlPanel.sdxl.step;
    this.controlPanel.sdxl.prompt = sdxl_setting?.prompt || this.controlPanel.sdxl.prompt;
    this.controlPanel.sdxl.negativePrompt = sdxl_setting?.negativePrompt || this.controlPanel.sdxl.negativePrompt;

    this.controlPanel.image.image = image;
    this.controlPanel.image.image_url = image_url;
    this.controlPanel.image.filter = filter;
    this.controlPanel.image.blur = blur;
    this.controlPanel.image.grayscale = grayscale;
    this.controlPanel.image.brightness = brightness;
    this.controlPanel.image.contrast = contrast;
    this.controlPanel.image.hue_rotate = hue_rotate;

    this.controlPanel.music.audio = music_audio;
    this.controlPanel.music.audio_url = music_audio_url;
    this.controlPanel.music.highlight = music_highlight;
    this.controlPanel.music.volume = music_volume;

    this.controlPanel.model.x = model_x;
    this.controlPanel.model.y = model_y;
    this.controlPanel.model.z = model_z;
    this.controlPanel.camera.x = camera_x;
    this.controlPanel.camera.y = camera_y;
    this.controlPanel.camera.z = camera_z;
    this.controlPanel.lookAt.x = look_at_x;
    this.controlPanel.lookAt.y = look_at_y;
    this.controlPanel.lookAt.z = look_at_z;

    this.updateFilter();

    setTimeout(() => {
      this.refresh_mmd();
    }, 200);
  }

  saveConfirm(): void {
    if (this.isChange) {
      let data = {
        width: '450px',
        data : {
          title: 'Notice',
          content: `Do you want to update this setting?`,
          button : [
            {text : 'Update', color : 'purple'},
            {text : 'Cancel', color : '#333'},
          ],
        },
      }
      let dialogRef = this.dialog.open(ConfirmDialogComponent, data);
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Update') {
          this.setting.set_stage({
            sdxl_setting: JSON.stringify(this.controlPanel.sdxl, null, 2),
            image: this.controlPanel.image.image,
            blur: this.controlPanel.image.blur,
            grayscale: this.controlPanel.image.grayscale,
            brightness: this.controlPanel.image.brightness,
            contrast: this.controlPanel.image.contrast,
            hue_rotate: this.controlPanel.image.hue_rotate,
            music_audio: this.controlPanel.music.audio,
            music_highlight: this.controlPanel.music.highlight,
            music_volume: this.controlPanel.music.volume,
            model_x: this.controlPanel.model.x,
            model_y: this.controlPanel.model.y,
            model_z: this.controlPanel.model.z,
            camera_x: this.controlPanel.camera.x,
            camera_y: this.controlPanel.camera.y,
            camera_z: this.controlPanel.camera.z,
            look_at_x: this.controlPanel.lookAt.x,
            look_at_y: this.controlPanel.lookAt.y,
            look_at_z: this.controlPanel.lookAt.z,
          });
          
          let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '450px',
            data : {
              title: 'Notice',
              content: `Profile information has been updated.`,
              button : [
                {text : 'OK', color : '#333'},
              ],
            },
          });
        }
      }); 
    }
  }
  ngOnDestroy(): void {
    this.saveConfirm();
    this.status.isOpenDialog = false;
  }

  close(): void {
    this.saveConfirm();
    this.status.isOpenDialog = false;
    this.dialogRef.close();
  }
  public updateFilter() {
    this.controlPanel.image.filter = `
      blur(${this.controlPanel.image.blur}px)
      grayscale(${this.controlPanel.image.grayscale})
      brightness(${this.controlPanel.image.brightness})
      contrast(${this.controlPanel.image.contrast})
      hue-rotate(${this.controlPanel.image.hue_rotate}deg)
    `;
  }
  public updateMMD() {
    this.threeMMD.camera.position.x = this.controlPanel.camera.x || 5;
    this.threeMMD.camera.position.y = this.controlPanel.camera.y || 18;
    this.threeMMD.camera.position.z = this.controlPanel.camera.z || 24;
    this.threeMMD.camera.lookAt(
      new THREE.Vector3(
        this.controlPanel.lookAt.x || 3, 
        this.controlPanel.lookAt.y || 15, 
        this.controlPanel.lookAt.z || 0
      )
    );
  }

  public onInput(event: any) {
    return event.preventDefault();
  }
  public onChange(event: any) {
    this.updateFilter();
    this.updateMMD();
    this.isChange = true;
  }

  public onFileDropped($event: any, key: string) {
    this.prepareFilesList($event, key);
  }
  public fileBrowseHandler(files: any, key: string) {
    this.prepareFilesList(files?.target?.files, key);
  }
  public deleteFile(key: string) {
    this.files[key] = undefined;
    this.files[`${key}_url`] = undefined;
    this.isChange = true;
  }
  public prepareFilesList(files: Array<any>, key: string) {
    for (const item of files) {
      let url = URL.createObjectURL(item);

      this.progress = 0;
      this.files[key] = item;
      this.files[`${key}_url`] = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.controlPanel.image.image = `url("${url}")`
      this.isChange = true;
    }
  }

  public refresh_mmd() {
    this.mmd.init_stageMMD(
      'stage-mmd-wrapper', this.threeMMD, this.setting.stage, this.loading, 
      this.setting.stage.model, this.setting.stage.pose, this.setting.stage.category, this.setting.stage.delay,
      this.setting.myModelList, this.setting.myPoseList, 'stage-dat-gui');
  }


  public request_sdxl() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Notice',
        content : `
          <b>CFG</b> : <code>${this.controlPanel.sdxl.cfg}</code><br>
          <b>Seed</b> : <code>${this.controlPanel.sdxl.seed}</code><br>
          <b>Step</b> : <code>${this.controlPanel.sdxl.step}</code><br>
          <b>Prompt</b><br><code>${this.controlPanel.sdxl.prompt}</code><br>
          <b>NegativePrompt</b><br><code>${this.controlPanel.sdxl.negativePrompt}</code><br><br>
          Do you have any images you want?<br>
          AI will draw the image you want.<br>
          It takes <span class="sb-point">2,500<i class="fa fa-star"></i></span> to create once.
        `,
        button : [
          { text : 'Cancel', color : '#333' },
          { text : 'Generate', color : 'purple' }
        ],
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == 'Generate') {
        const open_failedDialog = (error = undefined) => {
          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Warning',
              content : error ? JSON.stringify(error) : 'Sorry, AI drawing failed.<br>Please try again in a moment.',
              button : [
                { text : 'OK', color : '#333' },
              ],
            },
          });
        }

        this.setting.create_wallpaper(this.controlPanel.sdxl.cfg, this.controlPanel.sdxl.seed, this.controlPanel.sdxl.step, this.controlPanel.sdxl.prompt, this.controlPanel.sdxl.negativePrompt).subscribe((res:any) => {
          if (res?.status) {
            this.select_wallpaper();
          }
          else {
            open_failedDialog();
          }
        }, error => open_failedDialog(error));
      }
    });
  }
  public upload_image() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Notice',
        content : `
          Do you want to decorate the stage with your image?<br>
          For just <span class="sb-point">4,000<i class="fa fa-star"></i></span>, you can decorate the stage with your image.
        `,
        button : [
          { text : 'Cancel', color : '#333' },
          { text : 'Upload', color : 'purple' }
        ],
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == 'Upload') {
        const open_failedDialog = (error = undefined) => {
          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Warning',
              content : error ? JSON.stringify(error) : 'Sorry, Image upload was failed.<br>Please try again in a moment.',
              button : [
                { text : 'OK', color : '#333' },
              ],
            },
          });
        }

        this.setting.create_wallpaper(this.controlPanel.sdxl.cfg, this.controlPanel.sdxl.seed, this.controlPanel.sdxl.step, this.controlPanel.sdxl.prompt, this.controlPanel.sdxl.negativePrompt).subscribe((res:any) => {
          if (res?.status) {
            console.log(res);
            
            this.select_wallpaper();
          }
          else {
            open_failedDialog();
          }
        }, error => open_failedDialog(error));
      }
    });    
  }
  public select_wallpaper() {
    let data = {
      selectImage: {
        image: '',
        image_url: '',
      },
      button : [{ text : 'OK', color : '#333' }],
    }

    const dialogRef = this.dialog.open(StageWallpaperDialogComponent, {
      width : '78vw',
      height: '100vh',
      panelClass: ['dialog-transparent', 'dialog-height-100vh'],
      // panelClass: 'dialog-padding-horizon-0',
      data,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (data.selectImage?.image_url?.length) {
        this.controlPanel.image.image = data.selectImage?.image;
        this.controlPanel.image.image_url = data.selectImage?.image_url;
        this.isChange = true;
      }
    })
  }
  public select_music() {
    let data: any = {
      selectMusic: {},
      button : [        
        { text : 'Select', color : 'purple' },
        { text : 'Cancel', color : '#333' }
      ],
    }

    this.mute = true;

    const dialogRef = this.dialog.open(StageMusicDialogComponent, {
      width : '360px',
      panelClass: 'dialog-transparent',
      data,
    });

    dialogRef.afterClosed().subscribe(res => {
      this.mute = false;

      if (res == 'Select') {
        if (data.selectMusic?.uuid?.length) {
          this.controlPanel.music.uuid = data.selectMusic.uuid;
          this.controlPanel.music.audio = data.selectMusic.audio;
          this.controlPanel.music.audio_url = data.selectMusic.audio_url;
          this.controlPanel.music.highlight = data.selectMusic.audio_highlight;
          this.isChange = true;
        }
      }
    })
  }
  public select_model() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Warning',
        content : 'Sorry, This feature is being prepared.',
        button : [{ text : 'OK', color : '#333' }],
      },
    });
  }
  public select_motion() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Warning',
        content : 'Sorry, This feature is being prepared.',
        button : [{ text : 'OK', color : '#333' }],
      },
    });
  }
  public select_emotion() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width : '450px',
      data : {
        title : 'Warning',
        content : 'Sorry, This feature is being prepared.',
        button : [{ text : 'OK', color : '#333' }],
      },
    });
  }
}
