import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ShopService } from "../../service/shop.service";
import { StatusService } from "../../service/status.service";

@Component({
  selector: 'app-ticketlog-dialog',
  templateUrl: './ticketlog-dialog.component.html',
  styleUrls: ['./ticketlog-dialog.component.scss', '../pointlog-dialog/pointlog-dialog.component.scss']
})
export class TicketlogDialogComponent{
  public ticket_log: Array<any> = []
  public page: number = 1;
  public endpage: number = 0;
  public total: number = 0;
  public numOfRows: number = 10;

  
  constructor(
    public dialogRef: MatDialogRef<TicketlogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public shopService: ShopService,
    private status: StatusService,
  ) { 
    this.status.isOpenDialog = true;
    this.load_page(0);
  }

  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }

  public load_page(offset: number) {
    this.page += offset;

    this.shopService.get_ticket_log(this.page - 1, this.numOfRows).subscribe((res)=>{
      console.log(res);

      if (res.status){
        this.ticket_log = res.data;
        this.endpage = Math.ceil(res.total / this.numOfRows);
      }
    })
  }
  
  public close(): void {
    this.status.isOpenDialog = false;
    this.dialogRef.close();
  }
}
