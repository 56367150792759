<div class="pointlog-wrapper">
  <div class="pointlog-container">
    <table>
      <tr>
        <th>No.</th>
        <th>Title</th>
        <th>Point</th>
        <th>Date</th>
      </tr>
      <tr *ngFor="let log of point_log">
        <td>{{log.idx}}</td>
        <td>{{log.description}}
          <span *ngIf="log.title"> [<span class="highlight">{{log.title}}</span>] </span>
        </td>
        <td [ngClass]="log.type">{{log.point}}</td>
        <td>{{log.time}}</td>
      </tr>    
    </table>

    <div class="pagenation-wrapper">
      <span class="pagenation-indicator">
        <span (click)="load_page(-1)" *ngIf="page > 1"> Prev </span>
        <span>{{page}} / {{endpage}}</span>
        <span (click)="load_page(1)" *ngIf="page < endpage"> Next </span>
      </span>
    </div>
  </div>
</div>