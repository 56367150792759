<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.content"></p>
  <img *ngIf="data.image" [src]="data.image" style="width: 100%; height : auto; max-height: 100%;"/>
  
  <table>
    <tr>
      <td>Show MMD</td>
      <td colspan="3">
        <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.mmdDisplay">
          <mat-button-toggle class="model-selector-btn" value="1">On</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="0">Off</mat-button-toggle>
        </mat-button-toggle-group>
      </td>
    </tr>
    <tr>
      <td>MusicVideo</td>
      <td>
        <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.videoDisplay">
          <mat-button-toggle class="model-selector-btn" value="1">On</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="0">Off</mat-button-toggle>
        </mat-button-toggle-group>
      </td>
      <td>Background Image</td>
      <td>
        <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.bgImageDisplay">
          <mat-button-toggle class="model-selector-btn" value="1">On</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="0">Off</mat-button-toggle>
        </mat-button-toggle-group>
      </td>
    </tr>
    <tr>
      <td>Hit Effect</td>
      <td>
        <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.hitEffectDisplay">
          <mat-button-toggle class="model-selector-btn" value="1">On</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="0">Off</mat-button-toggle>
        </mat-button-toggle-group>
      </td>
      <td>Key Press Effect</td>
      <td>
        <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.keyPressEffectDisplay">
          <mat-button-toggle class="model-selector-btn" value="1">On</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="0">Off</mat-button-toggle>
        </mat-button-toggle-group>
      </td>
    </tr>

    <tr>
      <td>Extra difficult</td>
      <td colspan="3">
        <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.difficult">
          <mat-button-toggle class="model-selector-btn" value="0">No</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="1">Shuffle</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="2">Fade</mat-button-toggle>
          <mat-button-toggle class="model-selector-btn" value="3">Shuffle + Fade</mat-button-toggle>
        </mat-button-toggle-group>
      </td>
    </tr>

    
    <tr>
      <td>Board Width</td>
      <td><mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="data.setting.boardWidth" step="1" min="480" value="480" max="1280"></mat-slider></td>
      <td>Volume</td>
      <td><mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="data.setting.volume" step="0.01" min="0" value="0.4" max="1"></mat-slider></td>
    </tr>
    <tr>
      <td>Note Speed</td>
      <td><mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="data.setting.noteSpeed" step="0.01" min="0.1" value="1.5" max="3"></mat-slider></td>
      <td>Answer Line</td>
      <td><mat-slider thumbLabel (click)="$event.stopPropagation()" [(ngModel)]="data.setting.answerLine" step="0.01" min="0.35" value="0.85" max="0.95"></mat-slider></td>
    </tr>
  </table>
  <table>
    <tr >
      <td></td>
      <td class="center">Key</td>
      <td class="center">Subkey</td>
      <td class="center">Note</td>
      <td class="center">Effect</td>
    </tr>
    <tr *ngFor="let line of data.setting.lines; index as i;">
      <td>Line {{i + 1}}</td>
      <td><input type="text" [ngModel]="line.key" (keydown)="onKeyup(line, 'key', $event)"/></td>
      <td><input type="text" [ngModel]="line.subkey" (keydown)="onKeyup(line, 'subkey', $event)"/></td>
      <td><input type="color" [(ngModel)]="line.color"/></td>
      <td><input type="color" [(ngModel)]="line.effect"/></td>
    </tr>
  </table>

  <div *ngIf="data.input">
    <mat-form-field *ngFor="let input of data.input" style="width:100%;">
      <mat-label>{{input.label}}</mat-label>
      <input matInput type="{{input.type}}" placeholder="{{input.placeholder}}" [(ngModel)]="input.text">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text">{{button.text}}</button>
    <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
  </div>
</div>