<div class="shop-wrapper" (window:resize)="onResize($event)">
    <div class="shop-card-wrapper">
      <div class="shop-card-container" [style.width.px]='cardContainerWidth'>
        <div class="new-card" *ngIf="isCreatable" (click)="craft(null)">
          <span class="new-card-inner">
            <i class="fa fa-plus"></i>
            <div class="text">Craft New Music</div>
          </span>
        </div>

        <div class="shop-card" [ngClass]="{'denied' : item.craft_now}" *ngFor="let item of list" (mouseleave)="audio = null" (mouseenter)="onFocus($event, item)" (click)="craft(item)">
          <div *ngIf="!item.craft_now" class="ribbon ribbon-top-left">
            <span>Published</span>
          </div>
          <div class="thumbnail-container">
            <div class="thumbnail" [style.background-image]="item.thumbnail_url">
              <div class="thumbnail-inner" *ngIf="item.price && !item.uid">
                <span class="thumbnail-inner-text">
                  <div class="score">You can buy this music!</div>
                  <div class="require-point">{{item.price}}<i class="fa fa-star sb-point-icon"></i></div>
                </span>
              </div>
            </div>
          </div>
          <div class="shop-card-content">
            <div class="shop-card-content-header">
              <span class="shop-card-title">{{item.title}}</span>
              <span class="shop-card-duration">{{item.duration_parse}}</span>
            </div>
            <div class="shop-card-content-header">
              <span class="shop-card-tag" *ngFor="let tag of item.tags">#{{tag}}</span>
            </div>
            <div class="shop-card-content-body">
              <span class="line" *ngIf="item.dlc_day_price >= 0">
                Day Plan
                <span class="line-right">
                  <span class="sb-point">{{item.dlc_day_price}}<i class="fa fa-star"></i></span>
                </span>
              </span>

              <span class="line" *ngIf="item.dlc_lifelong_price >= 0">
                Lifelong Plan
                <span class="line-right">
                  <span class="sb-point">{{item.dlc_lifelong_price}}<i class="fa fa-star"></i></span>
                </span>
              </span>

              <span class="line" *ngIf="item.dlc_expired_count >= 0">
                Number of Game per &nbsp;<span class="sb-ticket"><i class="fa fa-credit-card-alt"></i></span>
                <span class="line-right">
                  <span class="sb-ticket">{{item.dlc_expired_count}} Games</span>
                </span>
              </span>

              <span class="line" *ngIf="item.benefit">
                Benefit
                <span class="line-right">
                  <span class="sb-point">{{item.benefit}}<i class="fa fa-star"></i></span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <audio *ngIf="audio" style="display: none;" [src]="audio" [volume]="gms.setting?.setting?.volume" autoplay></audio>