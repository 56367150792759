<div class="select-music-wrapper" (window:resize)="onResize($event)" (window:keyup)="onKeyup($event)" (window:keydown)="onKeydown($event)">
  <div class="background-wrapper" id="container">
    <div id="board" [style.width.px]="boardWidth" [style.transform]="boardScale" [ngClass]="{'deactivate' : metadata.focus != 1}" (click)="metadata.focus = 1">
      <div id="board-combo-container" [ngClass]="combo.lastClass">
        <div class="combo-text" [ngClass]="{'heartbeat': keyPress}">{{combo.lastJudge}}</div>
        <div class="combo" *ngIf="combo.count > 0" [ngClass]="{'heartbeat': keyPress}">{{combo.count}}</div>
      </div>
      <div id="score" *ngIf="score" [ngClass]="{'heartbeat': keyPress}">{{scorePrint}}</div>
      <div *ngFor="let line of effectMap; index as i;" class="line" (click)="onKeyTouch(i)">
        <!-- <div *ngFor="let note of line" class="note" [ngStyle]="{'top.px':note.y, 'height.px':note.py}"></div> -->
      </div>
      <div id="board-answerline" [style.top.%]="this.answerLinePer"></div>
      <div id="board-bottom-container" [style.top.%]="this.answerLinePer">
        <span class="key" *ngFor="let effect of effectMap; index as i;" (click)="onKeyTouch(i)">
          {{effect.key}}
          <!-- <div [ngClass]="{'effect': effect.keyPress}"></div> -->
        </span>
      </div>
      <canvas class="note-canvas" #noteCanvas></canvas>

      <div id="board-life">
        <div id="board-life-bar" [style.width.%]="combo.life"></div>
      </div>
    </div>


    <div class="craft-note-wrapper">
      <div #flowNoteScroll class="flow-note-scroll" (mousewheel)="onScroll($event)" (mousemove)="onMouseMove($event)" [ngClass]="{'deactivate' : metadata.focus != 0}">
        <div class="flow-note-wrapper">
          <div class="flow-note-line" *ngFor="let flowlineNote of flowlineNotes; index as i;" [style.width.px]="flowlineWidth" (contextmenu)="seekCurrentTime(i, $event)" (click)="addNote(i, $event)">
            <div *ngFor="let note of flowlineNote; index as j;" class="note" [style.left.px]="note.x" [style.width.px]="note.w" [style.background-color]="noteColor[i]" (contextmenu)="seekCurrentTime(note.ts, $event)" (click)="deleteNote(i, j, note.ts, $event)"></div>
          </div>

          <div *ngIf="flowlineLongNoteSwitch.able" class="longnote-shadow" 
              [style.left.px]="flowlineLongNoteSwitch.left" 
              [style.top.px]="flowlineLongNoteSwitch.top" 
              [style.width.px]="flowlineLongNoteSwitch.width">
          </div>

          <div class="flow-note-timebar-sub" *ngFor="let left of flowlineTimebarSub" [style.left.px]="left"></div>
          <div class="flow-note-timebar" *ngFor="let left of flowlineTimebarMain" [style.left.px]="left"></div>
        </div>
      </div>
      <div class="flow-note-current-bar" [ngClass]="{'deactivate' : metadata.focus != 0}"></div>


      <div class="flow-note-remain-wrapper">
        <div class="board-wrapper" [style.width.px]="boardScaledWidth"></div>
        <div class="metadata-grid">
          <audio #audio class="audio" *ngIf="files.audio_url" (canplaythrough)="onCanPlay()" [src]="files.audio_url" [volume]="setting.volume" controls></audio>
        
          <div class="upload-wrapper">

            <div class="drag-and-drop-container" appDragAndDrop (fileDropped)="onFileDropped($event, 'album')">
              <input type="file" #fileDropRef id="fileDropRef" multiple accept='.png, .jpg, .jpeg' (change)="fileBrowseHandler($event, 'album')" />
              <div class="drag-and-drop-message"  *ngIf="!files.album_url">Album Art</div>
            
              <div *ngIf="files.album_url">
                <img [src]="files.album_url">
                <div class="icons">
                  <i class="fa fa-trash" (click)="deleteFile('album')"></i>
                </div>
              </div>
            </div>

            <div class="drag-and-drop-container" appDragAndDrop (fileDropped)="onFileDropped($event, 'cover')">
              <input type="file" #fileDropRef id="fileDropRef" multiple accept='.png, .jpg, .jpeg' (change)="fileBrowseHandler($event, 'cover')" />
              <div class="drag-and-drop-message" *ngIf="!files.cover_url">Cover Image</div>

              <div *ngIf="files.cover_url">
                <img [src]="files.cover_url">
                <div class="icons">
                  <i class="fa fa-trash" (click)="deleteFile('cover')"></i>
                </div>
              </div>
            </div>

            <div class="drag-and-drop-container" appDragAndDrop (fileDropped)="onFileDropped($event, 'video')">
              <input type="file" #fileDropRef id="fileDropRef" multiple accept='.mp4, .webm' (change)="fileBrowseHandler($event, 'video')" />
              <div class="drag-and-drop-message" *ngIf="!files.video_url">Music Video</div>
              
              <div *ngIf="files.video_url">
                <video #video muted>
                  <source [src]="files.video_url">
                </video>
                <div class="icons">
                  <i class="fa" [ngClass]="files.video_class" (click)="play(video, 'video')"></i>
                  <i class="fa fa-trash" (click)="deleteFile('video')"></i>
                </div>
              </div>
            </div>

            <div class="drag-and-drop-container" appDragAndDrop (fileDropped)="onFileDropped($event, 'audio')">
              <input type="file" #fileDropRef id="fileDropRef" multiple accept='.mp3, .wav' (change)="fileBrowseHandler($event, 'audio')" />
              <div class="drag-and-drop-message" *ngIf="!files.audio_url">Audio</div>

              <div *ngIf="files.audio_url">
                <div class="preview">
                  <i class="fa fa-file-audio-o"></i>
                </div>
                <div class="icons">
                  <i class="fa" [ngClass]="files.audio_class" (click)="play(audio, 'audio')"></i>
                  <i class="fa fa-trash" (click)="deleteFile('audio')"></i>
                </div>
              </div>
            </div>

            
          </div>
          <div class="metadata-wrapper">
            <mat-form-field>
              <mat-label>Title</mat-label>
              <input matInput placeholder="Title" [(ngModel)]="metadata.title" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Tag</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip
                  *ngFor="let tag of tags"
                  (removed)="remove(tag)">
                  {{tag}}
                  <button matChipRemove>
                    <i class="fa fa-close"></i>
                  </button>
                </mat-chip>
                <input
                  placeholder="Add New Tag"
                  #tagInput
                  [formControl]="tagCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                  {{tag.label}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="mat-form-padding width-33">
              <mat-label>Artist</mat-label>
              <input matInput placeholder="Artist" [(ngModel)]="metadata.artist" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>
            <mat-form-field class="width-66">
              <mat-label>Youtube Link</mat-label>
              <input matInput placeholder="Youtube Link" [(ngModel)]="metadata.youtubeLink" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)" (keyup.enter)="download_youtube($event)">
            </mat-form-field>
            <mat-form-field class="mat-form-padding width-30">
              <mat-label>Day Plan</mat-label>
              <input matInput type="number" placeholder="100" [(ngModel)]="metadata.dlc_day_price" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>
            <mat-form-field class="mat-form-padding width-30">
              <mat-label>Lifelong Plan</mat-label>
              <input matInput type="number" placeholder="100" [(ngModel)]="metadata.dlc_lifelong_price" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>
            <mat-form-field class="width-40">
              <mat-label>Number of Game per Ticket</mat-label>
              <input matInput type="number" placeholder="100" [(ngModel)]="metadata.dlc_expired_count" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>
            <mat-form-field class="mat-form-padding width-50">
              <mat-label>Audio Highlight (Second)</mat-label>
              <input matInput type="number" placeholder="0" [(ngModel)]="metadata.audio_highlight" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)" (keydown.enter)="seek(metadata.audio_highlight)">
            </mat-form-field>
            <mat-form-field class="width-50">
              <mat-label>divideTime</mat-label>
              <input matInput type="number" placeholder="1" [(ngModel)]="metadata.divideTime" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>


            <mat-form-field class="width-25">
              <mat-label>audioRate</mat-label>
              <input matInput type="number" placeholder="1" min="0.01" max="1.0" [(ngModel)]="metadata.audioRate" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)" (keydown.enter)="changeAudioRate(metadata.audioRate)">
            </mat-form-field>

            <mat-form-field class="width-25 mat-form-padding">
              <mat-label>autoCreateNoteThreshold</mat-label>
              <input matInput type="number" placeholder="1" [(ngModel)]="autoCreateNoteThreshold" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>

            <mat-form-field class="width-25 mat-form-padding">
              <mat-label>autoCreateNoteTimeThreshold</mat-label>
              <input matInput type="number" placeholder="1" [(ngModel)]="autoCreateNoteTimeThreshold" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>

            <mat-form-field class="width-25">
              <mat-label>autoCreateNoteMaxNote</mat-label>
              <input matInput type="number" placeholder="1" [(ngModel)]="autoCreateNoteMaxNote" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Description</mat-label>
              <textarea rows="3" matInput [(ngModel)]="metadata.description"></textarea>
            </mat-form-field>
            
          </div>

          <div class="footer-wrapper">
            <div class="footer-line">
              <div (click)="toggle_pauseNote()" class="pause-note-btn" *ngIf="metadata.notePlayMode == 'pause'">
                <span><i class="fa fa-pause"></i>Pause Mode</span>
              </div>
              <div (click)="toggle_pauseNote()" *ngIf="metadata.notePlayMode == 'play'">
                <span><i class="fa fa-play"></i>Play Mode</span>
              </div>
              <div (click)="toggle_createBySound()">
                <span><i class="fa fa-music"></i>Create by Sound</span>
              </div>
              <div (click)="exportSBN(metadata)">
                <span><i class="fa fa-cogs"></i>Create by AI</span>
              </div>
            </div>
            <div class="footer-line">
              <div (click)="jsonImport.click()">
                <span><i class="fa fa-upload"></i>Import</span>
              </div>
              <div (click)="exportSBN(metadata)">
                <span><i class="fa fa-download"></i>Export</span>
              </div>
              <div class="save-btn" (click)="save()">
                <span><i class="fa fa-cloud-upload"></i>Save</span>
              </div>
              <div class="publish-btn" (click)="publish()">
                <span><i class="fa fa-share-alt"></i>Publish</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<input type="file" #jsonImport accept='.sbn' (change)="importSBN($event)"/>

<img id="effect2" style="display: none;" src="../../../assets/images/effect.png"/>
<img id="effect" style="display: none;" src="../../../assets/images/Spear1.png"/>