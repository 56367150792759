import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { PlayService } from "../../service/play.service";
import { ShopService } from "../../service/shop.service";
import { CraftService } from 'src/app/service/craft.service';
import { GameManagerService } from 'src/app/service/game-manager.service';

@Component({
  selector: 'app-craft-list',
  templateUrl: './craft-list.component.html',
  styleUrls: ['../shop/shop.component.scss', './craft-list.component.scss']
})
export class CraftListComponent implements OnInit {
  public clientHeight: number = 0;
  public clientWidth: number = 0;
  public cardContainerWidth: number = 1280;
  public list: Array<any> = [];
  public audio: any = undefined;
  public isCreatable = false;
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public playService : PlayService,
    public shopService : ShopService,
    public craftService : CraftService,
    public gms: GameManagerService,
  ) { 
  }

  ngOnInit(): void {
    this.onResize(null);
    this.load_list();
  }
  
  
  public load_list() {
    this.shopService.load_refresh();
    this.craftService.get_list().subscribe((res:any)=>{
      console.log(res);

      if (res.status){
        this.list = res.data;
        
        if (!this.list?.length || !this.list[0].craft_now) {
          this.isCreatable = true;
        }
      }
    });
  }
  public onResize(event: any) {
    this.clientHeight = +(window.innerHeight || 0);
    this.clientWidth = +(window.innerWidth || 0);
    this.cardContainerWidth = Math.floor((+(window.innerWidth || 0) - 277) / 320) * 320;

    console.log(this.cardContainerWidth);
  }

  public onFocus(event: any, item: any) {
    this.audio = item.audio_url;
  }

  public craft(item: any) {
    let cmid: number = item?.id || 0;

    this.router.navigate([`../craft/${cmid}`], { relativeTo: this.route });
  }
}
