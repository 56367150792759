
import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {  
  constructor(private httpService: HttpService) { 
  }

  public load_comment(mid: string) {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/community/comment/${mid}`);
  }
  public write_comment(mid: string, text: string) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/community/comment/${mid}`, {text: text});
  }
  public modify_comment(cid: string, text: string) {
    return this.httpService.httpClient.put<any>(`${this.httpService.apiServer}/api/v1/ingame/community/comment/${cid}`, {text: text});
  }
  public delete_comment(cid: string) {
    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/ingame/community/comment/${cid}`);
  }
  public load_reply(cid: string) {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/community/reply/${cid}`);
  }
  public write_reply(cid: string, text: string) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/community/reply/${cid}`, {text: text});
  }
  
  public get_reaction(mid: string) {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/ingame/community/reaction/${mid}`);
  }
  public set_reaction(mid: string, reaction: string, msec: number) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/community/reaction/${mid}`, {reaction: reaction, msec: msec});
  }

  public vote_music(mid: string, score: number) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/community/music/vote/${mid}`, {score: score});
  }
  public vote_comment(cid: string, score: number) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/ingame/community/comment/vote/${cid}`, {score: score});
  }
}
