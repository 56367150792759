<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <div class="pointlog-wrapper">
    <table>
      <tr>
        <th>No.</th>
        <th>Title</th>
        <th>Point</th>
        <th>Date</th>
      </tr>
      <tr *ngFor="let log of point_log">
        <td>{{log.idx}}</td>
        <td>{{log.description}}
          <div class="subtext" *ngIf="log.title"> [<span class="highlight">{{log.title}}</span>] </div>
        </td>
        <td class="point" [ngClass]="log.type">
          {{log.point}} <i class="fa fa-star"></i>
        </td>
        <td class="date">{{log.time}}</td>
      </tr>    
    </table>

    <div class="pagenation-wrapper">
      <span class="pagenation-indicator">
        <span (click)="load_page(-1)" *ngIf="page > 1">
          <i class="fa fa-angle-left"></i> &nbsp;
        </span>
        <span>{{page}} / {{endpage}}</span>
        <span (click)="load_page(1)" *ngIf="page < endpage">
          &nbsp; <i class="fa fa-angle-right"></i>
        </span>
      </span>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text">{{button.text}}</button>
    <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
  </div>
</div>