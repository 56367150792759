import { AfterViewInit, Component, ElementRef, Inject, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NetworkService } from 'src/app/service/network.service';
import { StatusService } from 'src/app/service/status.service';

import { fabric } from 'fabric';

@Component({
  selector: 'app-drawing-fabric-dialog',
  templateUrl: './drawing-fabric-dialog.component.html',
  styleUrls: ['./drawing-fabric-dialog.component.scss']
})
export class DrawingFabricDialogComponent implements AfterViewInit {
  private canvas!: fabric.Canvas;

  public canvasWidth = 800;
  public canvasHeight = 600;
  public brushSize = 5;
  public fgColor = '#000000';
  public bgColor = '#FFFFFF';

  constructor(
    public dialogRef: MatDialogRef<DrawingFabricDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private status: StatusService,
    public network: NetworkService,
  ) { 
    this.status.isOpenDialog = true;
  }

  ngAfterViewInit() {
    this.canvas = new fabric.Canvas('canvas');
    this.configureCanvas();
  }
  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.canvas) {
      this.canvas.freeDrawingBrush.width = this.brushSize;
      this.canvas.freeDrawingBrush.color = this.fgColor;
    }
  }


  configureCanvas(): void {
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.width = this.brushSize;
    this.canvas.freeDrawingBrush.color = this.fgColor;
  }

  async configureCanvas2(): Promise<void> {
    // this.canvas.isDrawingMode = true;

    // const patternSource = await fabric.Image.fromURL('/path/to/pattern.png');
    // const pattern = new fabric.Pattern({
    //   source: patternSource.getElement(),
    //   repeat: 'repeat',
    // });

    // const patternBrush = new fabric.PatternBrush(this.canvas);
    // patternBrush.sourcePattern = pattern;
    // patternBrush.width = this.brushSize;

    // this.canvas.freeDrawingBrush = patternBrush;
  }

  public async saveCanvasAsFile() {
    // const dataURL = this.canvas.nativeElement.toDataURL('image/png');
    // const response = await fetch(dataURL);
    // const blob = await response.blob();
    // const file = new File([blob], 'image.png', { type: 'image/png' });


    // console.log(file);
    
    // this.data.file = file;
    // this.dialogRef.close();

    // return file;
  }
  
  change_canvasSize(): void {
    this.canvas.setWidth(this.canvasWidth);
    this.canvas.setHeight(this.canvasHeight);
  }
  
  public mode(type: 'pointer' | 'brush'): void {
    this.canvas.isDrawingMode = type == 'brush';
  }
  public add_text(): void {
    const text = new fabric.IText('Text', {
      left: 100,
      top: 100,
      fontFamily: 'SeoulNamsan',
      fontSize: 20,
      fill: this.fgColor,
    });

    this.canvas.add(text);
    this.canvas.setActiveObject(text);
    this.canvas.isDrawingMode = false;
  }
  public add_object(
    shape: 'triangle' | 'rect' | 'circle' | 'ellipse' | 'line' | 'polygon' | 'polyline',
    fill: boolean = false
  ): void {
    let method: any = fabric.Rect;
    
    switch(shape) {
      case 'triangle':          method = fabric.Triangle;   break;
      case 'rect':              method = fabric.Rect;       break;
      case 'circle':            method = fabric.Circle;     break;
      case 'ellipse':           method = fabric.Ellipse;    break;
      case 'line':              method = fabric.Line;       break;
      case 'polygon':           method = fabric.Polygon;    break;
      case 'polyline':          method = fabric.Polyline;   break;
    }

    let obj = new method({
      left: 100,
      top: 100,
      fill: fill ? this.fgColor : 'transparent',
      stroke: this.fgColor,
      width: 100,
      height: 100,
    });
    
    this.canvas.add(obj);
    this.canvas.setActiveObject(obj);
    this.canvas.isDrawingMode = false;
  }
  public flip_object(direction: 'horizontal' | 'vertical'): void {
    const activeObject = this.canvas.getActiveObject();
    
    if (activeObject) {
      switch (direction) {
        case 'horizontal':    activeObject.toggle('flipY'); break;
        case 'vertical':      activeObject.toggle('flipX'); break;
      }
    
      this.canvas.renderAll();
    }
  }
  public change_zIndex(direction: 'front' | 'back'): void {
    const activeObject = this.canvas.getActiveObject();

    if (activeObject) {
      switch (direction) {
        case 'front':         this.canvas.bringToFront(activeObject); break;
        case 'back':          this.canvas.sendToBack(activeObject); break;
      }
    }
  }
  public group_object(): void {
    const activeObjects = this.canvas.getActiveObjects();
    if (activeObjects.length > 1) {
      const group = new fabric.Group(activeObjects);
      this.canvas.setActiveObject(group);
    }
  }
  
  public ungroup_object(): void {
    const activeObject = this.canvas.getActiveObject() as fabric.Group;
    if (activeObject && activeObject.type === 'group') {
      const items = activeObject.getObjects();
      this.canvas.remove(activeObject);
      items.forEach(item => {
        this.canvas.add(item);
      });
      this.canvas.renderAll();
    }
  }
  public delete_object(): void {
    const activeObject = this.canvas.getActiveObject();

    console.log(activeObject);

    if (activeObject) {
      this.canvas.remove(activeObject);
    }
  }

  public onKeyup(event: any): void {
    console.log(event);
  }

  close(): void {
    this.dialogRef.close();
  }
}
