<div class="dialog-chat-tab" (window:keyup)="onKeyUp($event)">
  <div class="dialog-chat-wrapper">
    <div class="dialog-chat-sidbar">
      <div *ngFor="let item of friend.friend; index as idx" (click)="friendIdx = idx">
        <span class="profile" [ngClass]="{'active': friendIdx == idx}">
          <img class="profile-image" [src]="item.profile"/> 
          <span class="profile-meta">
            <span class="profile-name" >{{item.name}}</span>
            <span class="profile-last-message">{{item.message}} &nbsp;</span>
          </span>
        </span>
      </div>
    </div>
    <div class="dialog-chat-resizer" (mousedown)="onMouseDown($event)"></div>
    <!-- <div class="dialog-chat-body"> -->
    <div class="chat-wrapper" [ngClass]="{'fold': chat.fold}" appDragAndDrop (fileDropped)="onFileDropped($event)" [style.width.px]="chat.width">
      <div class="chat-header">
        <span class="chat-title">{{friend.friend[friendIdx].name}}</span>
        <div class="chat-header-icons">
          <span class="chat-header-icon drawing" (click)="drawing_fabric()"></span>
          <span class="chat-header-icon drawing" (click)="drawing()"></span>
          <span class="chat-header-icon darkmode" *ngIf="!setting.darkmode" (click)="setting.toggle.darkmode()"></span>
          <span class="chat-header-icon lightmode" *ngIf="setting.darkmode" (click)="setting.toggle.darkmode()"></span>
        </div>
        <div class="expand-icon" (click)="chat.toggle()">
          <i class="fa fa-chevron-left"></i>
        </div>  
      </div>
      <div class="chat-body" #messageWrapper>
        <div [id]="message.id" class="chat-message-wrapper" *ngFor="let message of chat.messages" (dblclick)="chat.reply(message)">
          <div class="chat-message" [ngClass]="{'self': message?.self}">
            <div class="timestamp">{{message.ts | date:'MM/dd HH:mm:ss'}}</div>
            <div class="name">{{message.name}}</div>
            <div class="message" [innerHTML]="message.messageHTML" (click)="onClickChat($event)"></div>
          </div>
        </div>
        <br>
      </div>
      <div class="chat-footer">
        <div class="chat-preview">
          <div *ngIf="chat.files.length" class="chat-file-preview">
            <div *ngFor="let file of chat.files; let idx = index;" class="chat-file" [style.background-image]="file.backgroundURL">
              <!-- <img [src]="file.safeURL"> -->
              <div class="icons">
                <i class="fa fa-times" (click)="deleteFile(idx)"></i>
              </div>
            </div>
          </div>
          <div class="chat-reply" *ngIf="chat.replyMessage">
            <div class="icons">
              <i class="fa fa-times" (click)="chat.reply(undefined)"></i>
            </div>
            <div class="name">{{chat.replyMessage.name}} 님에게 답장</div>
            <div class="text" [innerHTML]="chat.replyMessage.messageHTML"></div>
          </div>
        </div>
        <div class="chat-input-wrapper">
          <textarea class="chat-input" placeholder="put the message" [(ngModel)]="chat.input" (keyup.enter)="chat.send()" (paste)="onPaste($event)" (keypress)="onPrevent($event)"></textarea>
          <div class="chat-send" (click)="chat.send()">
            <i class="fa fa-paper-plane"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>  
</div>


<input type="file" #fileDropRef id="fileDropRef" multiple accept='.png, .jpg, .jpeg, .gif, .mp4, .webm, .mp3, .m4a, .wav' (change)="fileBrowseHandler($event)" (paste)="onPaste($event)" style="display: none;"/>
<audio [src]="alarmURL" style="opacity: 0;" autoplay></audio>