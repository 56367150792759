<h1 mat-dialog-title>Drawing</h1>
<div mat-dialog-content class="padding-0">
  <canvas 
    #canvas 
    (pointermove)="onPointerMove($event)"
    (pointerdown)="onPointerDown($event)"
    (pointerup)="onPointerUp($event)"
  ></canvas>
  <br />
  <label for="penColor">펜 색상: </label>
  <input type="color" id="penColor" [(ngModel)]="penColor" />
  <br />
  <label for="penSize">펜 굵기: </label>
  <input type="range" id="penSize" [(ngModel)]="penSize" min="1" max="50" />
  <br />
  <label for="penStyle">펜 스타일: </label>
  <select id="penStyle" [(ngModel)]="penStyle">
    <option value="round">Round</option>
    <option value="square">Square</option>
    <option value="butt">Butt</option>
  </select>
  <br />
  <label for="penJoinStyle">펜 끝 스타일: </label>
  <select id="penJoinStyle" [(ngModel)]="penJoinStyle">
    <option value="round">Round</option>
    <option value="bevel">Bevel</option>
    <option value="miter">Miter</option>
  </select>
  <br />
  <label for="canvasColor">캔버스 배경색: </label>
  <input type="color" id="canvasColor" [(ngModel)]="canvasColor" (change)="changeCanvasColor()" />
  <br />
  <label for="penOpacity">펜 투명도: </label>
  <input type="range" id="penOpacity" [(ngModel)]="penOpacity" min="0" max="1" step="0.01" />
  <br />
  <button (click)="activateEraser()">지우개</button>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button mat-button mat-dialog-close="close">Close</button>
    <button class="primary" mat-button mat-dialog-close="success" (click)="saveCanvasAsFile()">Send</button>
  </div>
</div>