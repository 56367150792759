<div class="pin-code" (keyup)="keyUp($event)" (keydown)="keyDown($event)">
  <input maxlength="1" autofocus>
  <input maxlength="1">
  <input maxlength="1">
  <input maxlength="1">
  <input maxlength="1">
  <input maxlength="1">
  <input maxlength="1">
  <input maxlength="1">
</div>
<div style="overflow: auto; height: 100vh;">
  <div>
    <h2>Resource</h2>
    <div class="sb-resource-row" *ngFor="let item of resource">
      <button class="sb-clicker-button" (click)="click_resources(item)">{{item.prefix}} {{item.key}}</button>
      <button class="sb-clicker-info" disabled>{{item.total}} / {{item.limit}}</button>
      <button class="sb-clicker-info" disabled>{{item.increase}} / {{item.timer}}s</button>
    </div>
  </div>

  <div>
    <h2>Population</h2>
    <div class="sb-population-row" *ngFor="let item of population">
      <button class="sb-clicker-button" (click)="create_item(item)">{{item.prefix}} {{item.key}}</button>
      <button class="sb-clicker-info" disabled>{{item.total}}</button>
    </div>
  </div>

  <div>
    <h2>Building</h2>
    <div class="sb-building-row" *ngFor="let item of building">
      <button class="sb-clicker-button" (click)="create_item(item)">{{item.prefix}} {{item.key}}</button>
      <button class="sb-clicker-info" disabled>{{item.total}}</button>
    </div>
  </div>

  <div>
    <h2>Upgrade</h2>
    <div class="sb-upgrade-row" *ngFor="let item of upgrade">
      <button class="sb-clicker-button" (click)="create_item(item)">{{item.prefix}} {{item.key}}</button>
      <button class="sb-clicker-info" disabled>{{item.description}}</button>
    </div>
  </div>
</div>