import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { PoseLandmarker, FilesetResolver, DrawingUtils } from '@mediapipe/tasks-vision';

import * as THREE from 'three';
import { VRM, VRMHumanBoneName, VRMLoaderPlugin, VRMUtils } from '@pixiv/three-vrm';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as Kalidokit from 'kalidokit';
import { Holistic } from '@mediapipe/holistic';
import { Camera } from '@mediapipe/camera_utils';

// declare let THREE: any;
declare let Ammo: any;


@Component({
  selector: 'app-kalido',
  templateUrl: './kalido.component.html',
  styleUrls: ['./kalido.component.scss']
})
export class KalidoComponent implements OnInit, AfterViewInit {
  @ViewChild('vrmCanvas') vrmCanvas!: ElementRef;
  @ViewChild('video') video!: ElementRef;

  private scene!: THREE.Scene;
  private camera!: THREE.PerspectiveCamera;
  private renderer!: THREE.WebGLRenderer;
  private clock!: THREE.Clock;
  private vrmModel!: VRM | null;
  private holistic!: Holistic;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initScene();
    this.loadVRMModel('https://soulbeat.siya.co.kr/assets/test.vrm');
    this.initKalidoface();
    this.animate();
  }

  private initScene(): void {
    const canvas = this.vrmCanvas.nativeElement;

    console.log(canvas);

    this.renderer = new THREE.WebGLRenderer({ 
      canvas, 
      antialias: true,
    });
    
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
    this.scene.add(new THREE.AmbientLight(0xffffff));
    
    // this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000);
    this.camera.position.x = 0;
    this.camera.position.y = 1;
    this.camera.position.z = -3;
    this.camera.lookAt(new THREE.Vector3(0, 1, 0));

    this.clock = new THREE.Clock();
  }

  private loadVRMModel(path: string): void {
    const loader = new GLTFLoader();
    
    loader.register((parser) => {
      return new VRMLoaderPlugin(parser);
    });
  
    loader.load(
      path,
      (gltf) => {
        VRMUtils.removeUnnecessaryJoints(gltf.scene);
        const vrm = gltf.userData.vrm;

        this.vrmModel = vrm;

        this.scene.add(vrm.scene);
        VRMUtils.removeUnnecessaryJoints(vrm.scene);
        console.log(vrm);

        return vrm;
      },
      (progress) => console.log('Loading model...', 100.0 * (progress.loaded / progress.total), '%'),
      (error) => console.error(error),
    );
  }

  private initKalidoface(): void {
    this.holistic = new Holistic({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`
      },
    });

    this.holistic.setOptions({
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: false,
      smoothSegmentation: false,
      refineFaceLandmarks: true,
      minDetectionConfidence: 0.7,
      minTrackingConfidence: 0.7,
    });

    this.holistic.onResults(this.onResults);

    const camera = new Camera(this.video.nativeElement, {
      onFrame: async () => {
        await this.holistic.send({ image: this.video.nativeElement }).catch((error) => {
          console.error('Holistic send error:', error);
        });
      },
      width: 640,
      height: 480,
    });

    camera.start();
  }

  private onResults = (results: any): void => {
    if (this.vrmModel) {
      if (results.faceLandmarks) {
        const faceRig: any = Kalidokit.Face.solve(results.faceLandmarks, {
          runtime: 'mediapipe',
          video: this.video.nativeElement,
        });

        if (this.vrmModel.expressionManager) {
          const expressionManager = this.vrmModel.expressionManager;
        
          const blinkLeft = expressionManager.getExpression('blinkLeft');
          if (blinkLeft) blinkLeft.weight = faceRig.eye.l;
        
          const blinkRight = expressionManager.getExpression('blinkRight');
          if (blinkRight) blinkRight.weight = faceRig.eye.r;
        
          const mouthShape = expressionManager.getExpression('mouthOpen');
          if (mouthShape) mouthShape.weight = faceRig.mouth.shape;
        
          expressionManager.update();
        }
        else {
          console.log('none');
        }
      }
      
      // 포즈 및 본 회전
      if (results.poseLandmarks && results.poseLandmarks.length > 0) {
        const poseRig = Kalidokit.Pose.solve(results.za, results.poseLandmarks, {
          runtime: 'mediapipe',
          video: this.video.nativeElement,
        });
      
        if (poseRig) {
          this.updateBones(poseRig);
        } else {
          console.warn("유효한 포즈 데이터를 찾을 수 없습니다.", poseRig);
        }
      } else {
        console.warn("유효한 poseLandmarks 데이터가 없습니다.", results.poseLandmarks);
      }
    }
  };

  private animate = (): void => {
    requestAnimationFrame(this.animate);

    const delta = this.clock.getDelta();

    if (this.vrmModel) {
      this.vrmModel.update(delta);
    }

    this.renderer.render(this.scene, this.camera);
  };

  private updateBones(poseRig: any): void {
    // // Hips 본 업데이트
    // const hipsBone = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.Hips);
    // if (hipsBone && poseRig.Hips && poseRig.Hips.rotation) {
    //   hipsBone.rotation.set(
    //     poseRig.Hips.rotation.x,
    //     poseRig.Hips.rotation.y,
    //     poseRig.Hips.rotation.z,
    //   );
    //   // console.log("Hips Rotation:", hipsBone.rotation); // 회전 값 출력
    // }
    // if (hipsBone && poseRig.Hips && poseRig.Hips.position) {
    //   hipsBone.position.set(
    //     poseRig.Hips.position.x / 10,
    //     poseRig.Hips.position.y / 10,
    //     poseRig.Hips.position.z / 10,
    //   );
    //   // console.log("Hips Rotation:", hipsBone.rotation); // 회전 값 출력
    // }
  
  
  
    // // Spine 본 업데이트
    // const spineBone = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.Spine);
    // if (spineBone && poseRig.Spine) {
    //   spineBone.position.set(
    //     poseRig.Spine.x / 10,
    //     poseRig.Spine.y / 10,
    //     poseRig.Spine.z / 10,
    //   );
    // }
  


    // Left Upper Arm 본 업데이트
    const leftUpperArm = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.LeftUpperArm);
    if (leftUpperArm && poseRig.LeftUpperArm) {

      leftUpperArm.position.set(
        poseRig.LeftUpperArm.x,
        poseRig.LeftUpperArm.y,
        poseRig.LeftUpperArm.z,
      );
    }
  
    // Right Upper Arm 본 업데이트
    const rightUpperArm = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.RightUpperArm);
    if (rightUpperArm && poseRig.RightUpperArm) {
      rightUpperArm.rotation.set(
        poseRig.RightUpperArm.x,
        poseRig.RightUpperArm.y,
        poseRig.RightUpperArm.z,
      );
    }
  
    // Left Lower Arm 본 업데이트
    const leftLowerArm = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.LeftLowerArm);
    if (leftLowerArm && poseRig.LeftLowerArm) {
      leftLowerArm.position.set(
        poseRig.LeftLowerArm.x,
        poseRig.LeftLowerArm.y,
        poseRig.LeftLowerArm.z,
      );
    }
  
    // Right Lower Arm 본 업데이트
    const rightLowerArm = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.RightLowerArm);
    if (rightLowerArm && poseRig.RightLowerArm) {
      rightLowerArm.rotation.set(
        poseRig.RightLowerArm.x,
        poseRig.RightLowerArm.y,
        poseRig.RightLowerArm.z,
      );
    }


    // // Left Upper Leg 본 업데이트
    // const leftUpperLeg = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.LeftUpperLeg);
    // if (leftUpperLeg && poseRig.LeftUpperLeg) {

    //   leftUpperLeg.rotation.set(
    //     poseRig.LeftUpperLeg.x,
    //     poseRig.LeftUpperLeg.y,
    //     poseRig.LeftUpperLeg.z,
    //     poseRig.LeftUpperLeg.rotationOrder,
    //   );
    // }
  
    // // Right Upper Leg 본 업데이트
    // const rightUpperLeg = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.RightUpperLeg);
    // if (rightUpperLeg && poseRig.RightUpperLeg) {
    //   rightUpperLeg.rotation.set(
    //     poseRig.RightUpperLeg.x,
    //     poseRig.RightUpperLeg.y,
    //     poseRig.RightUpperLeg.z,
    //     poseRig.RightUpperLeg.rotationOrder,
    //   );
    // }
  
    // // Left Lower Leg 본 업데이트
    // const leftLowerLeg = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.LeftLowerLeg);
    // if (leftLowerLeg && poseRig.LeftLowerLeg) {
    //   leftLowerLeg.rotation.set(
    //     poseRig.LeftLowerLeg.x,
    //     poseRig.LeftLowerLeg.y,
    //     poseRig.LeftLowerLeg.z,
    //     poseRig.LeftLowerLeg.rotationOrder,
    //   );
    // }
  
    // // Right Lower Leg 본 업데이트
    // const rightLowerLeg = this.vrmModel?.humanoid?.getRawBoneNode(VRMHumanBoneName.RightLowerLeg);
    // if (rightLowerLeg && poseRig.RightLowerLeg) {
    //   rightLowerLeg.rotation.set(
    //     poseRig.RightLowerLeg.x,
    //     poseRig.RightLowerLeg.y,
    //     poseRig.RightLowerLeg.z,
    //     poseRig.RightLowerLeg.rotationOrder,
    //   );
    // }
  }
}
