import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpService } from './http.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public session : any = null;
  public user: any = null;
  public stage: any = null;

  constructor(
    private httpService: HttpService,
    private utils: UtilsService,
  ) { 
    this.reload_session();
  }

  public reload_session() {
    this.get_session().subscribe((res) => {
      if (res.status) {
        if (res?.data?.metadata?.profile) {
          res.data.metadata.profileImage = `url("${res.data.metadata.profile}")`;
        }

        this.session = res.user;
        this.user = res.user;
        this.stage = res.stage;
      }
    });
  }
  public get_session(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/session`)
  }
  public signin(email: string, password: string, captcha: string): Observable<any> {
    let param = {
      email: email,
      password: this.utils.sha256Base64(password),
      captcha: captcha,
    };

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/signin`, param);
  }
  public signup(email: string, name: string, password: string, captcha: string): Observable<any> {
    let param = {
      email: email,
      name: name,
      password: this.utils.sha256Base64(password),
      captcha: captcha,
    };

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/signup`, param);
  }
  
  public get_alluser(start: number, num: number, date: string): Observable<any> {
    let _params = new HttpParams();
    let param: any = {
      start: start,
      num: num,
      start_date : '1970-01-01 00:00:00',
      end_date: date,
    };

    for (let key in param)
      _params = _params.append(key, `${param[key]}`);

    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/account`, this.httpService.package_param(param));
  }
  public get_user(user_id: number, start: number, num: number, date: string): Observable<any> {
    let param: any = {
      id : user_id,
      start: start,
      num: num,
      start_date : '1970-01-01 00:00:00',
      end_date: date,
    };

    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/user/account`, this.httpService.package_param(param));
  }
  public create_user(name: string, email: string, permission: number, description: string, password: string, password_confirm: string): Observable<any> {
    let param: any = {
      name: name,
      email: email,
      permission: permission,
      description: description,
      password: password,
      password_confirm: password_confirm
    };

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/account`, param);
  }
  public update_user(name: string, email: string, user_id: number, permission: number, description: string): Observable<any> {
    let param: any = {
      id: user_id,
      name: name,
      email: email,
      permission: permission,
      description: description,
    };

    return this.httpService.httpClient.put<any>(`${this.httpService.apiServer}/api/v1/user/account`, param);
  }
  public change_password(password: string, user_id: number | undefined, password_now: string = '') {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/account/password`, {id:user_id, password: password, password_now:password_now});
  }
  public reset_password(email: string) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/password/reset`, {email:email});
  }
  public reset_token(user_id: number | undefined) {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/account/token`, {id:user_id});
  }
  public delete_user(user_id: number): Observable<any> {
    let param: any = {
      id : user_id,
    };

    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/user/account`, this.httpService.package_param(param));
  }

  public delete_permission(permission_level: number): Observable<any>{
    let param: any = {
      permission_level : permission_level,
    };

    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/user/account`, this.httpService.package_param(param));
  }
  public update_permissionLabel(id: number, label : string): Observable<any>{
    let param: any = {
      id: id,
      label: label,
    };

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/user/account/permission`, param);
  }

  public signout() {
    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/user/signout`, {});
  }
}
