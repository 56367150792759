import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-ads-dialog',
  templateUrl: './ads-dialog.component.html',
  styleUrls: ['./ads-dialog.component.scss']
})
export class AdsDialogComponent {
  public url: SafeUrl | undefined;
  public sec: number = 0;
  public point: number = 0;
  private handle: any;

  constructor(
    public dialogRef: MatDialogRef<AdsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private sanitizer: DomSanitizer,
    private status: StatusService,
  ) { 
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url + '?autoplay=1&controls=0');
    this.sec = this.data.duration - 1;
    this.point = this.data.point;
    this.status.isOpenDialog = true;

    this.handle = setInterval(()=>{
      if(--this.sec < 0) {
        this.sec = 0;
        clearInterval(this.handle);
        this.close();
      };
    }, 1000);
  }

  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
    clearInterval(this.handle);
  }
  updateClosedReason(reason: string): void {
    this.data.closedReason = reason;
  }
  close(): void {
    this.dialogRef.close();
  }
}
