import { Component, Input, ElementRef, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {
  @Input() data: any = 0;
  @Output() menuItemSelected = new EventEmitter<string>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.left = this.data.x + 'px';
    this.el.nativeElement.style.top = this.data.y + 'px';
  }

  select(key: string) {
    this.menuItemSelected.emit(key);
  }
}