<div class="shop-wrapper" (window:resize)="onResize($event)" (click)="close()">
  <div class="shop-card-wrapper">
    <div class="shop-card-container" [style.width.px]='cardContainerWidth'>
      <div class="shop-card" [ngClass]="{'denied' : item.uid || item.soldout}" *ngFor="let item of list" (mouseleave)="audio = null" (mouseenter)="onFocus($event, item)" (click)="buy($event, item)">
        <div *ngIf="item.price == 0" class="ribbon ribbon-top-left">
          <span>Free to Play</span>
        </div>
        <div *ngIf="item.soldout" class="ribbon ribbon-top-left ribbon-darkred">
          <span>SOLD OUT!</span>
        </div>
        <div class="thumbnail-container">
          <div class="thumbnail" [style.background-image]="item.thumbnail_url">

            <div class="thumbnail-inner" *ngIf="item.soldout">
              <span class="thumbnail-inner-text">
                <div class="score">Sold out!<br>You can't play this music.</div>
              </span>
            </div>
            <div class="thumbnail-inner" *ngIf="item.price && item.uid">
              <span class="thumbnail-inner-text">
                <div class="score">This music is Expired!<br>You can play again, only</div>
                <div class="require-point">{{item.price}} <i class="fa fa-star sb-point-icon"></i></div>
              </span>
            </div>
            <div class="thumbnail-inner" *ngIf="item.price && !item.uid">
              <span class="thumbnail-inner-text">
                <div class="score">You can buy this music!</div>
                <div class="require-point">{{item.price}} <i class="fa fa-star sb-point-icon"></i></div>
              </span>
            </div>
          </div>
        </div>
        <div class="shop-card-content">
          <div class="shop-card-content-header">
            <span class="shop-card-title">{{item.title}}</span>
            <span class="shop-card-duration">{{item.duration_parse}}</span>
          </div>
          <div class="shop-card-content-header">
            <span class="shop-card-tag" *ngFor="let tag of item.tags">#{{tag}}</span>
          </div>
          <div class="shop-card-content-body">
            <span class="line" *ngIf="item.price == undefined"></span>
            <span class="line" *ngIf="item.price != undefined">
              Price
              <span class="line-right" *ngIf="item.price != item.price_max">
                <span class="sb-point-bright">{{item.price}}<i class="fa fa-star"></i></span>
                &nbsp;- &nbsp;
                <span class="sb-point-bright">{{item.price_max}}<i class="fa fa-star"></i></span>
              </span>

              <span class="line-right" *ngIf="item.price == item.price_max">
                <span class="sb-point-bright">{{item.price}}<i class="fa fa-star"></i></span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<audio *ngIf="audio" style="display: none;" [src]="audio" [volume]="0.2" autoplay></audio>