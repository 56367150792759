<h1 mat-dialog-title>My Account</h1>

<div mat-dialog-content class="dialog-content-tab">
  <div class="my-profile">
    <!-- <img class="my-profile-image" [src]="friend?.me?.profile"> -->

    <div class="my-profile-image">
      <div class="drag-and-drop-container" [ngClass]="{'no-border' : files.profile_image_url}" appDragAndDrop (fileDropped)="onFileDropped($event, 'profile_image')">
        <input type="file" #fileDropRef id="fileDropRef" multiple accept='.png, .jpg, .jpeg' (change)="fileBrowseHandler($event, 'profile_image')"/>
        <div class="drag-and-drop-message" *ngIf="!files.profile_image_url">Profile</div>

        <div *ngIf="files.profile_image_url">
          <img [src]="files.profile_image_url">
          <div class="icons">
            <i class="fa fa-trash" (click)="deleteFile('profile_image')"></i>
          </div>
        </div>
      </div>
    
      <div class="my-profile-status" [ngClass]="friend?.me?.current_status"></div>
    </div>

    <span class="my-profile-body">
      <div class="my-profile-name">{{friend?.me?.name}}</div>
      <!-- <div class="my-profile-message">{{profile.message}}</div> -->
      <input class="my-profile-message" [(ngModel)]="profile.message" placeholder="Profile message">
    </span>
    <span class="my-profile-option">

    </span>
  </div>
  

  <mat-tab-group headerPosition="below" class="dialog-content-body">
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fa fa-gears"></i> &nbsp; Setting
      </ng-template>
      <div class="tab-page">
        <div class="tab-label">Disclosure Scope</div>
        <table>
          <tr>
            <td>Play log</td>
            <td colspan="3">
              <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.difficult">
                <mat-button-toggle class="model-selector-btn" value="0">Public</mat-button-toggle>
                <mat-button-toggle class="model-selector-btn" value="1">Friend</mat-button-toggle>
                <mat-button-toggle class="model-selector-btn" value="2">Follow</mat-button-toggle>
                <mat-button-toggle class="model-selector-btn" value="3">Follower</mat-button-toggle>
              </mat-button-toggle-group>
            </td>
          </tr>
          <tr>
            <td>Replay</td>
            <td colspan="3">
              <mat-button-toggle-group class="model-selector" [(ngModel)]="data.setting.difficult">
                <mat-button-toggle class="model-selector-btn" value="0">Public</mat-button-toggle>
                <mat-button-toggle class="model-selector-btn" value="1">Friend</mat-button-toggle>
                <mat-button-toggle class="model-selector-btn" value="2">Follow</mat-button-toggle>
                <mat-button-toggle class="model-selector-btn" value="3">Follower</mat-button-toggle>
              </mat-button-toggle-group>
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>
    
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fa fa-usd"></i> &nbsp; Purchase
      </ng-template>
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>Point</ng-template>
          <div class="tab-page purchase-wrapper">

            <table>
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th>Point</th>
                <th>Date</th>
              </tr>
              <tr *ngFor="let log of pointLog">
                <td>{{log.idx}}</td>
                <td>{{log.description}}
                  <div class="subtext" *ngIf="log.title"> [<span class="highlight">{{log.title}}</span>] </div>
                </td>
                <td [ngClass]="log.type">{{log.point}}</td>
                <td>{{log.time}}</td>
              </tr>    
            </table>
        
            <div class="pagination-wrapper">
              <span class="pagination-indicator">
                <span class="pagination-cursor" (click)="load_pointLog(-1)" *ngIf="pointPage > 1"> &lt; &nbsp; Prev </span>
                <span>{{pointPage}} / {{pointEndpage}}</span>
                <span class="pagination-cursor" (click)="load_pointLog(1)" *ngIf="pointPage < pointEndpage"> Next &nbsp; &gt; </span>
              </span>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>Ticket</ng-template>
          <div class="tab-page purchase-wrapper">
            
            <table>
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th>Num</th>
                <th>Date</th>
              </tr>
              <tr *ngFor="let log of ticketLog">
                <td>{{log.idx}}</td>
                <td>{{log.description}}
                  <div class="subtext" *ngIf="log.title"> [<span class="highlight">{{log.title}}</span>] </div>
                </td>
                <td [ngClass]="log.type">
                    <span class="sb-ticket" *ngIf="log.ticket"><i class="fa fa-credit-card-alt"></i>{{log.ticket}}</span>
                </td>
                <td>{{log.time}}</td>
              </tr>    
            </table>

            <div class="pagination-wrapper">
              <span class="pagination-indicator">
                <span class="pagination-cursor" (click)="load_ticketLog(-1)" *ngIf="ticketPage > 1"> &lt; &nbsp; Prev </span>
                <span>{{ticketPage}} / {{ticketEndpage}}</span>
                <span class="pagination-cursor" (click)="load_ticketLog(1)" *ngIf="ticketPage < ticketEndpage"> Next &nbsp; &gt; </span>
              </span>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>Cash</ng-template>
          <div class="tab-page purchase-wrapper">
            
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>
    
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fa fa-history"></i> &nbsp; History
      </ng-template>
      <div class="tab-page">
        
      </div>
    </mat-tab>

  </mat-tab-group>
</div>

<!-- <div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text">{{button.text}}</button>
  </div>
</div> -->