import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

import { SettingService } from 'src/app/service/setting.service';
import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-stage-wallpaper-dialog',
  templateUrl: './stage-wallpaper-dialog.component.html',
  styleUrls: ['./stage-wallpaper-dialog.component.scss']
})
export class StageWallpaperDialogComponent {
  public myWallpaperList: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<StageWallpaperDialogComponent>,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private status: StatusService,
    private setting: SettingService,
  ) { 
    this.status.isOpenDialog = true;

    this.get_wallpaperList();
  }

  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }
  updateClosedReason(reason: string): void {
    this.data.closedReason = reason;
  }
  close(): void {
    this.dialogRef.close();
  }

  public get_wallpaperList () {
    const open_failedDialog = (error = undefined) => {
      this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'Warning',
          content : error ? JSON.stringify(error) : 'Sorry, wallpaper load failed.<br>Please try again in a moment.',
          button : [
            { text : 'OK', color : '#333' },
          ],
        },
      });
    }

    this.setting.get_wallpaper().subscribe((res) => {
      if (res.status) {
        for (let data of res.data) {
          data.image_url = `url('${data.image}')`
        }

        this.myWallpaperList = res.data;
      } else {
        return open_failedDialog();
      }
    }, error => open_failedDialog(error))
  }

  public selectImage(event: any, item: any) {
    this.data.selectImage = item;

    event.stopPropagation();

    this.dialogRef.close();
  }
}