import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from "../../dialog/confirm-dialog/confirm-dialog.component"

import { AccountService } from "../../service/account.service";
import { StatusService } from "../../service/status.service";
import { NetworkService } from 'src/app/service/network.service';
import { GameManagerService } from 'src/app/service/game-manager.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public title: string = 'SoulBeat'
  public useBGM: boolean = false;
  public backgroundMode = false;

  private threeMMD: any = {
    camera: undefined, 
    scene: undefined, 
    renderer: undefined, 
    audio: undefined,
    effect: undefined,
    helper : undefined,
    fid: undefined,
    ready : false,
    clock : undefined,
  }
  

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public accountService: AccountService,
    public statusService: StatusService,
    public networkService: NetworkService,
    public gms: GameManagerService,
  ) { 
    if(`${window.location}`.search('background') != -1) {
      this.backgroundMode = true;
    }
  }

  ngOnInit(): void {
    this.accountService.get_session().subscribe((res)=>{
      if(res.status)
        this.next_page(res?.data?.uuid);
    })
    
    const h: any = document.querySelector("#h");

    const camera_move = ()=> {
      const t = new Date().getTime();
      const x = Math.sin((t % 50000) / 50000 * 2 * Math.PI);
      const y = Math.cos((t % 60000) / 60000 * 2 * Math.PI);
      
      h.style.transform = `scale(3.5) perspective(1250px) rotateX(${y * 9 + 75}deg) rotateZ(${-x * 36 + 45}deg) translateZ(-9vw)`;
      
      requestAnimationFrame(camera_move);
    }

    camera_move();
  }

  ngOnDestroy(): void {
    if (this.threeMMD.audio?.isPlaying)
      this.threeMMD.audio?.stop();
    
    cancelAnimationFrame(this.threeMMD.fid);
  }

  public step: string = 'signin';
  public signin_captcha: string = '';
  public signup_captcha: string = '';
  public loginScreen: boolean = false;
  public input = {
    signup: {
      able : true,
      email: '',
      name: '',
      password: '',
      captcha: '',
    },
    signin: {
      email: '',
      password: '',
      captcha: '',
    }
  }
  public validation = {
    signup : {
      name: false, 
      email: false,
      password: false,
      nameCheck: () => {
        this.validation.signup.name = (this.input.signup.name.length >= 4);
      },
      emailCheck: () => {
        this.validation.signup.email = (this.input.signup.email.search(/^[0-9a-zA-Z]([\-.\w]*[0-9a-zA-Z\-_+])*@([0-9a-zA-Z][\-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9}$/g) !== -1);
      },
      passwordCheck: () => {
        this.validation.signup.password = (this.input.signup.password.length >= 8);
      },
    },
    signin : {
        all : false,
        allCheck : () => {
            this.validation.signin.all = (this.input.signin.email.length > 0 && this.input.signin.password.length > 0);
        },
    },
  }
  public click = {
    toggle_bgm: () => {
      // this.useBGM ? this.audio.pause() : this.audio.play();
      this.useBGM = !this.useBGM;
    },
    change_step : (step : string) => {
      this.step = step;
    },
    signin: () => {
      if (!this.validation.signin.all) {
        return this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Warning',
            content : 'This input is not valid.',
            button : [{ text : 'OK', color : '#333' }],
          },
        });
      }
      if (this.signin_captcha && !this.input.signin.captcha.length) {
        return this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Warning',
            content : 'Please enter a captcha text.',
            button : [{ text : 'OK', color : '#333' }],
          },
        });
      }

      return this.accountService.signin(
        this.input.signin.email, 
        this.input.signin.password, 
        this.input.signin.captcha,
      ).subscribe((res) => {
        if (res.status) {
          this.input.signin.email = '';
          this.input.signin.password = '';
          this.accountService.reload_session();
          this.gms.refresh();

          let extraMsg = ''
          
          if(res.welcome_point || res.welcome_ticket) {
            extraMsg += `<br>You've got a daily welcome kit!` 
            extraMsg += res.welcome_point ? `&nbsp; <span class="sb-point">${res.welcome_point}<i class="fa fa-star"></i></span>` : '';
            extraMsg += res.welcome_ticket ? `&nbsp; <span class="sb-ticket"><i class="fa fa-credit-card-alt"></i>${res.welcome_ticket}</span>` : '';
          }

          let msg = `Welcome. <b>${res.data?.name}</b>.${extraMsg}${res.description ? `<br><br><b>from Admin:</b><br>${res.description}` : ''}`;

          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Login',
              content : msg,
              button : [{ text : 'OK', color : '#333' }],
            },
          });
      
          dialogRef.afterClosed().subscribe(result => {
            this.next_page(res?.data?.uuid);
          });
        }
        else {
          if(res.description) {
            res.msg += `<br><br><b>from Admin:</b><br>${res.description}`;
          }

          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Warning',
              content : res.msg.replace('\n', '<br>') || `ID or password mismatched.`,
              button : [{ text : 'OK', color : '#333' }],
            },
          });
          
          if (res.captcha) {
            this.signin_captcha = res.captcha;
          }
        }
      });
    },
    signup: () => {
      if (!this.validation.signup.name || !this.validation.signup.email || !this.validation.signup.password) {
        return this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Warning',
            content : 'This input is not valid.',
            button : [{ text : 'OK', color : '#333' }],
          },
        });
      }
      if (this.signup_captcha && !this.input.signup.captcha.length) {
        return this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Warning',
            content : 'Please enter a captcha text.',
            button : [{ text : 'OK', color : '#333' }],
          },
        });
      }

      if (this.input.signup.able) {
        this.input.signup.able = false;

        this.accountService.signup(
          this.input.signup.email,
          this.input.signup.name,
          this.input.signup.password,
          this.input.signup.captcha
        ).subscribe((res) => {
          this.input.signup.able = true;

          if (res.status) {
            this.input.signup.email = '';
            this.input.signup.password = '';
            this.input.signup.name = '';
            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Sign up',
                content : 'Your membership has been completed.<br>Available after administrator approval.',
                button : [{ text : 'OK', color : '#333' }],
              },
            });
          }
          else {
            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : 'Warning',
                content : `Your membership has failed.<br>${res.msg}`,
                button : [{ text : 'OK', color : '#333' }],
              },
            });

            if (res.captcha) {
              this.signup_captcha = res.captcha;
            }
          }
        });
      }
      
      return;
    },
    reset_password: () => {
      let data = {
        width : '450px',
        data : {
          title : 'Reset Password',
          content : 'Send the password initialization link to the email you signed up.',
          input : [
            {label : 'Email', text : '', type : 'text'},
          ],
          button : [
            {text : 'Cancel', color : '#333'},
            {text : 'Send', color : '#58bef6'}
          ],
        },
      }
      
      let dialogRef = this.dialog.open(ConfirmDialogComponent, data);

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Send') {
          this.accountService.reset_password(data.data.input[0].text).subscribe((res: any)=>{
            let title = 'Warnning';
            let content = res.err;
            
            if(res.status) {
              title = 'Notice';
              content = `The password initialization link has been sent to the email address you entered.<br>If the mail hasn't arrived in the mailbox, please check the spam mailbox.<br><br><div style="text-align:center"><b>${data.data.input[0].text}</b></div>`;
            }

            this.dialog.open(ConfirmDialogComponent, {
              width : '450px',
              data : {
                title : title,
                content : content,
                button : [{ text : 'OK', color : '#333' }],
              },
            });
          })
        }
      });
    },
  }
  public next_page = (uuid: string) => {
    this.statusService.sidebar = true;
    
    this.gms.refresh();
    this.networkService.connect(uuid);
    
    this.router.navigate([`../menu`], { relativeTo: this.route });
  }
}
